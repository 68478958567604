<template>
    <div class="scenario-detail flex flex-col flex-auto">
        <!-- Header -->
        <div class="pl-3 md:pl-10 flex-initial text-xs">
            <span>{{scenario.name}}</span>
            <img
            class="ml-3 inline align-top cursor-pointer h-4"
            src="@/assets/images/SETTINGS.svg"
            @click="showSettingsPane()">
        </div>

        <!-- Node toolbar and buttons -->
        <div class="pl-3 md:pl-10 flex flex-row justify-between">
            <div class="dock pb-1 flex justify-start overflow-auto" ref="dock"></div>
            <div class="scenario-buttons pr-8 flex flex-col justify-center">
                <button
                class="twn-button text-xs mb-2"
                type="button"
                :disabled="hasPendingStoreRequest"
                @click="saveScenario()">Enregistrer</button>
                <button
                v-if="canPreviewScenario"
                @click="previewScenario"
                class="twn-button text-xs mb-2"
                type="button"
                :disabled="hasPendingStoreRequest || !itemID">Aperçu</button>
                <button
                @click="saveScenario(true)"
                class="twn-button text-xs mb-2"
                type="button"
                :disabled="hasPendingStoreRequest || !itemID">Dupliquer</button>
            </div>
        </div>

        <!-- Node editor -->
        <div class="flex flex-auto">
            <div
            class="flex-auto editor"
            :class="{ 
                'node-details-fade': (displayNodeDetails < 2),
                'node-details-fade-out': (displayNodeDetails == 0),
            }"
            ref="editor"></div>
        </div>

        <div class="mr-auto mb-16 ml-4">
            <button type="button" class="twn-button text-xs" @click="showDeleteModal">Supprimer le scénario</button>
        </div>

        <!-- Right pane (settings, media/asset, ...) -->
        <RightPanel
        ref="rightPanel"
        :title="rightPanelTitle[currentPaneType]"
        @hide="didHideRightPanel">
            <!-- Asset/Media Pane -->
            <keep-alive>
                <Medias
                v-if="currentPaneType == 'asset'"
                :select-only="true"
                :draggable-asset="true"
                :default-type-filter-slug="[assetTypeFilter]"
                @select-media="mediaSelected"></Medias>
            </keep-alive>

            <!-- Items (Games, ...) List -->
            <keep-alive>
                <List
                v-if="currentPaneType == 'item'"
                :listing="itemListing"
                :fields="itemListFields"
                :filters="itemListFilters"
                :searchText="itemSearchText"
                :mobileFields="itemListFields"
                :read-only="true"
                @select-item="itemSelected">
                </List>
            </keep-alive>

            <!-- Settings Pane -->
            <ScenarioSettingsPanel
            :show="currentPaneType == 'settings'"
            :scenario="scenario"
            :location-list="locationList"
            :entity-list="entityList"
            :scenario-type-list="scenarioTypeList"
            :has-pending-request="hasPendingStoreRequest"
            @update-settings="updateAndSaveSettings" />

            <!-- Characters for SMS, audio message, ... -->
            <keep-alive>
                <ScenarioCharactersPanel
                v-if="currentPaneType == 'characters'"
                :characters="characterList"
                @select-item="characterSelected" />
            </keep-alive>

            <!-- Entities for exploration, simple action, ... -->
            <keep-alive>
                <ScenarioEntityPanel
                v-if="currentPaneType == 'entity'"
                :entities="entityList"
                :entity-type="entityPaneType"
                @select-item="entitySelected" />
            </keep-alive>

            <!-- Subtitles -->
            <ScenarioSubtitlesPanel
            v-if="currentPaneType == 'subtitles'"
            :asset-id="subtitlesAssetId"
            @saved="subtitlesSaved" />
        </RightPanel>

        <b-modal ref="delete-modal-detail-scenario" class="bootstrap" centered hide-footer id="delete-modal-detail-scenario" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-scenario')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import AreaPlugin from "rete-area-plugin"

    import { FC_DELETE_ITEM } from '@/graphql/list'

    import initNodeEditor from "../node-editor"
    
    import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

    import List from '@/components/List'
    import RightPanel from '@/components/RightPanel'
    import Medias from "./Medias"
    import ScenarioSettingsPanel from '@/views/Scenario/ScenarioSettingsPanel'
    import ScenarioCharactersPanel from '@/views/Scenario/ScenarioCharactersPanel'
    import ScenarioEntityPanel from '@/views/Scenario/ScenarioEntityPanel'
    import ScenarioSubtitlesPanel from '@/views/Scenario/ScenarioSubtitlesPanel'

    import breadcrumb from '@/utils/breadcrumb'

    export default {
        name: 'ScenarioDetail',
        props: ['itemID'],
        components: {
            List,
            RightPanel,
            Medias,
            ScenarioSettingsPanel,
            ScenarioCharactersPanel,
            ScenarioEntityPanel,
            ScenarioSubtitlesPanel
        },
        mixins: [ dispatchStoreRequest ],
        data() {
            return {
                // UI states
                currentPaneTarget: null,
                currentPaneType: false,
                rightPanelTitle: {
                    asset: "Bibliotheque de médias",
                    item: "Bibliotheque de mini-jeux",
                    settings: "Paramètres du scénario",
                    characters: 'Bibliotheque de personnages',
                    entity: 'Bibliotheque d\'entité',
                    subtitles: 'Sous titres'
                },
                assetTypeFilter: null,
                nodeEditor: null,
                displayNodeDetails: 2,
                subtitlesAssetId: null,
                // Game list config
                itemListing: 'game',
                itemSearchText: 'Rechercher un mini-jeu',
                itemListFields: [
                    {
                        key:'name',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'type',
                        db: `type:gameType {
                            name
                        }`,
                        nested: 'name',
                        label: 'Type',
                        sortable: true,
                    }
                ],
                itemListFilters: [
                    {
                        key:'type',
                        label: 'Type'
                    }
                ],
                entityPaneType: null,
                // Local scenario states
                scenario: {
                    id: null,
                    name: "Nouveau scénario",
                    description: "",
                    title: "",
                    location: null,
                    type: null,
                    reteNodes: null,
                    contentLogs: [],
                    spawnPoint: null,
                    mapPoint: null,
                    presetScenario: null,
                    linkedScenario: null,
                    revisionDelay: 1,
                    scenes: [],
                },
            }
        },
        computed: {
            canPreviewScenario() {
                return (process.env.VUE_APP_FRONT_URL ? true : false)
            },
            ...mapState({
                locationList: state => state.Locations.list,
                proposalList: state => (state.Proposals ? state.Proposals.list : []),
                modalList: state => (state.Modals ? state.Modals.list : []),
                videoPartList: state => (state.VideoParts ? state.VideoParts.list : []),
                messageList: state => (state.Messages ? state.Messages.list : []),
                subtitleList: state => (state.Subtitles ? state.Subtitles.list : []),
                characterList: state => (state.Characters ? state.Characters.list : []),
                scenarioTypeList: state => state.Scenarios.type_list,
                entityTypeDict: state => state.Entity.type_list.reduce((dict, type) => {
                    dict[type.slug] = type

                    return dict
                }, {}),
                entityList: state => state.Entity.list,
                scenarioList: state => state.Scenarios.list,
            })
        },
        watch: {
            itemID: {
                async handler(val) {
                    // Load current scenario data, if needed
                    if (val) {
                        // todo: handle invalid uuid response
                        await this.dispatchStoreRequest('Scenarios/getByID', val)

                        this.updateLocalScenarioData()

                        // todo: common/cleaner system
                        breadcrumb.viewTitle = this.scenario.name + ' ' + this.scenario.title
                    }
                },
                immediate: true
            },
            currentPaneTarget(newTarget, oldTarget) {
                // Automaticly unselect old target
                if (oldTarget && newTarget != oldTarget) {
                    oldTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: false }))
                    oldTarget.dispatchEvent(new CustomEvent('set-selected-subtitles', { bubbles: true, composed: true, detail: false }))
                }
            }
        },
        async mounted() {
            // Load common data types
            await this.dispatchStoreRequest('Scenarios/getTypeList')
            await this.dispatchStoreRequest('Nodes/getTypeList')
            await this.dispatchStoreRequest('Triggers/getTypeList')
            await this.dispatchStoreRequest('Assets/getAssetList')

            //Todo : conditional
            // await this.dispatchStoreRequest('Locations/getList')
            await this.dispatchStoreRequest('Entity/getTypeList')
            await this.dispatchStoreRequest('Entity/getList')

            // Load scenario list for revision linked scenario
            await this.dispatchStoreRequest('Scenarios/getList')

            // Todo: load when each time ? (if changed in other tab ?)
            // Load node content data list if needed
            for (var i = 0; i < this.$store.state.Nodes.type_list.length; i++) {
                const type = this.$store.state.Nodes.type_list[i]

                switch (type.slug) {
                    case 'game':
                        await this.dispatchStoreRequest('Games/getTypeList')
                        await this.dispatchStoreRequest('Games/getList')
                        break

                    // case 'proposal': todo
                    case 'simple_proposal':
                        await this.dispatchStoreRequest('Proposals/getList')
                        break

                    case 'video_part':
                        await this.dispatchStoreRequest('VideoParts/getList')
                        break

                    case 'modal':
                        await this.dispatchStoreRequest('Modals/getList')
                        break

                    case 'sms':
                    case 'audio_message':
                    case 'exploration':
                        await this.dispatchStoreRequest('Messages/getList')
                        await this.dispatchStoreRequest('Characters/getList')
                        break

                    case 'subtitles':
                        await this.dispatchStoreRequest('Subtitles/getList')
                        break
                }
            }

            this.updateLocalScenarioData()

            // Media and PDF nodes events
            this.$el.addEventListener('select-asset', this.showAssetPane)
            this.$el.addEventListener('asset-selected', this.hideRightPanel)

            // Media nodes specific event
            this.$el.addEventListener('select-asset-media', this.showMediaPanel)

            // PDF nodes specific event
            this.$el.addEventListener('select-asset-pdf', this.showPDFPanel)

            // Scene nodes specific event
            this.$el.addEventListener('select-asset-scene', this.showScenePanel)

            // Interactive nodes specific event
            this.$el.addEventListener('select-asset-interactive', this.showInteractivePanel) //todo: improve by getting data from the control

            // Integrated video nodes specific event
            this.$el.addEventListener('select-asset-integrated', this.showIntegratedPanel) //todo: improve by getting data from the control

            // Game nodes events
            this.$el.addEventListener('select-item', this.showItemPane)
            this.$el.addEventListener('item-selected', this.hideRightPanel)

            // Game nodes specific event
            this.$el.addEventListener('select-item-game', this.showGamePanel)

            // SMS and Audio message nodes specific event
            this.$el.addEventListener('select-item-character', this.showCharacterPanel)

            // Exploration nodes specific event
            this.$el.addEventListener('select-item-action', this.showActionPanel)
            this.$el.addEventListener('select-item-signal', this.showSignalPanel)

            // Subtitles nodes specific event
            this.$el.addEventListener('edit-subtitles', this.showSubtitlesPane)

            // Init Rete node editor
            this.nodeEditor = await initNodeEditor(this.$refs.editor, this.$refs.dock, this.$store.state.Nodes.type_list)

            // Add event to handle node details on zoom
            this.nodeEditor.on('zoom', this.handleZoom)

            // Add event to handle connection drop on node (and not on a socket), to connect them to default output or input
            this.nodeEditor.on('connectiondrop', this.handleConnectionDrop)

            if (this.scenario.reteNodes) {
                const result = await this.nodeEditor.fromJSON(this.scenario.reteNodes)

                if (!result) {
                    this.failedLoadingData()
                    return
                }

                AreaPlugin.zoomAt(this.nodeEditor)
            }

            // Show settings pane by default for new scenario
            if (!this.itemID) {
                this.showSettingsPane()
            }
        },
        beforeDestroy() {
            this.$el.removeEventListener('select-asset', this.showAssetPane)
            this.$el.removeEventListener('asset-selected', this.hideRightPanel)
            this.$el.removeEventListener('select-asset-media', this.showMediaPanel)
            this.$el.removeEventListener('select-asset-pdf', this.showPDFPanel)
            this.$el.removeEventListener('edit-subtitles', this.showSubtitlesPane)
        },
        methods: {
            failedLoadingData() {
                alert('Erreur de chargement du scénario')
                this.$router.push({
                    name: 'scenario-list'
                })
            },
            showDeleteModal(){
                this.$refs['delete-modal-detail-scenario'].show();
            },
            async deleteItem(){
                await this.$apollo.query({
                    query: FC_DELETE_ITEM("scenario"),
                    variables: {
                        id: this.itemID
                    }
                })
                this.$store.dispatch('Logs/ContentLog', {
                    id: this.itemID,
                    action: 'content_delete'
                }, {root:true})
                this.$refs['delete-modal-detail-scenario'].hide();
                this.$router.push({
                    name: 'scenario-list'
                })
            },
            handleZoom({ zoom }) {
                if (zoom <= 0.5 && this.displayNodeDetails > 1) {
                    // Hide details
                    this.displayNodeDetails = 1

                    // Node icon Animation
                    setTimeout(() => {
                        this.displayNodeDetails = 0
                    }, 100)
                } else if (zoom > 0.5 && this.displayNodeDetails < 1) {
                    this.displayNodeDetails = 1

                    // Node icon Animation
                    setTimeout(() => {
                        this.displayNodeDetails = 2
                    }, 500)
                }
            },
            handleConnectionDrop(io) {
                const node = this.nodeEditor.custom_selected_node
                if (node) {
                    // Consume event
                    this.nodeEditor.custom_selected_node = null

                    // Don't connect to self
                    if (io.node != node) {
                        if (io.connectTo) {
                            // Connect to default input, if not already connected to it
                            const defaultInput = node.inputs.get('previous')

                            if (defaultInput && !io.connectedTo(defaultInput)) {
                                if (!io.multipleConnections && io.hasConnection())
                                    this.nodeEditor.removeConnection(io.connections[0])

                                this.nodeEditor.connect(io, defaultInput)
                            }
                        } else {
                            // Connect to default output, if not already connected to it
                            const defaultOutput = node.outputs.get('next')

                            if (defaultOutput && !defaultOutput.connectedTo(io)) {
                                if (!io.multipleConnections && io.hasConnection())
                                    this.nodeEditor.removeConnection(io.connections[0])

                                if (!defaultOutput.multipleConnections && defaultOutput.hasConnection())
                                    this.nodeEditor.removeConnection(defaultOutput.connections[0])

                                this.nodeEditor.connect(defaultOutput, io)
                            }
                        }
                    }
                }
            },
            showAssetPane(event) {
                if (this.currentPaneTarget)
                    this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: false }))

                this.currentPaneTarget = event.target
                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: true }))

                this.currentPaneType = "asset"

                if (event.detail && event.detail.restrictTypes && event.detail.restrictTypes.length > 0) {
                    this.assetTypeFilter = event.detail.restrictTypes[0]
                }

                this.$refs['rightPanel'].show()
            },
            showItemPane(event) {
                if (this.currentPaneTarget)
                    this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: false }))

                this.currentPaneTarget = event.target
                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: true }))

                this.currentPaneType = "item"
                this.$refs['rightPanel'].show()
            },
            showSettingsPane() {
                if (this.currentPaneType == 'settings')
                    return
                
                // Show settings pane
                this.currentPaneType = "settings"
                this.$refs['rightPanel'].show()
            },
            didHideRightPanel() {
                if (this.currentPaneTarget)
                    this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: false }))

                this.currentPaneType = null
            },
            hideRightPanel() {
                this.$refs['rightPanel'].hide()
            },
            showGamePanel() {
                this.itemListing = 'game'
                this.itemSearchText = 'Rechercher un minijeu'
            },
            showCharacterPanel() {
                this.currentPaneType = 'characters'
            },
            showActionPanel() {
                this.currentPaneType = 'entity'
                this.entityPaneType = this.entityTypeDict['action']
            },
            showSignalPanel() {
                this.currentPaneType = 'entity'
                this.entityPaneType = this.entityTypeDict['signal']
            },
            showSubtitlesPane() {
                if (this.currentPaneTarget)
                    this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected-subtitles', { bubbles: true, composed: true, detail: false }))

                this.currentPaneTarget = event.target
                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected-subtitles', { bubbles: true, composed: true, detail: true }))

                this.currentPaneType = "subtitles"

                if (event.detail && event.detail.assetId) {
                    this.subtitlesAssetId = event.detail.assetId
                }

                this.$refs['rightPanel'].show()
            },
            mediaSelected(asset) {
                if (!this.currentPaneTarget)
                    return

                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-asset', { bubbles: true, composed: true, detail: JSON.stringify(asset) }))
            },
            itemSelected(item) {
                if (!this.currentPaneTarget)
                    return

                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-item', { bubbles: true, composed: true, detail: JSON.stringify(item) }))
            },
            characterSelected(character) {
                if (!this.currentPaneTarget)
                    return

                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-item', { bubbles: true, composed: true, detail: JSON.stringify(character) }))
            },
            entitySelected(entity) {
                if (!this.currentPaneTarget)
                    return

                this.currentPaneTarget.dispatchEvent(new CustomEvent('set-item', { bubbles: true, composed: true, detail: JSON.stringify(entity) }))
            },
            subtitlesSaved() {
                if (!this.currentPaneTarget)
                    return

                this.currentPaneTarget.dispatchEvent(new CustomEvent('reload-asset', { bubbles: true, composed: true }))
                this.hideRightPanel()
            },
            saveScenario(duplicate = false) {
                const editorData = this.nodeEditor.toJSON()
                let data = {
                    ...this.scenario,
                    nodes: (editorData && editorData.nodes ? editorData.nodes : {}),
                }

                if (duplicate === true) {
                    delete data.id
                    data.name += ' (copie)'
                }

                this.dispatchStoreRequest('Scenarios/save', data).then(async (response) => {
                    // Reload external node content list after potential updates
                    for (var i = 0; i < this.$store.state.Nodes.type_list.length; i++) {
                        const type = this.$store.state.Nodes.type_list[i]

                        switch (type.slug) {
                            // case 'proposal': todo
                            case 'simple_proposal':
                                await this.dispatchStoreRequest('Proposals/getList', null, true)
                                break

                            case 'video_part':
                                await this.dispatchStoreRequest('VideoParts/getList', null, true)
                                break

                            case 'modal':
                                await this.dispatchStoreRequest('Modals/getList', null, true)
                                break

                            case 'sms':
                            case 'audio_message':
                            case 'exploration':
                                await this.dispatchStoreRequest('Messages/getList', null, true)
                                await this.dispatchStoreRequest('Characters/getList', null, true)
                                break
                            
                            case 'subtitles':
                                await this.dispatchStoreRequest('Subtitles/getList')
                                break
                        }
                    }

                    if (response.id) {
                        this.$router.push({
                            name: 'scenario-edit',
                            params: {
                                itemID: response.id
                            }
                        })
                    }

                    // Update node with new external node_content (proposal, etc...)
                    const nodeExtContent = response.nodeExtContent
                    if (nodeExtContent) {
                        for (var j = 0; j < this.nodeEditor.nodes.length; j++) {
                            const reteNode = this.nodeEditor.nodes[j]

                            if (nodeExtContent[reteNode.id] && nodeExtContent[reteNode.id].simple_proposal) {
                                reteNode.data.simple_proposal = nodeExtContent[reteNode.id].simple_proposal
                            } else if (nodeExtContent[reteNode.id] && nodeExtContent[reteNode.id].video_part) {
                                reteNode.data.video_part = nodeExtContent[reteNode.id].video_part
                            } else if (nodeExtContent[reteNode.id] && nodeExtContent[reteNode.id].modal) {
                                reteNode.data.modal = nodeExtContent[reteNode.id].modal
                            } else if (nodeExtContent[reteNode.id] && nodeExtContent[reteNode.id].message) {
                                reteNode.data.message = nodeExtContent[reteNode.id].message
                            }
                        }
                    }

                    this.hideRightPanel()
                    this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
                })
            },
            updateAndSaveSettings(settings) {
                if (!this.scenario || !settings)
                    return

                // Update scenario data
                const keys = Object.keys(settings)
                for (var i = 0; i < keys.length; i++) {
                    this.scenario[keys[i]] = settings[keys[i]]
                }

                // Save scenario
                this.saveScenario()
            },
            previewScenario() {
                window.open(process.env.VUE_APP_FRONT_URL + 'scenario/' + this.itemID, '_blank');
            },
            defaultEntities(typeSlug) {
                if (!this.entityList || this.entityList.length <= 0)
                    return []

                return this.entityList.filter((entity) => {
                    return (entity.default && entity.type.slug == typeSlug)
                })
            },
            defaultEntity(typeSlug) {
                const entities = this.defaultEntities(typeSlug)

                return (entities.length > 0 ? entities[0] : null)
            },
            updateLocalScenarioData() {
                const remoteData = this.$store.state.Scenarios.items[this.itemID] //todo: computed?

                if (!remoteData) {
                    this.scenario.id = null
                    this.scenario.name = "Nouveau scénario"
                    this.scenario.description = ""
                    this.scenario.title = ""
                    this.scenario.location = null
                    this.scenario.type = (this.scenarioTypeList && this.scenarioTypeList.length > 0 ? this.scenarioTypeList.find(type => type.slug == 'scenario') : null)
                    this.scenario.contentLogs = []
                    this.scenario.spawnPoint = this.defaultEntity('spawn_point')
                    this.scenario.mapPoint = this.defaultEntity('map_point')
                    this.scenario.scenes = this.defaultEntities('scene')
                    this.scenario.presetScenario = null
                    this.scenario.linkedScenario = null
                    this.scenario.revisionDelay = 1
                    return
                }

                this.scenario.id = remoteData.id
                this.scenario.name = remoteData.name
                this.scenario.title = remoteData.title
                this.scenario.description = remoteData.description
                this.scenario.contentLogs = remoteData.contentLogs
                
                if (remoteData.scenario_type_id) {
                    const type = this.scenarioTypeList.find((type) => {
                        if (type.id == remoteData.scenario_type_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.type = type
                }

                if (remoteData.scenarioLocations && remoteData.scenarioLocations.length > 0) {
                    const location = this.locationList.find((loc) => {
                        if (loc.id == remoteData.scenarioLocations[0].location_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.location = (location || null)
                }

                if (remoteData.spawn_point_id) {
                    const entity = this.entityList.find((entity) => {
                        if (entity.id == remoteData.spawn_point_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.spawnPoint = entity
                } else {
                    this.scenario.spawnPoint = this.defaultEntity('spawn_point')
                }

                if (remoteData.map_point_id) {
                    const entity = this.entityList.find((entity) => {
                        if (entity.id == remoteData.map_point_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.mapPoint = entity
                } else {
                    this.scenario.mapPoint = this.defaultEntity('map_point')
                }

                if (remoteData.scenes && remoteData.scenes.length > 0) {
                    const sceneIds = remoteData.scenes.map((scene) => scene.scene_id)
                    const scenes = this.entityList.filter((scene) => {
                        return (sceneIds.indexOf(scene.id) > -1)
                    })

                    this.scenario.scenes = (scenes || [])
                } else {
                    this.scenario.scenes = this.defaultEntities('scene')
                }

                if (remoteData.preset_scenario_id && remoteData.preset_scenario_id.length > 0) {
                    const scenario = this.scenarioList.find((scenario) => {
                        if (scenario.id == remoteData.preset_scenario_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.presetScenario = (scenario ? {
                        ...scenario,
                        label: (scenario.name + ' - ' + scenario.title),
                    } : null)
                } else {
                    this.scenario.presetScenario = null
                }

                if (remoteData.linked_scenario_id && remoteData.linked_scenario_id.length > 0) {
                    const scenario = this.scenarioList.find((scenario) => {
                        if (scenario.id == remoteData.linked_scenario_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.linkedScenario = (scenario ? {
                        ...scenario,
                        label: (scenario.name + ' - ' + scenario.title),
                    } : null)
                } else {
                    this.scenario.linkedScenario = null
                }

                if (remoteData.revision_delay) {
                    this.scenario.revisionDelay = (parseInt(remoteData.revision_delay, 10) || 1)
                } else {
                    this.scenario.revisionDelay = 1
                }

                // Convert nodes from DB to Rete.js format
                if (remoteData.nodes && remoteData.nodes.length > 0) {
                    let data = {
                        id: "ScenarioEditor@1.0.0",
                    }

                    data.nodes = remoteData.nodes.reduce((dict, node) => {
                        // Init base values
                        let reteNode = {
                            id: node.id,
                            name: node.nodeType.slug,
                            data: {},
                            inputs: {},
                            outputs: {},
                            position: [
                                node.position_x,
                                node.position_y
                            ],
                        }

                        // Get title if needed
                        if (node.title) {
                            reteNode.data.title = node.title
                        }

                        // Get node content
                        reteNode.data[node.nodeType.slug] = node.node_content

                        // Load node content if needed
                        if (reteNode.name == 'simple_proposal' && node.node_content) {
                            const proposal = this.proposalList.find((proposal) => {
                                if (proposal.id == node.node_content) {
                                    return true
                                }

                                return false
                            })

                            if (proposal) {
                                reteNode.data.text = proposal.texte
                            }
                        } else if (reteNode.name == 'video_part' && node.node_content) {
                            const video_part = this.videoPartList.find((video_part) => {
                                if (video_part.id == node.node_content) {
                                    return true
                                }

                                return false
                            })

                            if (video_part) {
                                reteNode.data.integrated = video_part.media_id
                                reteNode.data.startTime = video_part.start_time
                                reteNode.data.endTime = video_part.end_time
                                reteNode.data.frame = video_part.frame_id
                            }
                        } else if (reteNode.name == 'modal' && node.node_content) {
                            const modal = this.modalList.find((modal) => {
                                if (modal.id == node.node_content) {
                                    return true
                                }

                                return false
                            })

                            if (modal) {
                                reteNode.data.title = modal.title
                                reteNode.data.text = modal.text
                            }
                        } else if (reteNode.name == 'sms' && node.node_content) {
                            const message = this.messageList.find((message) => {
                                if (message.id == node.node_content) {
                                    return true
                                }

                                return false
                            })

                            if (message) {
                                reteNode.data.character = message.character_id
                                reteNode.data.text = (message.text || '')
                            }
                        } else if (reteNode.name == 'audio_message' && node.node_content) {
                            const message = this.messageList.find((message) => {
                                if (message.id == node.node_content) {
                                    return true
                                }

                                return false
                            })

                            if (message) {
                                reteNode.data.character = message.character_id
                                reteNode.data.audio = message.media_id
                            }
                        } else if (reteNode.name == 'exploration' && node.node_content) {
                            const message = this.messageList.find((message) => {
                                if (message.id == node.node_content) {
                                    return true
                                }

                                return false
                            })
                            
                            if (message) {
                                reteNode.data.action = message.action_id
                                reteNode.data.text = (message.text || '')
                            }
                        } else if (reteNode.name == 'subtitles' && node.node_content) {
                            const subtitle = this.subtitleList.find((subtitle) => {
                                if (subtitle.id == node.node_content) {
                                    return true
                                }

                                return false
                            })

                            if (subtitle) {
                                reteNode.data.character = subtitle.character_id
                                reteNode.data['subbed-media'] = subtitle.media_id
                            }
                        }

                        const commonTriggersTypeKey = {
                            continue: 'next',
                            game_success: 'success',
                            game_failure: 'failure'
                        }

                        // Get outputs data from triggers
                        let outputsData = node.triggers.reduce((data, trigger) => {
                            let output = {
                                connections: [
                                {
                                    node: trigger.triggered_node_id,
                                    input: "previous",
                                    data: {}
                                }
                                ]
                            }

                            // Get trigger output key and data
                            let outputKey = null

                            if (commonTriggersTypeKey[trigger.triggerType.slug]) {
                                outputKey = commonTriggersTypeKey[trigger.triggerType.slug]
                            } else if (trigger.triggerType.slug == 'interactive_trigger') {
                                outputKey = trigger.name
                                    
                                data.customTriggers.push(trigger)
                            } else {
                                // Handle dynamic output
                                data.dynamicOutputCount += 1

                                // Back compat for buttons
                                if (trigger.triggerType.slug == (reteNode.name + '_button')) {
                                    data.dynamicOutputType = 'button'
                                    outputKey = 'button|' + data.dynamicOutputCount
                                } else {
                                    data.dynamicOutputType = trigger.triggerType.slug
                                    outputKey = trigger.triggerType.slug + '|' + data.dynamicOutputCount
                                }

                                let triggerName = (trigger.name || '')

                                if (trigger.signal_id) {
                                    const signal = this.entityList.find((signal) => {
                                        if (signal.id == trigger.signal_id) {
                                            return true
                                        }

                                        return false
                                    })

                                    if (signal) {
                                        triggerName = (signal.name || '')
                                    }
                                }

                                // Set data
                                data.dynamicOutputs[outputKey] = {
                                    name: triggerName,
                                    signal_id: (trigger.signal_id || null),
                                }
                            }
                            
                            if (outputKey)
                                data.outputs[outputKey] = output

                            return data
                        }, {
                            dynamicOutputType: null,
                            dynamicOutputCount: 0,
                            dynamicOutputs: {},
                            customTriggers: [],
                            outputs: {},
                        })

                        reteNode.outputs = outputsData.outputs

                        if (outputsData.dynamicOutputType)
                            reteNode.data[outputsData.dynamicOutputType] = outputsData.dynamicOutputs

                        if (outputsData.customTriggers.length > 0)
                            reteNode.data.customTriggers = outputsData.customTriggers

                        dict[node.id] = reteNode

                        return dict
                    }, {})

                    this.scenario.reteNodes = data

                    if (this.nodeEditor) {
                        this.nodeEditor.fromJSON(data).then((result) => {
                            if (!result) {
                                this.failedLoadingData()
                                return
                            }

                            AreaPlugin.zoomAt(this.nodeEditor)
                        })
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
//todo remove when bootstrap is removed
// Fix bootstrap conflict
.node.media, .node.modal {
    display: block;
    overflow: visible;
}

.dock {
    .item {
        @apply p-3;

        .node {
            cursor: grab;
            width: 240px;
            min-width: 240px;

            &:active {
                cursor: grabbing;
            }
        }
    }

    .title {
        margin-top: 10px;
    }

    .input, .outputs, .control, .cross-container, .button {
        display: none;
    }
}

.editor {
    cursor: move;

    .node .description {
        display: none;
    }

    .node {
        max-width: 32rem;

        &:before {
            @apply absolute inset-0;
            display: none;
            content: '';
            z-index: 1;
            transition: opacity 0.5s;
            background-repeat: no-repeat;
            background-size: 70% 60%;
            background-position: center;
            background-color: white;
        }

        &.scene:before {
            background-image: url('../assets/images/SCENE_NODE.svg');
        }
        &.media:before {
            background-image: url('../assets/images/MEDIA_NODE.svg');
        }
        &.video:before {
            background-image: url('../assets/images/VIDEO_NODE.svg');
        }
        &.pdf:before {
            background-image: url('../assets/images/PDF_NODE.svg');
        }
        &.game {
            &:before {
                background-image: url('../assets/images/GAME_NODE.svg');
            }

            &.quiz_table:before {
                background-image: url('../assets/images/QUIZ_TABLE_ICON.svg');
            }

            &.reorder:before {
                background-image: url('../assets/images/REORDER_ICON.svg');
            }

            &.gap_fill:before {
                background-image: url('../assets/images/GAP_FILL_ICON.svg');
            }

            &.vocabulary:before {
                background-image: url('../assets/images/VOCABULARY_ICON.svg');
            }

            &.quiz_simple:before {
                background-image: url('../assets/images/QUIZ_SIMPLE_ICON.svg');
            }

            &.true_false:before {
                background-image: url('../assets/images/TRUE_FALSE_ICON.svg');
            }

            &.drag_drop:before {
                background-image: url('../assets/images/DRAG_DROP_ICON.svg');
            }

            &.cell_by_cell:before {
                background-image: url('../assets/images/CELL_BY_CELL_ICON.svg');
            }
        }
        &.simple_proposal:before {
            background-image: url('../assets/images/TEXT_NODE.svg');
        }
        &.interactive:before {
            background-image: url('../assets/images/INTERACTIVE_NODE.svg');
        }
        &.video_part:before {
            background-image: url('../assets/images/VIDEO_PART_NODE.svg');
        }
        &.modal:before {
            background-image: url('../assets/images/MODAL_NODE.svg');
        }
        &.subtitles:before {
            background-image: url('../assets/images/SUBTITLES_NODE.svg');
        }
        &.sms:before {
            background-image: url('../assets/images/SMS_NODE.svg');
        }
        &.audio_message:before {
            background-image: url('../assets/images/AUDIO_MESSAGE_NODE.svg');
        }
        &.video_call:before {
            background-image: url('../assets/images/VIDEO_CALL_NODE.svg');
        }
        &.exploration:before {
            background-image: url('../assets/images/EXPLORATION_NODE.svg');
        }
        &.actions:before {
            background-image: url('../assets/images/SIGNAL_NODE.svg');
        }

        .outputs {
            transition: background 0.5s;
            transition: border 0.5s;
        }
    }

    &.node-details-fade .node {
        &:before {
            display: block;
            opacity: 0;
        }
    }

    &.node-details-fade-out .node {
        &:before {
            display: block;
            opacity: 1;
        }

        .outputs {
            border-color: transparent;
            background: transparent;
        }
    }

    .connection {

        .main-path {
            cursor: no-drop;
            stroke-width: 4px;
            stroke: #d8d8d8;
        }

        &.input- {
            .main-path {
                stroke: #dc9799;
            }
        }
    }
}

.right-pane {
    top: 4rem;
    border-left: 1px solid rgba(0,0,0,0.2);
}
</style>