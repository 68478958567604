import gql from 'graphql-tag';

const GC_GET_USER_N_ORGAS = gql`
query GetUserInfo ($id:uuid!){
    users(where: {id: {_eq: $id}}) {
        id
        email
        experienced
        seniority
        first_name
        name
        status
        company
        role
        registration_date
        userOrganisations {
            organisations {
                name
                id
            }
        }
        promotion_users(distinct_on: promotion_id) {
            promotion {
                title
                id
                start_date
                end_date
                type {
                    slug
                }
            }
        }
    }
    organisations_aggregate(distinct_on: id) {
        nodes {
            name
            id
        }
    }
    users_aggregate(distinct_on: experienced) {
        nodes {
            experienced
        }
    }
}
`


const GC_EDIT_USER = gql`
mutation UpdateUser($id:uuid!, $email:String!, $company: String, $seniority: Int, $experienced:Boolean, $status: Int, $role: String, $fName:String!, $lName:String!, $orgas: [user_organisations_insert_input!]!, $registrationDate: date) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {
        email: $email,
        experienced: $experienced,
        seniority: $seniority,
        first_name: $fName,
        name: $lName,
        company: $company,
        role: $role,
        status: $status,
        registration_date: $registrationDate
    }) {
      id
    }
    delete_user_organisations(where: {user: {_eq: $id}}) {
        affected_rows
    }
    insert_user_organisations(objects: $orgas) {
        affected_rows
    }
  }
`

const GC_EDIT_USER_AND_PASSWORD = gql`
mutation UpdateUser($id:uuid!, $email:String!, $company: String, $seniority: Int, $experienced:Boolean, $status: Int, $role: String, $fName:String!, $lName:String!, $password:String!, $orgas: [user_organisations_insert_input!]!, $registrationDate: date) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {
        email: $email,
        experienced: $experienced,
        seniority: $seniority,
        first_name: $fName,
        name: $lName,
        company: $company,
        role: $role,
        status: $status,
        password: $password,
        registration_date: $registrationDate
    }) {
      id
    }
    delete_user_organisations(where: {user: {_eq: $id}}) {
        affected_rows
    }
    insert_user_organisations(objects: $orgas) {
        affected_rows
    }
  }
`

const GC_ADD_USER_ONE = gql`
    mutation AddUser($email:String!, $company: String, $experienced:Boolean, $status: Int, $seniority: Int, $role: String, $fName:String!, $lName:String, $password:String, $orgas: [user_organisations_insert_input!]!, $registrationDate: date) {
        insert_users_one(
            object: {
                email: $email,
                experienced: $experienced,
                first_name: $fName,
                seniority: $seniority,
                name: $lName,
                status: $status
                company: $company
                role: $role,
                password: $password,
                registration_date: $registrationDate,
                userOrganisations: {
                    data: $orgas
                }
            }
        ){
            id
        }
    }
`

const GC_EDIT_ORGA_ONE = gql`
mutation UpdateOrga($id: uuid!, $orga:organisations_set_input!) {
    update_organisations_by_pk(pk_columns: {id: $id}, _set: $orga) {
      id
    }
  }
`

const GC_ADD_ORGA_ONE = gql`
mutation AddOrga($orga:organisations_insert_input!) {
    insert_organisations_one(object: $orga) {
      id
    }
  }
`

const GC_GET_ORGA_ONE = gql`
query GetOrga($id: uuid!) {
    organisations_by_pk(id: $id) {
      name
      id
      city
      address2
      address
      post_code
      country
      admin_id
      federation_id
      housing
      hlm
      admin{
          id
          name
      }
      userOrganisations(distinct_on: user) {
        user
      }
    }
  }
`

const GC_ADD_MULTIPLE_USER = gql`
mutation AddMultipleUser($users: [users_insert_input!]!) {
  insert_users(objects: $users) {
    affected_rows
  }
}
`

export {
    GC_GET_USER_N_ORGAS,
    GC_EDIT_USER,
    GC_EDIT_USER_AND_PASSWORD,
    GC_EDIT_ORGA_ONE,
    GC_GET_ORGA_ONE,
    GC_ADD_USER_ONE,
    GC_ADD_ORGA_ONE,
    GC_ADD_MULTIPLE_USER,
}