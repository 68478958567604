function checkSequenceDates(sequenceData) {
    const today = new Date()
    const startDateTime = (new Date(sequenceData.start_date)).setHours(0, 0, 0, 0)
    const endDateTime = (new Date(sequenceData.end_date)).setHours(24, 0, 0, 0)

    if (startDateTime > today.getTime() || endDateTime < today.getTime()) {
        return false
    }

    return true
}

export default function getAvailableSequenceCountByGroup(userId, groupsResponse, sequenceDoneByID) {
    if (!groupsResponse || !groupsResponse.data || !groupsResponse.data.users || !groupsResponse.data.users[0] || !groupsResponse.data.users[0].promotion_users) {
        return 0
    }

    const groups = groupsResponse.data.users[0].promotion_users.map((pu) => pu.promotion)

    return groups.reduce((availableSequenceCountByGroup, group) => {
        availableSequenceCountByGroup[group.id] = 0

        // Check if group as not started or ended
        const today = new Date()
        const startDate = new Date(group.start_date)
        const endDate = new Date(group.end_date)
        
        if (startDate.getTime() > today.getTime() || endDate.getTime() < today.getTime()) {
            availableSequenceCountByGroup[group.id] = -1
            return availableSequenceCountByGroup
        }

        // Get sequence availability dates for this group
        const sequenceAvailibilityByID = group.sequences.reduce((dictionary, sequenceData) => {
            if (sequenceData.user_id == null) {
                dictionary[sequenceData.sequence_id] = sequenceData
            }
            return dictionary
        }, {})

        // Get sequence planned for this user
        const plannedSequenceByID = group.sequences.reduce((dictionary, sequenceData) => {
            if (sequenceData.user_id == userId) {
                if (!dictionary[sequenceData.sequence_id]) {
                    dictionary[sequenceData.sequence_id] = []
                }

                dictionary[sequenceData.sequence_id].push(sequenceData)
            }
            return dictionary
        }, {})

        // Check if all sequences are done
        if (Object.keys(sequenceDoneByID).length === Object.keys(sequenceAvailibilityByID).length) {
            availableSequenceCountByGroup[group.id] = -2
            return availableSequenceCountByGroup
        }

        group.course.levels.forEach((level) => {
            level.sequences.forEach((sequence) => {
                // Check if this sequence is already done
                if (sequenceDoneByID[sequence.id]) {
                    return
                }

                // Check if this sequence is planned for today
                if (plannedSequenceByID[sequence.id]) {
                    for (let i = 0; i < plannedSequenceByID[sequence.id].length; i += 1) {
                        if (checkSequenceDates(plannedSequenceByID[sequence.id][i])) {
                            availableSequenceCountByGroup[group.id] += 1
                            return
                        }
                    }
                }

                // Check if this sequence is available today
                if (sequenceAvailibilityByID[sequence.id] && checkSequenceDates(sequenceAvailibilityByID[sequence.id])) {
                    availableSequenceCountByGroup[group.id] += 1
                }
            })
        })

        return availableSequenceCountByGroup
    }, {})
}