import utils from '@/utils/trackingReportTemplate'
import { computeTrackingDataForList, countMatch, sumField, averageField } from '@/utils/trackingDataFactories'

import commonTypes from '../common/commonTypes'

import extraUsersData from "./extraUsersData"

import commonSheet from "./commonSheet"
import usersSheet from "./usersSheet"

const now = new Date()

export default {
	id: 'mainTrackingDataSheet',
	types: commonTypes,
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				roles: ['user'],
				emailFilter: '%(actionlogement.fr|afpols.fr|thewalkingnerds.com|grave.cool)',
				logTypes: ['login', 'logout', 'pdf', 'pdf_view', 'open_library', 'sequence_end', 'video_start', 'scenario', 'video_end', 'scenario_end', 'game'],
			},
			groupByUniq: 'id'
		},
		promotions: {
			module: 'Logs',
			action: 'GetPromotionsTrackingData',
			groupByUniq: 'id'
		},
		scenarios: {
			module: 'Scenarios',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		},
		organizations: {
			module: 'Organization',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		},
		courses: {
			module: 'Course',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id',
		},
	},
	extraData: {
		promotions: {
			sequences(promotion) {
				// Get scenario list for this promotion course
				return promotion.course.modules.reduce((sequences, mod) => {
					sequences = sequences.concat(mod.sequences)

					return sequences
				}, [])
			},
			scenarios(promotion) {
				// Get scenario list for this promotion course
				return promotion.sequences.reduce((scenarios, sequence) => {
					// Get scenarios in this module sequences
					scenarios = scenarios.concat(sequence.scenarios)

					return scenarios
				}, [])
			},
			status(group) {
				const start = new Date(group.start_date)
				const end = new Date(group.end_date)

				return (now < start ? 'coming' : (now > end ? 'done' : 'pending'))
			},
			userCount(group) {
				return group.users.length
			},
		},
		users: extraUsersData,
		isMainScenario: (data) => {
			// Create hash map to check if a scenario is a main one (ie. one of the main types and not used as a preset)
			const mainScenarioTypeSlug = ['scenario', 'edl']
			const isPresetScenario = data.scenarios.reduce((dict, scenario) => {
				if (scenario.preset_scenario_id) {
					dict[scenario.preset_scenario_id] = true
				}
				return dict
			}, {})

			return data.scenarios.reduce((dict, scenario) => {
				// Check type and if it's not used as preset
				if (mainScenarioTypeSlug.indexOf(scenario.type.slug) > -1 && !isPresetScenario[scenario.id]) {
					dict[scenario.id] = true
				}

				return dict
			}, {})
		},
		mainScenarioCompletionTimes: (data) => {
			const completionTimes = {
				firstAttempt: [],
				averageSumOfAllAttempt: [],
			}

			// Sum of all finished attempt time per scenario
			const perScenarioDict = {}

			data.users.forEach((user) => {
				const userAttemptedScenarioDict = {}

				// Add all finished attempt duration/time
				user.scenariosAttempts.forEach((attempt) => {
					if (attempt.end && attempt.scenarioId && data.isMainScenario[attempt.scenarioId]) {
						const time = (attempt.end - attempt.start)

						// Get the total time user have spent on a scenario
						if (!perScenarioDict[attempt.scenarioId]) {
							perScenarioDict[attempt.scenarioId] = {
								time: 0,
								userCount: 0,
							}
						}

						perScenarioDict[attempt.scenarioId].time += time

						// Get the first time a scenario was done and how much time it needed
						if (!userAttemptedScenarioDict[attempt.scenarioId]) {
							userAttemptedScenarioDict[attempt.scenarioId] = true

							// Count only the first attempt for 
							perScenarioDict[attempt.scenarioId].userCount += 1

							completionTimes.firstAttempt.push({
								scenarioId: attempt.scenarioId,
								time,
							})
						}
					}
				})
			})

			// Add total average time per scenario to the list
			Object.keys(perScenarioDict).forEach((key) => {
				completionTimes.averageSumOfAllAttempt.push({
					time: (perScenarioDict[key].time / perScenarioDict[key].userCount)
				})
			})

			return completionTimes
		},
		scenarioTimesById: (data) => {
			return data.users.reduce((dict, user) => {
				user.scenariosAttempts.forEach((attempt) => {
					if (!dict[attempt.scenarioId]) {
						dict[attempt.scenarioId] = []
					}

					dict[attempt.scenarioId].push({
						time: ((attempt.end || attempt.update) - attempt.start),
					})
				})

				return dict
			}, {})
		},
		scenarios: {
			averageTime(scenario, data) {
				return utils.averageField(data.scenarioTimesById[scenario.id] || [], 'time')
			},
			averageSuccessRate(scenario, data) {
				return utils.averageValues(data.users || [], (user) => {
					return (user.successRatePerScenario[scenario.id] || undefined)
				})
			},
		},
		usersData: (data) => {
			// Compute active and finished count
			const { activeCount, finishedCount } = computeTrackingDataForList(data.users || [], {
				activeCount: countMatch((user) => {
					return user.progression > 0 && user.progression < 1
				}),
				finishedCount: countMatch((user) => {
					return user.progression >= 1
				}),
			})

			return {
				count: data.users.length,
				waitingCount: (data.users.length - activeCount - finishedCount),
				activeCount,
				finishedCount,
			}
		},
		organizationsData: (data) => {
			return {
				count: data.organizations.length,
			}
		},
		groupsData: (data) => {
			// Compute collective and individual count
			const { collectiveCount, individualCount, averageUserCount } = computeTrackingDataForList(data.promotions || [], {
				collectiveCount: countMatch((group) => {
					return (group.type.slug === 'collective_promotion')
				}),
				individualCount: countMatch((group) => {
					return (group.type.slug === 'individual_promotion')
				}),
				averageUserCount: averageField('userCount'),
			})

			return {
				count: data.promotions.length,
				collectiveCount,
				individualCount,
				averageUserCount,
			}
		},
		coursesData: (data) => {
			// Compute complet and block count
			const {
				completCount,
				completActiveCount,
				completFinishedCount,
				blockCount,
				blockActiveCount,
				blockFinishedCount,
			} = computeTrackingDataForList(data.promotions || [], {
				completCount: countMatch((group) => {
					return (group.course.type.slug === 'complet_course')
				}),
				completActiveCount: countMatch((group) => {
					return (group.course.type.slug === 'complet_course' && group.status === 'pending')
				}),
				completFinishedCount: countMatch((group) => {
					return (group.course.type.slug === 'complet_course' && group.status === 'done')
				}),
				blockCount: countMatch((group) => {
					return (group.course.type.slug === 'bloc_course')
				}),
				blockActiveCount: countMatch((group) => {
					return (group.course.type.slug === 'bloc_course' && group.status === 'pending')
				}),
				blockFinishedCount: countMatch((group) => {
					return (group.course.type.slug === 'bloc_course' && group.status === 'done')
				}),
			})

			return {
				completCount,
				completActiveCount,
				completFinishedCount,
				blockCount,
				blockActiveCount,
				blockFinishedCount,
			}
		},
		activityData: (data) => {
			// Compute total time and average success rate for done users
			const { totalTime, averageSuccessRate } = computeTrackingDataForList(data.users || [], {
				totalTime: sumField('activeTimeData', 'sum'),
				averageSuccessRate: averageField('successRate', null, (value, user) => {
					return (user.progression >= 1)
				}),
			})

			// Compute average time to finish a scenario of type MES/VT and who is not used as a preset
			const { averageScenarioCompletionTime } = computeTrackingDataForList(data.mainScenarioCompletionTimes.firstAttempt, {
				averageScenarioCompletionTime: averageField('time'),
			})

			// Compute average activity time per user and scenario
			const { averageTimePerUserAndScenario } = computeTrackingDataForList(data.mainScenarioCompletionTimes.averageSumOfAllAttempt, {
				averageTimePerUserAndScenario: averageField('time'),
			})

			return {
				totalTime,
				averageScenarioTime: averageScenarioCompletionTime,
				averageSuccessRate,
				averageTimePerUserAndScenario,
			}
		},
		averageTimeBySequence: (data) => {
			const averageTimeDataBySequence = data.users.reduce((dict, user) => {
				const ids = Object.keys(user.activeTimeData.timeBySequence)

				ids.forEach((id) => {
					if (!user.activeTimeData.sequencesDone[id]) {
						return
					}

					if (!dict[id]) {
						dict[id] = {
							total: 0,
							count: 0,
						}
					}

					dict[id].total += user.activeTimeData.timeBySequence[id]
					dict[id].count += 1
				})

				return dict
			}, {})

			// Compute average time per sequence
			return Object.keys(averageTimeDataBySequence).reduce((averageTimeBySequence, id) => {
				const timeData = averageTimeDataBySequence[id]

				averageTimeBySequence[id] = (timeData.total / timeData.count)

				return averageTimeBySequence
			}, {})
		},
		doneGroups(data) {
			const doneGroups = []

			// Get list of course completely done at least once
			data.promotions.forEach((group) => {
				const endDate = new Date(group.end_date)

				if (now.getTime() > endDate.getTime()) {
					// Compute average progression for user in this group
					const averageUserProgression = utils.averageValues(group.users, (groupUser) => {
						const user = data.usersById[groupUser.id]

						if (!user) {
							return undefined
						}

						return user.progression
					})

					// Remove group with no real progress (usualy mean that it's a test group)
					if (averageUserProgression < 0.1) {
						return
					}

					doneGroups.push({
						id: group.id,
						title: group.title,
						averageUserProgression,
					})
				}
			})

			return doneGroups
		},
		doneCourses: (data) => {
			const doneCourses = {}

			// Get list of course completely done at least once
			data.promotions.forEach((group) => {
				const endDate = new Date(group.end_date)

				// Only add data for finished group (so they should have finished the course)
				if (now.getTime() < endDate.getTime()) {
					return
				}

				// Register all user for a course for each group
				if (!doneCourses[group.course.id]) {
					doneCourses[group.course.id] = {
						id: group.course.id,
						name: group.course.name,
						sequences: group.sequences,
						users: [],
					}
				}

				// Add valid user to the list
				group.users.forEach((userData) => {
					const user = data.usersById[userData.id]

					if (user) {
						doneCourses[group.course.id].users.push(user)
					}
				})
			})

			return Object.keys(doneCourses).reduce((list, id) => {
				const course = doneCourses[id]

				// Compute average and median login time
				course.averageLoginTime = utils.averageField(course.users, 'activeTimeData', 'sum')
				course.medianLoginTime = utils.medianField(course.users, 'activeTimeData', 'sum')

				// Compute average sequence time
				course.averageSequenceTime = utils.averageValues(course.users, (user) => {
					// Compute and return average sequence time for this user
					return utils.averageValues(course.sequences, (sequence) => {
						return user.activeTimeData.timeBySequence[sequence.id]
					})
				})

				// List total, in sequence, time for each user
				const userInSequenceTimes = course.users.map((user) => {
					// Compute and return total, in sequence, time for this user
					return course.sequences.reduce((total, sequence) => {
						if (user.activeTimeData.timeBySequence[sequence.id]) {
							total += user.activeTimeData.timeBySequence[sequence.id]
						}
						return total
					}, 0)
				})

				// Compute average and median course, in sequence, time
				course.averageInSequenceTime = utils.averageValues(userInSequenceTimes)
				course.medianInSequenceTime = utils.median(userInSequenceTimes)

				// Don't add course with no data
				if (course.averageInSequenceTime < 1) {
					return list
				}

				list.push(course)

				return list
			}, [])
		},
	},
	sheets: [
		commonSheet,
		usersSheet,
	]
}