<template>
    <b-modal
        ref="check-progress-modal"
        id="check-progress-modal"
        class="bootstrap"
        size="lg"
        centered
        hide-footer
        hide-header
    >
        <div class="d-block text-center my-6 uppercase font-semibold">
            <h3>Déblocage session libre pour l'utilisateur {{ firstName }} {{ lastName }}</h3>
        </div>
        <p
            class="text-center mb-2"
            :class="{
                'text-dashboard-red': hasWarning === true,
                'text-dashboard-green': hasWarning === false,
            }"
        >
            {{ status }}
        </p>
        <p
            v-for="(status, index) in calendarStatus.split('\n')"
            :key="index"
            class="text-center"
        >
            {{ status }}
        </p>
        <div class="flex flex-row justify-evenly items-center">
            <button v-if="hasWarning" type="button" class="mt-4 twn-button" @click="fixWarnings()">
                Corriger les problèmes
            </button>
            <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('check-progress-modal')">Fermer</button>
        </div>
    </b-modal>
</template>

<script>
import apollo from '@/apolloClient'
import constants from './gql-constants'

import checkGroupsSequenceData from './checkGroupsSequenceData'
import getAvailableSequenceCountByGroup from './getAvailableSequenceCountByGroup'

export default {
    name: 'DebugUserProgressModal',
    props: {
        userId: {
            type: String,
            required: true,
        },
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            status: '',
            calendarStatus: '',
            hasWarning: null,
            groups: [],
        }
    },
    methods: {
        show() {
            this.$refs['check-progress-modal'].show()
            this.checkData()
        },
        async checkData() {
            this.status = 'Analyse de la progression de l\'utilisateur...'
            this.calendarStatus = 'Analyse du contenu disponible pour l\'utilisateur...'
            this.hasWarning = null

            // Load scenario done
            const scenarioDoneResponse = await apollo.query({
                query: constants.GC_GET_SCENARIO_COMPLETED_BY_USERID,
                variables: {
                    id: this.userId,
                },
            })

            // Load groups course data
            const groupsResponse = await apollo.query({
                query: constants.GC_GET_GROUPS_COURSE_BY_USERID,
                variables: {
                    id: this.userId,
                },
            })

            // Check progress warnings
            this.groups = checkGroupsSequenceData(groupsResponse, scenarioDoneResponse)

            let warningCount = 0

            this.groups.forEach((group) => {
                group.course.levels.forEach((level) => {
                    if (level.warning) {
                        level.sequences.forEach((sequence) => {
                            if (sequence.warning) {
                                warningCount += sequence.warningReasons.length
                            }
                        })
                    }
                })
            })

            this.status = (warningCount > 0 ? warningCount + ' problèmes de progression détéctés' : 'Aucun problème de progression détécté')
            this.hasWarning = (warningCount > 0)

            // Get sequence done by their ID
            const sequenceDoneByID = this.groups.reduce((dict, group) => {
                group.course.levels.forEach((level) => {
                    level.sequences.forEach((sequence) => {
                        if (sequence.freeScenarioDone.length >= sequence.level_scenario_limit && sequence.scenarioDone.length >= sequence.scenarios.length) {
                            dict[sequence.id] = true
                        }
                    })
                })

                return dict
            }, {})

            // Check user available/planned sequence
            const availableSequenceCountByGroup = getAvailableSequenceCountByGroup(this.userId, groupsResponse, sequenceDoneByID)

            this.calendarStatus = ''

            this.groups.forEach((group) => {
                const availableSequenceCount = availableSequenceCountByGroup[group.id]

                this.calendarStatus += group.title + ' : '

                if (availableSequenceCount === -1) {
                    this.calendarStatus += 'Promotion fermée'
                } else if (availableSequenceCount === -2) {
                    this.calendarStatus += 'Toutes les séquences on été terminées'
                } else if (!availableSequenceCount || availableSequenceCount <= 0) {
                    this.calendarStatus += 'Aucune séquence programmée ou disponible aujourd\'hui'
                } else {
                    this.calendarStatus += availableSequenceCount + ' séquences disponibles'
                }

                this.calendarStatus += '\n'
            })

        },
        async fixWarnings(previouslyFixedWarningsCount = 0, fixLoopCount = 0) {
            this.status = 'Correction des problèmes...'
            this.hasWarning = null

            const fixedWarningLogByID = {}
            let fixedWarningsCount = previouslyFixedWarningsCount

            for (let i = 0; i < this.groups.length; i += 1) {
                const group = this.groups[i]

                for (let i = 0; i < group.course.levels.length; i += 1) {
                    const level = group.course.levels[i]

                    if (level.warning) {
                        for (let i = 0; i < level.sequences.length; i += 1) {
                            const sequence = level.sequences[i]

                            if (sequence.warning) {
                                // Check for free scenario warning
                                if (sequence.freeScenarioDone.length > sequence.level_scenario_limit) {
                                    // Try to move all extra scenarios to another sequence
                                    const extraScenarioCount = sequence.freeScenarioDone.length - sequence.level_scenario_limit

                                    for (let i = 0; i < extraScenarioCount; i += 1) {
                                        // Find a sequence with available free scenario slot
                                        const newSequence = this.findAvailableFreeScenarioSlot(level)

                                        if (newSequence) {
                                            const scenarioData = sequence.freeScenarioDone.pop()

                                            // Only update remote data if not already done by a previous fix
                                            if (!fixedWarningLogByID[scenarioData.log.id]) {
                                                fixedWarningLogByID[scenarioData.log.id] = true
                                                await this.changeLogSequence(scenarioData.log, newSequence.id)
                                            }

                                            // Update local data
                                            newSequence.freeScenarioDone.push(scenarioData)

                                            fixedWarningsCount += 1
                                        }
                                    }
                                }

                                // Check replayed scenarios
                                for (let i = 0; i < sequence.replayedScenarios.length; i += 1) {
                                    const scenarioData = sequence.replayedScenarios[i]

                                    if (scenarioData.warning) {
                                        // Only update remote data if not already done by a previous fix
                                        if (!fixedWarningLogByID[scenarioData.log.id]) {
                                            fixedWarningLogByID[scenarioData.log.id] = true

                                            // Move the scenario replay to the sequence where the scenario was done first
                                            await this.changeLogSequence(scenarioData.log, scenarioData.sequence.id)
                                        }

                                        fixedWarningsCount += 1
                                    }
                                }
                            }
                        }
                    }
                }
            }

            await this.checkData()

            if (this.hasWarning && fixLoopCount < 0) {
                await this.fixWarnings(fixedWarningsCount, fixLoopCount + 1)
            } else {
                confirm(fixedWarningsCount + ' problèmes corrigés')
            }
        },
        findAvailableFreeScenarioSlot(level) {
            for (let i = 0; i < level.sequences.length; i += 1) {
                const sequence = level.sequences[i]

                if (sequence.freeScenarioDone.length < sequence.level_scenario_limit) {
                    return sequence
                }
            }

            return null
        },
        async changeLogSequence(log, newSequenceId) {
            await apollo.mutate({
                mutation: constants.GC_SET_LOG_DATA,
                variables: {
                    id: log.id,
                    data: {
                        ...log.data,
                        sequence_id: newSequenceId,
                    },
                }
            })
        },
    },
}
</script>