import gql from 'graphql-tag'

const GC_GET_SUBTITLE_LIST = gql`
query GetSubtitleList {
  subtitle {
    id
    media_id
    character_id
  }
}
`

const GC_ADD_SUBTITLE_ONE = gql`
mutation AddSubtitleOne($media_id: uuid, $character_id: uuid) {
  insert_subtitle_one(object: {media_id: $media_id, character_id: $character_id}) {
    id
  }
}
`

const GC_UPDATE_SUBTITLE_BY_ID = gql`
mutation UpdateSubtitleOne($id: uuid!, $media_id: uuid, $character_id: uuid) {
  update_subtitle(where: {id: {_eq: $id}}, _set: {media_id: $media_id, character_id: $character_id}) {
    affected_rows
    returning {
      id
    }
  }
}
`

const GC_DELETE_SUBTITLE_ONE = gql`
mutation DeleteSubtitle($id:uuid!) {
  delete_subtitle(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
`

export {
  GC_GET_SUBTITLE_LIST,
  GC_ADD_SUBTITLE_ONE,
  GC_UPDATE_SUBTITLE_BY_ID,
  GC_DELETE_SUBTITLE_ONE
}