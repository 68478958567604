export default {
	filtered(arrayOfObject, field, value) {
		return arrayOfObject.filter((object) => {
			return (object[field] == value)
		})
	},

	groupByUniq(list, field, transform = null) {
		return list.reduce((dict, item) => {
			if (transform) {
				item = transform(item)
			}

			dict[item[field]] = item

			return dict
		}, {})
	},

	groupBy(list, field) {
		return list.reduce((dict, item) => {
			if (!dict[item[field]])
				dict[item[field]] = []

			dict[item[field]].push(item)

			return dict
		}, {})
	},

	countMatch(list, filter) {
		return list.filter(filter).length
	},

	countDistinct(list, field, filterNullable = false) {
		let keys = Object.keys(this.groupByUniq(list, field))

		if (filterNullable) {
			keys = keys.filter((key) => {
				return (key ? true : false)
			})
		}

		return keys.length
	},

	dayId(date) {
		// Create day id with Day+Month+Year
		return (date.getFullYear() * 10000) + (date.getMonth() * 100) + date.getDate()
	},

	weekId(date) {
		// Generate a uniq and ordered week id for a date
		const weekDate = new Date(date.getTime())

		// Set to start of the week (previous monday)
		weekDate.setDate((weekDate.getDate() - (6 + weekDate.getDay()) % 7))

		// Create week id with Day+Month+Year
		return this.dayId(weekDate)
	},

	dateFromWeekId(weekId) {
		return new Date(Math.trunc(weekId / 10000), Math.trunc((weekId % 10000) / 100), (weekId % 100))
	},

	fillMissingWeek(data, fillMissing, from = null, to = new Date()) {
		// Check for missing week id
		const sortedWeekIds = Object.keys(data).map((k) => parseInt(k, 10)).sort()

		if (sortedWeekIds.length > 0) {
			// Get first week start date
			const firstWeekId = (from ? this.weekId(from) : sortedWeekIds[0])
			const firstMonday = this.dateFromWeekId(firstWeekId)

			// Get all weekIDs and add missing ones
			const oneWeek = (7 * 24 * 60 * 60 * 1000)
			const weekCount = Math.round((to.getTime() - firstMonday.getTime()) / oneWeek)

			let currentDate = firstMonday
			for (let i = 0; i < weekCount; i++) {
				const id = this.weekId(currentDate)

				if (!data[id]) {
					data[id] = fillMissing(id)
				}

				// Move to next week
				currentDate = new Date(currentDate.getTime() + oneWeek)
			}
		}

		return data
	},

	groupByWeek(list, weekIdField, from = null, to = new Date()) {
		// Group by weekIdField
		let data = this.groupBy(list, weekIdField)

		// Check for missing week and fill with empty array
		return this.fillMissingWeek(data, () => [], from, to)
	},

	groupAverage(dict, transform) {
		const keys = Object.keys(dict)

		if (keys.length <= 0)
			return 0.0

		let sum = 0
		let count = 0

		keys.forEach((key) => {
			const value = transform(dict[key], key)

			if (value != undefined) {
				sum += value
				count += 1
			}
		})
		
		if (count <= 0)
			return 0.0

		return (sum / count)
	},

	sumField(list, field, subfield = null) {
		let reduce = (sum, item) => {
			sum += item[field]

			return sum
		}

		if (subfield) {
			reduce = (sum, item) => {
				sum += item[field][subfield]

				return sum
			}
		}

		return list.reduce(reduce, 0)
	},

	maxField(list, field, subfield = null) {
		let reduce = (max, item) => {
			if (item[field] > max) {
				max = item[field]
			}

			return max
		}

		if (subfield) {
			reduce = (max, item) => {
				if (item[field][subfield] > max) {
					max = item[field][subfield]
				}

				return max
			}
		}

		return list.reduce(reduce, 0)
	},

	averageField(list, field, subfield = null) {
		if (list.length <= 0)
			return 0.0

		let sum = 0
		let count = 0

		// Create computing method
		let forEach = (item) => {
			const value = item[field]

			if (value != undefined) {
				sum += value
				count += 1
			}

			return sum
		}

		if (subfield) {
			forEach = (item) => {
				const value = item[field][subfield]

				if (value != undefined) {
					sum += value
					count += 1
				}

				return sum
			}
		}

		// Compute sum and count
		list.forEach(forEach)
		
		if (count <= 0)
			return 0.0

		return (sum / count)
	},

	averageValues(list, transform = (item) => item) {
		if (list.length <= 0)
			return 0

		let sum = 0
		let count = 0

		list.forEach((item) => {
			const value = transform(item)

			if (value != undefined) {
				sum += value
				count += 1
			}
		})
		
		if (count <= 0)
			return 0.0

		return (sum / count)
	},

	median(list) {
		if (list.length <= 0)
			return 0.0

		const sorted = Array.from(list).sort((a, b) => a - b);
		const middle = Math.floor(sorted.length / 2);

		if (sorted.length % 2 === 0) {
			return (sorted[middle - 1] + sorted[middle]) / 2;
		}

		return sorted[middle];
	},

	medianField(list, field, subfield = null) {
		if (list.length <= 0)
			return 0.0

		let reduce = (list, item) => {
			const value = item[field]

			if (value != undefined) {
				list.push(value)
			}

			return list
		}

		if (subfield) {
			reduce = (list, item) => {
				const value = item[field][subfield]

				if (value != undefined) {
					list.push(value)
				}

				return list
			}
		}

		const sortedValueList = list.reduce(reduce, []).sort((a, b) => a - b);

		const middle = Math.floor(sortedValueList.length / 2);

		if (sortedValueList.length % 2 === 0) {
			return (sortedValueList[middle - 1] + sortedValueList[middle]) / 2;
		}

		return sortedValueList[middle];
	},

	groupMedian(dict, transform) {
		const keys = Object.keys(dict)

		if (keys.length <= 0)
			return 0.0

		const valueList = keys.map((key) => transform(dict[key], key))

		return this.median(valueList)
	},
}