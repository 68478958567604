import apollo from '@/apolloClient'
import { GC_GET_COURSE_LIST, GC_GET_COURSE_TYPES, GC_ADD_COURSE_ONE, GC_GET_COURSE, GC_DELETE_COURSE, GC_UPDATE_COURSE, GC_CHECK_NAME } from '@/graphql/course'
import { GC_GET_SCENARIO_TYPE_LIST } from '@/graphql/scenario'

export default {
    namespaced: true,
    state:{
        list: [],
        types:[],
        scenarioTypes: []
    },
    mutations: {
        SET_COURSE_LIST(state, courses) {
            state.list = (courses || [])
        },
    },
    getters:{
        typeListFormat(state){
            return state.types.reduce((dict, type) => {
                dict[type.id] = type

                return dict
            }, {})
        },
    },
    actions:{
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_COURSE_LIST })

            commit('SET_COURSE_LIST', response.data.course)
        },
        async Delete({state}, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_COURSE,
                variables: {id}
            })

            return response.data.delete_course_by_pk
        },
        async GetCourse({state}, id){
            const response = await apollo.query({
                query: GC_GET_COURSE,
                variables: {
                    id
                }
            })

            return response.data.course_by_pk
        },
        async GetCourseTypes({state}){
            const response = await apollo.query({
                query:GC_GET_COURSE_TYPES
            })

            state.types = response.data.course_type
        },
        async GetScenarioTypes({state}){
            const response = await apollo.query({
                query: GC_GET_SCENARIO_TYPE_LIST
            })

            state.scenarioTypes = response.data.course_type;
        },
        async NameTaken({state},{name, id}){
            const response = await apollo.query({
                query: GC_CHECK_NAME,
                variables: {
                    name
                }
            })

			if (
				(response.data.course.length > 0 && !id) ||
				(response.data.course.length > 0 && id && response.data.course[0].id !== id)
			) {
                return true
            }
        },
        async saveCourse({state, dispatch}, course){
            // Check name (todo:)
            let isTaken = await dispatch('NameTaken', {name: course.name, id:course.id})

            if (isTaken){
                return false;
            }

    //         // todo: Sanity check for course of type bloc
    //         if (course.type && course.type.slug == 'bloc_course') {
				// // Delete levels > 0
				// if (course.levels.length > 1){
				// 	course.levels.splice(1, course.levels.length - 1);
				// }
    //         }

            // Add course base data
            let variables = {
                name: (course.name || ''),
                description: (course.description || ''),
                type: course.type,
            }

            // Format levels data
            variables.levels = {
                data: course.levels.map((level, index) => {
                    const data = {
                        name: (level.name || ''),
                        title: (level.title || ''),
                        order: (level.order || index),
                        scenarios: {
                            data: level.scenarios.map((scenario) => {
                                return {
                                    scenario_id: scenario.id,
                                }
                            }),
                        },
                        sequences: {
                            data: level.sequences.map((sequence, index) => {
                                // Get full list of associated scenarios for both scenario type: revision and scenario
                                let scenarios = []

                                if (sequence.scenarios) {
                                    scenarios = scenarios.concat(
                                        sequence.scenarios.map((scenario, index) => {
                                            return {
                                                scenario_id: scenario.id,
                                                order: (scenario.order || index),
                                            }
                                        })
                                    )
                                }
                                
                                if (sequence.revisions) {
                                    scenarios = scenarios.concat(
                                        sequence.revisions.map((scenario, index) => {
                                            return {
                                                scenario_id: scenario.id,
                                                order: (scenario.order || index),
                                            }
                                        })
                                    )
                                }

                                const data = {
                                    name: (sequence.name || ''),
                                    title: (sequence.title || ''),
                                    level_scenario_limit: parseInt(sequence.level_scenario_limit, 10),
                                    order: (sequence.order || index),
                                    scenarios: {
                                        data: scenarios,
                                    },
                                }

                                if (sequence.id) {
                                    data.id = sequence.id
                                }

                                return data
                            }),
                        },
                    }

                    if (level.id) {
                        data.id = level.id
                    }

                    const course_id = (level.course_id || course.id)

                    if (course_id) {
                        data.course_id = course_id
                    }

                    return data
                }),
            }

            let response = null
            
            if (course.id) {
                // Set id
                variables.id = course.id

                // Get existing level ids
                const existingLevels = variables.levels.data.filter((level) => {
                    return (level.id ? true : false)
                })

                variables.level_ids = existingLevels.map((level) => level.id)

                // List all level scenario associations (existing or new) and create condition expression to delete old associations
                const levelScenarioData = existingLevels.reduce((data, level) => {
                    if (!level.scenarios.data)
                        return data

                    // Add new condition rules (delete existing associations not in the current list)
                    data.deleteCondition._or.push({
                        level_id: { _eq: level.id },
                        scenario_id: {
                            _nin: level.scenarios.data.reduce((ids, scenario) => {
                                if (scenario.scenario_id) {
                                    ids.push(scenario.scenario_id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all scenario associations in existing level
                    level.scenarios.data.forEach((scenario) => {
                        data.list.push({
                            level_id: level.id,
                            scenario_id: scenario.scenario_id,
                        })
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_level_scenarios_condition = levelScenarioData.deleteCondition
                variables.level_scenario_list = levelScenarioData.list

                // List all level sequences (existing or new) and create condition expression to delete old ones
                const levelSequenceData = existingLevels.reduce((data, level) => {
                    if (!level.sequences.data)
                        return data

                    // Add new condition rules (delete existing sequence not in the current list)
                    data.deleteCondition._or.push({
                        level_id: { _eq: level.id },
                        id: {
                            _nin: level.sequences.data.reduce((ids, sequence) => {
                                if (sequence.id) {
                                    ids.push(sequence.id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all sequence in existing level
                    level.sequences.data.forEach((sequence) => {
                        data.list.push({
                            id: sequence.id,
                            name: sequence.name,
                            order: sequence.order,
                            title: sequence.title,
                            level_scenario_limit: parseInt(sequence.level_scenario_limit, 10),
                            scenarios: sequence.scenarios,
                            level_id: level.id,
                        })
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_sequence_condition = levelSequenceData.deleteCondition
                variables.level_sequence_list = levelSequenceData.list.map((sequence) => {
                    // Remove extra data (scenarios, ...) for existing ones
                    if (sequence.id) {
                        return {
                            id: sequence.id,
                            name: sequence.name,
                            title: sequence.title,
                            level_scenario_limit: parseInt(sequence.level_scenario_limit, 10),
                            order: sequence.order,
                            level_id: sequence.level_id,
                        }
                    }

                    return sequence
                })

                // List all sequence scenario associations (existing or new) and create condition expression to delete old associations
                const sequenceScenarioData = levelSequenceData.list.reduce((data, sequence) => {
                    if (!sequence.id)
                        return data

                    if (!sequence.scenarios.data)
                        return data

                    // Add new condition rules (delete existing associations not in the current list)
                    data.deleteCondition._or.push({
                        sequence_id: { _eq: sequence.id },
                        scenario_id: {
                            _nin: sequence.scenarios.data.reduce((ids, scenario) => {
                                if (scenario.scenario_id) {
                                    ids.push(scenario.scenario_id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all scenario associations in existing sequence
                    sequence.scenarios.data.forEach((scenario) => {
                        data.list.push({
                            sequence_id: sequence.id,
                            scenario_id: scenario.scenario_id,
                            order: scenario.order,
                        })
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_sequence_scenarios_condition = sequenceScenarioData.deleteCondition
                variables.sequence_scenario_list = sequenceScenarioData.list

                // Re-format levels data
                variables.levels = variables.levels.data.map((level) => {
                    // Remove extra data (sequences, scenarios, ...) for existing ones
                    if (level.id) {
                        return {
                            id: level.id,
                            name: level.name,
                            title: level.title,
                            order: level.order,
                            course_id: level.course_id,
                        }
                    }

                    return level
                })

                // Update course data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_COURSE,
                    variables,
                })
            } else {
                // Insert new course data
                response = await apollo.mutate({
                    mutation: GC_ADD_COURSE_ONE,
                    variables: {
                        course: variables
                    }
                })

            }

            return response.data.insert_course_one
        }
    }
}