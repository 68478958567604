const groupStatus = {
	coming: 'À venir',
	done: 'Passé',
	pending: 'En cours',
}

export default {
	name: 'Utilisateurs',
	content: [
	{
		table: 'users',
		headers: ['Nom', 'Prénom', 'Nom de parcours', 'Titre de promo', 'Statut de promo', 'Complétion', 'Temps de connexion total', 'Taux de réussite moyen', 'Date d\'inscription'],
		row: (user, data) => {
			const timeData = user.activeTimeData
			const registration = user.registration_date ? {
				value: new Date(user.registration_date).getTime(),
				type: 'dateOnly',
			} : { value: '-' }

			return [
				'name',
				'first_name',
				{ value: (user.promotions.map((promotion) => data.promotionsById[promotion.id] && data.promotionsById[promotion.id].course && data.promotionsById[promotion.id].course.name).filter(s => !!s).join(', ') || '-') },
				{ value: (user.promotions.map((promotion) => data.promotionsById[promotion.id] && data.promotionsById[promotion.id].title).filter(s => !!s).join(', ') || '-') },
				{ value: (user.promotions.map((promotion) => data.promotionsById[promotion.id] && groupStatus[data.promotionsById[promotion.id].status]).filter(s => !!s).join(', ') || '-') },
				{ value: user.progression, type: 'percentage' },
				{ value: timeData.sum, type: 'duration' },
				{ value: (user.successRate > -1 ? user.successRate : 0), type: 'percentage' },
				registration,
			]
		},
	}
	]
}