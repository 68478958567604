import utils from "@/utils/trackingReportTemplate"

import statusLabels from "../userStatus"

export default {
	name: 'Promotion',
	content: [
		{
			cells: [
				{
					value: (data) => data.promotion.title
				}
			]
		},
		{
			cells: [
				{
					text: 'Date de début'
				},
				{
					text: 'Date de fin'
				}
			]
		},
		{
			cells: [
				{
					value: (data) => (new Date(data.promotion.startDate)).getTime(),
					type: 'date'
				},
				{
					value: (data) => (new Date(data.promotion.endDate)).getTime(),
					type: 'date'
				}
			]
		},
		{},
		{
			cells: [
				{
					text: 'Temps moyen des utilisateurs'
				},
				{
					text: 'Temps cumulé des utilisateurs'
				}
			]
		},
		{
			cells: [
				{
					value: (data) => utils.averageField(data.users, 'activeTimeData', 'average'),
					type: 'duration'
				},
				{
					value: (data) => utils.sumField(data.users, 'activeTimeData', 'sum'),
					type: 'duration'
				}
			]
		},
		{},
		{
			table: 'courseScenarios',
			headers: ['Scénarios', 'Nom', 'Temps moyen par utilisateur pour réaliser le scénario en entier', 'Temps moyen par utilisateur passé sur le scénario'],
			row: (scenario, data) => {
				// Average user time for the current scenario
				const averageUserTime = utils.averageField(data.users, 'activeTimePerScenario', scenario.id)
				const averageUserSpentTime = utils.averageField(data.users, 'activeTimeSpentPerScenario', scenario.id)

				return [
					{ value: scenario.name },
					{ value: scenario.title },
					{ value: averageUserTime, type: 'duration' },
					{ value: averageUserSpentTime, type: 'duration' },
				]
			}
		},
		{},
		{
			cells: [
				{
					text: 'Temps de connexion / utilisateur sur la promo'
				},
				{
					text: ''
				},
				{
					text: ''
				},
				{
					text: 'Scénario manquant par utilisateur'
				}
			]
		},
		{
			table: 'users',
			headers: (users, data) => {
				const levelNames = data.course.levels.map((level) => 'Niveau ' + (level.order + 1))

				return ['Nom', 'Prénom', 'Temps de connexion'].concat(levelNames)
			},
			row: (user, data) => {
				const scenariosDone = user.logs.reduce((dict, log) => {
					if (log.type.slug == 'scenario_end') {
						dict[log.data_key] = true
					}

					return dict
				}, {})

				const row = [
					'name',
					'first_name',
					{ value: user.activeTimeData.sum, type: 'duration' },
				].concat(data.course.levels.reduce((missingScenarios, level) => {
					const scenarios = data.course.scenariosPerLevel[level.id].filter((scenario) => {
						return !scenariosDone[scenario.id]
					}).map((scenario) => {
						return scenario.name
					})

					missingScenarios.push({ value: scenarios.join(', ') })

					return missingScenarios
				}, []))

				return row
			}
		},
		{},
		{
			cells: [
				{
					text: ''
				},
				{
					text: ''
				},
				{
					text: ''
				},
				{
					text: 'Scénario réalisé par utilisateur'
				}
			]
		},
		{
			table: 'users',
			headers: (users, data) => {
				const levelNames = data.course.levels.map((level) => 'Niveau ' + (level.order + 1))

				return ['Nom', 'Prénom', ''].concat(levelNames)
			},
			row: (user, data) => {
				const scenariosDone = user.logs.reduce((dict, log) => {
					if (log.type.slug == 'scenario_end') {
						dict[log.data_key] = true
					}

					return dict
				}, {})

				const row = [
					'name',
					'first_name',
					{ value: '' },
				].concat(data.course.levels.reduce((missingScenarios, level) => {
					const scenarios = data.course.scenariosPerLevel[level.id].filter((scenario) => {
						return !!scenariosDone[scenario.id]
					}).map((scenario) => {
						return scenario.name
					})

					missingScenarios.push({ value: scenarios.join(', ') })

					return missingScenarios
				}, []))

				return row
			}
		},
	]
}