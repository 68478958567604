import gql from 'graphql-tag'

export default {
	GC_GET_GROUPS_COURSE_BY_USERID: gql`
		query GroupsCourseByUserID($id: uuid!) {
			users(
				where: {
					id: { _eq: $id }
				}
			) {
				promotion_users(distinct_on: promotion_id) {
					promotion {
						id
						title
						start_date
						end_date
						course {
							id
							name
							levels(order_by: {order: asc}) {
								id
								order
								name
								title
								sequences(order_by: {order: asc}) {
									id
									name
									order
									title
									level_scenario_limit
									scenarios(order_by: {order: asc}) {
										order
										scenario{
											id
											name
											title
										}
									}
								}
								scenarios {
									scenario{
										id
										name
										title
									}
								}
							}
						}
						sequences {
							sequence_id
							start_date
							end_date
							user_id
						}
					}
				}
			}
		}
	`,
	GC_GET_SCENARIO_COMPLETED_BY_USERID: gql`
		query ScenarioCompletedByUserID($id: uuid!) {
			users(
				where: {
					id: { _eq: $id }
				}
			) {
				logs(
					where: { logType: { slug: { _in: ["scenario_end"] } } }
				) {
					id
					created_at
					data
					data_key
					logType { slug }
				}
			}
		}
	`,
	GC_SET_LOG_DATA: gql`
		mutation SetLogData($id: uuid!, $data: jsonb!) {
			update_logs_by_pk(
				pk_columns: {
					id: $id
				},
				_set: {
					data: $data
				}
			) {
				id
			}
		}
	`,
}