export default {
	scenariosPerLevel(course) {
		let scenariosPerLevel = {}

		course.levels.forEach((level) => {
			scenariosPerLevel[level.id] = []
			
			level.scenarios.forEach((scenarioLevel) => {
				scenariosPerLevel[level.id].push(scenarioLevel.scenario)
			})

			level.sequences.forEach((sequence) => {
				sequence.scenarios.forEach((sequenceScenario) => {
					scenariosPerLevel[level.id].push(sequenceScenario.scenario)
				})
			})
		})

		return scenariosPerLevel
	},
	sequences(course) {
		return course.levels.reduce((sequences, level) => {
			// Get this level sequence list
			let list = []
			
			level.sequences.forEach((sequence) => {
				list.push(sequence)
			})

			// Sort list and add to parent list
			list.sort((s1, s2) => {
				if (s1.order < s2.order) { return -1 }
				if (s1.order > s2.order) { return 1 }
				return 0
			})
			sequences = sequences.concat(list)
			
			return sequences
		}, [])
	}
}