import statusLabels from "../userStatus"

export default {
	name: 'Utilisateurs',
	content: [
	{
		table: 'users',
		headers: ['Nom', 'Prénom', 'Email', 'Statut', 'Promotions', 'Durée moyenne de connexion', 'Durée totale de connexion', 'Date dernière connexion', 'Taux d\'avancement', 'Taux de réussite moyen'],
		row: (user, data) => {
			const timeData = user.activeTimeData

			return [
				'name',
				'first_name',
				'email',
				{ value: (statusLabels[user.status] || '-') },
				{ value: (user.promotions.map((promotion) => data.promotionsById[promotion.id].title).join(', ') || '-') },
				{ value: timeData.average, type: 'duration' },
				{ value: timeData.sum, type: 'duration' },
				{ value: timeData.lastSessionTimestamp, type: 'date' },
				{ value: user.progression, type: 'percentage' },
				{ value: (user.successRate > -1 ? user.successRate : 0), type: 'percentage' },
			]
		},
	}
	]
}