<template>
	<div class="dashboard-chart dashboard-chart-bar" :class="{ 'vertical-bars': !horizontalBars, 'horizontal-bars': horizontalBars, 'has-tooltip': enableTooltip, 'has-legend': showLegend }">
		<!-- Chart -->
		<div class="dashboard-chart-container">
			<span
				v-if="enableTooltip"
				class="dashboard-chart-tooltip"
				:class="{ 'opacity-0': !showTooltip }"
				:style="tooltipPosition"
			>
				<p
					v-for="line in tooltipValue.split('\n')"
					:key="line"
				>
					{{ line }}
				</p>
			</span>

			<chartist
			class="h-full"
			ratio=""
			type="Bar"
			:data="chartData"
			:options="chartOptions"
			:event-handlers="chartEvents" />
		</div>

		<!-- Bottom legend -->
		<ul v-if="showLegend" class="dashboard-chart-legend">
			<li
			v-for="serie in chartData.legend"
			:key="serie.name"
			:data-series-name="serie.name">
				{{serie.label}}
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'BarChart',
		props: {
			data: {
				type: Object,
				required: true
			},
			highValue: {
				type: Number,
				default: undefined
			},
			labels: {
				type: Array,
				default: null
			},
			format: {
				type: String,
				default: 'raw'
			},
			horizontalBars: {
				type: Boolean,
				default: false
			},
			stackBars: {
				type: Boolean,
				default: false
			},
			showLegend: {
				type: Boolean,
				default: true
			},
			enableTooltip: {
				type: Boolean,
				default: false
			},
			showTopLabel: {
				type: Boolean,
				default: true
			},
			limit: {
				type: Number,
				default: null,
			},
			maxBarSize: {
				type: Number,
				default: null,
			},
			minBarSpace: {
				type: Number,
				default: 2,
			},
			// Override all serie name with one (used to do graph with multiple bars but one color)
			serieName: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				chartEvents: [{
					event: 'draw',
					fn: this.onChartDraw
				}],
				tooltipValue: '0',
				tooltipPosition: { top: 0, right: 'auto', bottom: 'auto', left: 0 },
				showTooltip: false
			}
		},
		computed: {
			chartOptions() {
				const stackCount = Object.keys(this.data).length

				return {
					low: 0,
					high: this.highValue,
					distributeSeries: !this.stackBars,
					horizontalBars: this.horizontalBars,
					stackBars: this.stackBars,
					height: (this.horizontalBars ? (60 * stackCount) + 'px' : undefined),
					chartPadding: {
						top: (this.horizontalBars ? 0 : 35),
						right: (this.horizontalBars ? (this.enableTooltip ? 50 : this.maxValueWidth + 10) : 14),
						bottom: (this.horizontalBars ? 0 : 4),
						left: (this.horizontalBars ? 4 : 14),
					},
					axisX: {
						offset: ((!this.horizontalBars && this.labels) ? 30 : 0),
						showLabel: (!this.horizontalBars && this.labels),
						showGrid: this.horizontalBars,
						labelInterpolationFnc: (label) => {
							if (this.horizontalBars) {
								// Only show the first grid line
								return (label > 0 ? null : label)
							}

							return label
						}
					},
					axisY: {
						offset: ((this.horizontalBars && this.labels) ? 100 : 0),
						showLabel: (this.horizontalBars && this.labels),
						showGrid: !this.horizontalBars,
						onlyInteger: true,
						labelInterpolationFnc: (label) => {
							if (!this.horizontalBars) {
								// Only show the first grid line
								return (label > 0 ? null : label)
							}
							
							return label
						}
					}
				}
			},
			chartData() {
				if (this.stackBars) {
					return this.formatStackedBarData(this.data, {})
				}

				return this.formatBarData(this.data, {})
			},
			maxValueWidth() {
				switch (this.format) {
					case 'raw':
						return 100

					case 'percentage':
						return 42

					case 'time':
						return 100

					default:
						return 100
				}
			}
		},
		methods: {
			onChartDraw(data) {
				switch (data.type) {
					case 'grid':{
						const gridDirection = (this.horizontalBars ? 'horizontal' : 'vertical')

						if (data.axis.units.dir == gridDirection) {
							// Expand grid line to chart offset
							const { rectStart, rectEnd } = data.axis.counterUnits
							const paddings = {
								y1: data.axis.chartRect.padding.top,
								y2: data.axis.chartRect.padding.bottom,
								x1: data.axis.chartRect.padding.left,
								x2: data.axis.chartRect.padding.right
							}

							const node = data.element.getNode()
							
							if (this.horizontalBars) {
								node.setAttribute(rectStart, data[rectStart] + paddings[rectStart] - 2)
								node.setAttribute(rectEnd, data[rectEnd] - paddings[rectEnd] + 2)
							} else {
								node.setAttribute(rectStart, data[rectStart] - paddings[rectStart] + 2)
								node.setAttribute(rectEnd, data[rectEnd] + paddings[rectEnd] - 2)
							}

							// Hack to place the grid group on top of the bars to hide their bottom border radius (yeah i know... :D)
							data.element.parent().parent().append(data.element.parent())

							// Add limit rectangle
							if (this.limit !== null) {
								const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')

								// Copy/update attributes
								rect.setAttributeNS(null, 'rx', 4)
								rect.setAttributeNS(null, 'ry', 4)
								rect.setAttributeNS(null, 'class', 'ct-limit')		
								rect.setAttributeNS('http://gionkunz.github.com/chartist-js/ct', 'ct:value', this.limit)

								const limitSize = this.limit * (data.axis.chartRect.height() / (data.axis.range.max - data.axis.range.min))

								if (this.horizontalBars) {
									rect.setAttributeNS(null, 'x', data.axis.chartRect.x1 + limitSize)
									rect.setAttributeNS(null, 'y', data.axis.chartRect.y1)
									rect.setAttributeNS(null, 'width', limitSize)
									rect.setAttributeNS(null, 'height', data.axis.chartRect.height())
								} else {
									rect.setAttributeNS(null, 'x', data.axis.chartRect.x1)
									rect.setAttributeNS(null, 'y', data.axis.chartRect.y1 - limitSize)
									rect.setAttributeNS(null, 'width', data.axis.chartRect.width())
									rect.setAttributeNS(null, 'height', limitSize)
								}

								node.parentElement.parentElement.prepend(rect)
							}
						}
						break;
					}

					case 'bar': {
						const barRect = (this.horizontalBars ? {
							start: data.x1 - 2,
							end: data.x2,
							position: data.y1,
							size: Math.max((data.x2 - data.x1 + 2), 0)
						} : {
							start: data.y2,
							end: data.y1,
							position: data.x1,
							size: Math.max((data.y1 - data.y2 + 2), 0)
						})

						const dataValue = (this.horizontalBars ? data.value.x : data.value.y)

						const isLastStackedBar = (this.stackBars && this.chartData.lastSerieIndexForStack[data.index] === data.seriesIndex)

						let node = data.element.getNode()

						// Avoid adding border radius to empty bar or when stacking bars
						if (dataValue > 0 && (!this.stackBars || isLastStackedBar)) {
							const barSize = (this.horizontalBars ? data.axisY.stepLength : data.axisX.stepLength) - (this.minBarSpace * 2)

							node = this.replaceBarNode(node, barRect, dataValue, barSize)

							// Hack to place the current bar group below the preceding one to hide their bottom border radius (yeah i know... :D)
							const barGroup = node.parentElement
							const grouParent = barGroup.parentElement
							grouParent.insertBefore(barGroup, grouParent.firstChild)
						}
						
						// Add event listeners for the tooltip display logic
						if (this.enableTooltip) {
							node.addEventListener('mouseover', () => {
								this.onBarMouseOver(data.seriesIndex, dataValue, barRect)
							})
							node.addEventListener('mouseout', () => {
								this.onBarMouseOut()
							})
						}

						if ((!this.enableTooltip || this.showTopLabel) && (!this.stackBars || isLastStackedBar)) {
							// Add custom label display the value at the end of the bar
							const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')

							if (this.horizontalBars) {
								foreignObject.setAttributeNS(null, 'x', barRect.end + 10)
								foreignObject.setAttributeNS(null, 'y', (barRect.position - 12))
							} else {
								foreignObject.setAttributeNS(null, 'x', (barRect.position - (this.maxValueWidth / 2)))
								foreignObject.setAttributeNS(null, 'y', barRect.start - 30)
							}
							foreignObject.setAttributeNS(null, 'width', this.maxValueWidth)
							foreignObject.setAttributeNS(null, 'height', 20)

							node.parentElement.append(foreignObject)

							const span = document.createElement('span')

							span.setAttribute('class', 'ct-value-label')
							span.setAttribute('xmlns', 'http://www.w3.org/2000/xmlns')

							span.textContent = this.formatValue(dataValue, true)

							foreignObject.append(span)
						}
						break;
					}
				}
			},
			onBarMouseOver(index, serieValue, barRect) {
				this.tooltipPosition = {
					left: (this.horizontalBars ? (barRect.start + (barRect.size / 2)) : barRect.position) + 'px',
					top: (this.horizontalBars ? (barRect.position - 45) : barRect.start) + 'px',
					right: null,
					bottom: null
				}

				this.tooltipValue = this.chartData.labels[index] + '\n' + this.formatValue(serieValue)

				this.showTooltip = true
			},
			onBarMouseOut() {
				this.showTooltip = false
			},
			formatValue(value, full) {
				switch (this.format) {
					case 'raw':
						return value

					case 'percentage':
						value = (value * 100)

						if (value > 0 && value < 1) {
							return value.toFixed(2) + '%'
						}

						return Math.round(value) + '%'

					case 'time':{
						const oneHour = (1 * 60 * 60 * 1000)
						let hours = (value / oneHour)
						let rHours = Math.floor(hours)
						let min = Math.round((hours - rHours) * 60)

						return (rHours > 0 ? rHours + 'h ' : '') + (min < 10 ? '0' + min : min) + (full ? 'min' : '')
					}

					default:
						return value
				}
			},
			formatBarData(data, jobs) {
				const dataKeys = Object.keys(data)

				const formattedData = dataKeys.reduce((formattedData, key) => {
					formattedData.legend.push({
						name: (this.serieName || key),
						label: (jobs[key] || key)
					})

					formattedData.series.push({
						name: (this.serieName || key),
						data: data[key]
					})

					return formattedData
				}, {
					legend: [],
					labels: this.labels,
					series: []
				})

				return formattedData
			},
			formatStackedBarData(data, jobs) {
				const stackKeys = Object.keys(data)
				let serieIndexMap = {}
				
				const formattedData = stackKeys.reduce((formattedData, stackKey, stackIndex) => {
					const serieKeys = Object.keys(data[stackKey])

					serieKeys.forEach((key) => {
						const value = data[stackKey][key]

						if (serieIndexMap[key] === undefined) {
							serieIndexMap[key] = formattedData.series.length

							formattedData.legend.push({
								name: (this.serieName || key),
								label: (jobs[key] || key)
							})

							formattedData.series.push({
								name: (this.serieName || key),
								data: [value]
							})
						} else {
							formattedData.series[serieIndexMap[key]].data.push(value)
						}

						if (value > 0) {
							formattedData.lastSerieIndexForStack[stackIndex] = serieIndexMap[key]
						} else if (formattedData.lastSerieIndexForStack[stackIndex] === undefined) {
							formattedData.lastSerieIndexForStack[stackIndex] = -1
						}
					})
					return formattedData
				}, {
					legend: [],
					labels: this.labels && [...this.labels],
					series: [],
					lastSerieIndexForStack: []
				})

				// Reverse data to display bars in order (top to bottom)
				formattedData.series.forEach(serie => serie.data.reverse())
				formattedData.labels.reverse()
				formattedData.lastSerieIndexForStack.reverse()

				return formattedData
			},
			replaceBarNode(node, barRect, dataValue, barSize) {
				// Replace bar "line" by "rect" to be able to add rounded borders and margin
				const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')

				if (this.maxBarSize !== null) {
					barSize = Math.min(this.maxBarSize, barSize)
				}

				// Safe guard for negative value
				barSize = Math.max(1, barSize)

				// Copy/update attributes
				rect.setAttributeNS(null, 'rx', 6)
				rect.setAttributeNS(null, 'ry', 6)
				rect.setAttributeNS(null, 'class', 'ct-bar')
				rect.setAttributeNS('http://gionkunz.github.com/chartist-js/ct', 'ct:value', dataValue)
				
				const positionDelta = (barSize / 2)

				if (this.horizontalBars) {
					rect.setAttributeNS(null, 'x', barRect.start)
					rect.setAttributeNS(null, 'y', barRect.position - positionDelta)
					rect.setAttributeNS(null, 'width', barRect.size)
					rect.setAttributeNS(null, 'height', barSize)
				} else {
					rect.setAttributeNS(null, 'x', barRect.position - positionDelta)
					rect.setAttributeNS(null, 'y', barRect.start)
					rect.setAttributeNS(null, 'height', barRect.size)
					rect.setAttributeNS(null, 'width', barSize)
				}

				// Replace element
				node.replaceWith(rect)

				return rect
			}
		}
	}
</script>

<style lang="scss">
	@namespace ct "http://gionkunz.github.com/chartist-js/ct";

	.dashboard-chart-bar {
		@apply pt-3;

		&.vertical-bars {
			.dashboard-chart-tooltip {
				transform: translateX(-50%) translateY(-100%) translateY(-0.60rem);
			}

			.dashboard-chart-legend {
				@apply border-t-1 border-dashboard-blue-lighter mt-4 pt-6;

				li {
					@apply w-1/3 pr-6;
				}
			}
		}

		&.horizontal-bars {
			@apply flex items-center;

			.dashboard-chart-container {
				@apply w-full;
			}

			.dashboard-chart-legend {
				@apply w-1/6 border-l-1 border-dashboard-blue-lighter pl-10;
			}

			&.has-legend {
				.dashboard-chart-container {
					@apply w-5/6;
				}
			}
		}

		&.has-tooltip {
			&.vertical-bars .dashboard-chart-container {
				rect.ct-bar:hover {
					transform: translatey(-1px);
				}
			}

			&.horizontal-bars .dashboard-chart-container {
				rect.ct-bar:hover {
					transform: translateX(1px);
				}
			}

			.dashboard-chart-container {
				rect.ct-bar:hover {
					@apply duration-500;
					transition-property: stroke-width, transform;
					stroke-width: 2;
				}

				line.ct-bar:hover {
					@apply transition-stroke-width duration-500;
					stroke-width: 32;
				}
			}
		}

		.dashboard-chart-container {
			@apply relative;

			rect.ct-bar {
				stroke-width: 0;
			}

			line.ct-bar {
				stroke-width: 30;
			}
			
			.ct-grid {
				stroke-width: 8;
			}
		}

		.dashboard-chart-tooltip {
			@apply absolute;
			transform: translateX(-50%) translateY(-0.60rem);
		}

		.dashboard-chart-legend {
			@apply flex flex-wrap;
		}
	}
</style>