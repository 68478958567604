<template>
    <div class="dashboard-superadmin" >
        <button type="button" class="mb-4 twn-button float-right" @click="ExportCSV" v-bind:disabled="!initialized || loading">
            <span v-if="!loading">
                Exporter en CSV
            </span>
            <span v-else>
                Chargement...
            </span>
        </button>
        <div class="labels float-left flex">
            <div v-for="(filter, i) in filterList" :key="i" class="filter m-4">
                <div v-if="filter.selected">
                    {{filter.label}}
                    <div class="float-right cursor-pointer ml-4 pt-1"
                        @click="ClearSelectedFilter(i)">
                        <img src="@/assets/images/NODE_CROSS.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-10 bg-white flex w-full items-center justify-between">
            <v-select
            class="twn-select-filter w-1/6"
            name="type"
            :searchable="false"
            :options="filterList"
            v-model="filter"
            placeholder="Filtres" />
            <div class="filters w-full flex justify-center">
                <div v-if="filter && filter.id == 1" class="flex items-center">
                    <label for="orga-period-from">Début: </label>
                    <b-form-datepicker v-model="fromDate" @input="SelectFilter(filter.id, $event)" type="text" id="orga-period-from" class="m-4"></b-form-datepicker>
                    <label for="orga-period-to">Fin: </label>
                    <b-form-datepicker v-model="toDate" @input="SelectFilter(filter.id, $event)" type="text" id="orga-period-to" class="m-4"></b-form-datepicker>
                </div>
                <div v-if="filter && filter.id == 2" class="flex">
                    <b-form-group label="" label-cols="auto">
                        <b-form-radio v-model="experienced" @input="SelectFilter(filter.id, $event)" name="experienced-radio" :value="true" class="ml-4">Libre</b-form-radio>
                        <b-form-radio v-model="experienced" @input="SelectFilter(filter.id, $event)" name="experienced-radio" :value="false" class="ml-4">Guidée</b-form-radio>
                    </b-form-group>
                </div>
                <div v-if="filter && filter.id == 5" class="flex">
                    <b-form-group label="" label-cols="auto">
                        <b-form-radio v-model="seniority" @input="SelectFilter(filter.id, $event)" name="seniority-radio" :value="1" class="ml-4">Administrateur expérimenté</b-form-radio>
                        <b-form-radio v-model="seniority" @input="SelectFilter(filter.id, $event)" name="seniority-radio" :value="0" class="ml-4">Administrateur nouvellement formé</b-form-radio>
                    </b-form-group>
                </div>
                <div v-if="filter && filter.id == 3" class="flex flex-col items-center">
                    <b-form-radio-group label="Statut" stacked v-model="status">
                        <b-form-radio @input="SelectFilter(filter.id, $event)" name="status-radio" :value="0" class="ml-4">Administrateur dans un OPH</b-form-radio>
                        <b-form-radio @input="SelectFilter(filter.id, $event)" name="status-radio" :value="1" class="ml-4">Collaborateur dans un OPH</b-form-radio>
                        <b-form-radio name="status-radio" :value="2" class="ml-4">Autre</b-form-radio>
                    </b-form-radio-group>
                    <b-form-group label="Employeur" label-cols="auto" v-if="status == 2">
                        <b-form-input v-model="company" @blur="(e) => { if(company) SelectFilter(filter.id, e)}">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div v-if="filter && filter.id == 4" class="flex justify-around w-full">
                    <div class="twn-select-filter flex flex-col items-center justify-center w-1/5">
                        <label for="seminar">Seminaire
                        </label>
                        <v-select @input="SelectFilter(filter.id, $event)" class="w-full" :options="seminarSelectOptions" :searchable="false" v-model="inscription.seminar" :reduce="(e) => e.value"></v-select>
                    </div>
                    <div class="twn-select-filter flex flex-col items-center justify-center w-1/5">
                        <label for="seminar">Formation Administrateur
                        </label>
                        <v-select @input="SelectFilter(filter.id, $event)" class="w-full" :options="seminarSelectOptions" :searchable="false" v-model="inscription.course" :reduce="(e) => e.value"></v-select>
                    </div>
                    <div class="twn-select-filter flex flex-col items-center justify-center w-1/5">
                        <label for="seminar">Autre formation
                        </label>
                        <v-select @input="SelectFilter(filter.id, $event)" class="w-full" :options="seminarSelectOptions" :searchable="false" v-model="inscription.other" :reduce="(e) => e.value"></v-select>
                    </div>
                    <div class="twn-select-filter flex flex-col items-center justify-center w-1/5">
                        <label for="seminar">Infos mail FOPH
                        </label>
                        <v-select @input="SelectFilter(filter.id, $event)" class="w-full" :options="mailingSelectOptions" :searchable="false" v-model="inscription.mailing" :reduce="(e) => e.value"></v-select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="initialized">
            <div class="dashboard-header">
                <div>
                    <p>Nombre total d'utilisateurs</p>
                    <div class="dashboard-header-content">
                        <img src="@/assets/images/USER_TOTAL.svg">
                        <span>{{totalUsers}}</span>
                    </div>
                </div>
                <div>
                    <p>Nombre total d'organisations</p>
                    <div class="dashboard-header-content">
                        <img src="@/assets/images/OFFICES_TOTAL.svg">
                        <span>{{totalOrganization}}</span>
                    </div>
                </div>
                <div>
                    <p>Temps de connexion moyen</p>
                    <div class="dashboard-header-content">
                        <img src="@/assets/images/USER_LOGIN_TIME.svg">
                        <span>{{userTimeAverage}}</span>
                    </div>
                </div>
                <div>
                    <p>Utilisateurs ayant terminé</p>
                    <div class="dashboard-header-content">
                        <img src="@/assets/images/USER_FINISHED.svg">
                        <span>{{userFinished}}%</span>
                    </div>
                </div>
            </div>
            <div class="dashboard-content">
                <div>
                    <p>Nombre d'utilisateurs actifs par semaine</p>
                    <bar-chart class="dashboard-chart" :chart-data="activeUsersPerWeek" :options="barChartOptions" />
                </div>
                <div>
                    <p>Nombre de nouveaux utilisateurs par semaine</p>
                    <bar-chart class="dashboard-chart" :chart-data="newUsersPerWeek" :options="barChartOptions" />
                </div>
            </div>
            <div class="dashboard-content">
                <div class="dashboard-content-wide game-result">
                    <p>Moyenne de resultat aux quiz par épisode</p>
                    <line-chart class="dashboard-chart" :chart-data="gameResultAverage" :options="lineChartOptions" />
                    <div class="exercice-info p-4 pt-8" v-if="selectedScenarioName && gameResultList.length > 0">
                        <div class="title text-sm font-bold">{{selectedScenarioName}}</div>
                        <div class="exercice-list">
                            <div v-for="result in gameResultList" :key="result.name">
                                <div class="text-sm flex items-center justify-between">
                                    <div class="pr-4">{{result.name}}</div>
                                    <div>{{result.percent}}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-content">
                <div>
                    <p>Complétion moyenne par épisode</p>
                    <line-chart class="dashboard-chart" :chart-data="scenarioCompletion" :options="completionLineChartOptions" />
                </div>
            </div>
            <div class="dashboard-content">
                <div>
                    <p>Taux de consultation des PDF par épisode</p>
                    <horizontal-bar-chart class="dashboard-chart" :chart-data="averagePdfPerEpisode" :options="mediaPercentOptions" />
                </div>
            </div>
            <div class="dashboard-content">
                <div>
                    <p>Taux de consultation des Vidéos par épisode</p>
                    <horizontal-bar-chart class="dashboard-chart" :chart-data="videoWatchPerEpisode" :options="mediaPercentOptions" />
                </div>
            </div>
        </div>
        <div v-else>Chargement...</div>
    </div>
</template>

<script>
    import BarChart from '@/components/chart/Bar'
    import HorizontalBarChart from '@/components/chart/HorizontalBar'
    import LineChart from '@/components/chart/Line'
    import XLSX from 'xlsx-js-style'
    import { mapState } from 'vuex'

    import {
        GC_GET_TOTAL,
        GC_INITALIZE_DASHBOARD_DATA
    } from '@/graphql/logs'

    export default {
        name: 'SuperAdmin',
        components: {
            BarChart,
            HorizontalBarChart,
            LineChart,
        },
        data() {
            return {
                allData: { //Formatted data for xls export
                    totalData: {
                        'Nombre total d\'utilisateurs': 0,
                        'Nombre total d\'organisations': 0,
                        'Temps de connexion moyen': 0,
                        'Utilisateurs ayant terminé': 0,
                    },
                    gameData:{
                        //name of the scenario: []
                    },
                    scenarioData:{
                        //scenarioName: []
                    },
                    userData: {},
                    officeData: {},
                },
                userHeaders: [],
                orgaHeaders: [],
                company: null,
                loading: false,
                filter: null,
                filterList: [
                    {
                        id: 1,
                        label: 'Période',
                    },
                    {
                        id: 2,
                        label: 'Navigation',
                    },
                    {
                        id: 5,
                        label: 'Ancienneté',
                    },
                    {
                        id: 3,
                        label: 'Statut'
                    },
                    {
                        id: 4,
                        label: 'Inscription'
                    }
                ],
                fromDate: null,
                toDate: null,
                experienced: null,
                status: null,
                seniority: null,
                inscription: {
                    seminar: null,
                    course: null,
                    other: null,
                    mailing: null
                },
                seminarSelectOptions: [
                    {
                        label: "Ayant participé",
                        value: true
                    },
                    {
                        label: "N'ayant pas participé",
                        value: false
                    },
                    {
                        label: "Les deux",
                        value: null
                    },
                ],
                mailingSelectOptions: [
                    {
                        label: "Abonné",
                        value: true
                    },
                    {
                        label: "Non abonné",
                        value: false
                    },
                    {
                        label: "Les deux",
                        value: null
                    },
                ],
                gameResults: null,
                initialized: false,
                pdfPerScenario: null,
                averagePdf: null,
                totalUserTime: 0,
                selectedScenarioName: null,
                totalUsers: 0,
                totalOrganization: 0,
                gameResultAverageRaw: null,
                gameResultList: [],
                scenarioCompletionPre: null,
                activePerWeek: null,
                newPerWeek: null,
                videoPerScenario: {},
                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                    'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                userFinished: 0,
                barChartOptions: {
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                            top: 30,
                            right: 18,
                            left: 18,
                            bottom: 10,
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#E1E1E1',
                                lineWidth: 0.3,
                                zeroLineColor: '#E1E1E1',
                                zeroLineWidth: 0.3,
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            }
                        }]
                    }
                },
                lineChartOptions: {
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                            top: 30,
                            right: 18,
                            left: 18,
                            bottom: 10,
                        }
                    },
                    responsive: true,
                    onHover: this.selectedScenarioData,
                    // events: ['mouseenter'],
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: (v) => {
                                    if (v.length > 20){
                                        return v.substring(0, 20) + '...'
                                    }
                                    return v;
                                }
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#E1E1E1',
                                lineWidth: 0.3,
                                zeroLineColor: '#E1E1E1',
                                zeroLineWidth: 0.3,
                            },
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return value + '%'
                                },
                            },
                        }]
                    },
                },
                completionLineChartOptions:{
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                            top: 30,
                            right: 18,
                            left: 18,
                            bottom: 10,
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: (v) => {
                                    if (v.length > 20){
                                        return v.substring(0, 20) + '...'
                                    }
                                    return v;
                                }
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#E1E1E1',
                                lineWidth: 0.3,
                                zeroLineColor: '#E1E1E1',
                                zeroLineWidth: 0.3,
                            },
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return value + '%'
                                },
                            },
                        }]
                    },
                },
                mediaPercentOptions: {
                    legend: {
                        display: true
                    },
                    layout: {
                        padding: {
                            top: 30,
                            right: 18,
                            left: 18,
                            bottom: 10,
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(item, data) {
                                return data.datasets[item.datasetIndex].label + ": " + data.datasets[item.datasetIndex].data[item.index] + '%' 
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return value + '%'
                                },
                            },
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#E1E1E1',
                                lineWidth: 0.3,
                                zeroLineColor: '#E1E1E1',
                                zeroLineWidth: 0.3,
                            },
                            ticks: {
                                callback: (v) => {
                                    if (v.length > 20){
                                        return v.substring(0, 20) + '...'
                                    }
                                    return v;
                                }
                            }
                        }]
                    },
                },
            }
        },
        async created(){
            this.GetDashboardInfo()
        },
        computed: {
            ...mapState("Logs", ['scenarios', 'revisions', 'gameNamesByID']),
            scenarioLabels(){
                if(!this.scenarios) return []

                return Object.values(this.scenarios).reduce((arr, e) => {
                    arr.push(e.name)
                    return arr
                }, [])
            },
            scenarioCompletion(){
                if (this.scenarioCompletionPre && this.scenarios){
                    let data = []
                    let completionValues = Object.values(this.scenarioCompletionPre)
                    this.scenarioLabels.forEach(label => {

                        let index = Object.keys(this.scenarioCompletionPre).findIndex(e => this.scenarios[e].name == label)
                        if (index != -1){
                            data.push(completionValues[index])
                        } else {
                            data.push(0)
                        }
                    })
                    return {
                        labels: this.scenarioLabels,
                        datasets: [
                            {
                                label: '',
                                backgroundColor: '#DC9799',
                                pointRadius: 4,
                                pointHoverRadius: 6,
                                data
                            }
                        ]
                    }
                }
                return {}
            },
            gameResultAverage(){
                if (this.gameResultAverageRaw){
                    let data = []
                    this.scenarioLabels.forEach(label => {
                        if (this.gameResultAverageRaw[label]){
                            data.push(this.gameResultAverageRaw[label])
                        } else {
                            data.push(0)
                        }
                    })

                    return {
                        labels: this.scenarioLabels,
                        datasets: [
                            {
                                label: '',
                                backgroundColor: '#DC9799',
                                pointRadius: 8,
                                pointHoverRadius: 10,
                                data
                            }
                        ]
                    }
                }
                return {}
            },
            activeUsersPerWeek() {
                if (this.activePerWeek){
                    return {
                        labels: Object.keys(this.activePerWeek),
                        datasets: [
                            {
                                label: '',
                                backgroundColor: '#DC9799',
                                hoverBackgroundColor: '#BE7173',
                                data: Object.values(this.activePerWeek)
                            }
                        ]
                    }
                }
                return {};
            },
            newUsersPerWeek(){
                if (this.newPerWeek){
                    return {
                        labels: Object.keys(this.newPerWeek),
                        datasets: [
                            {
                                label: '',
                                backgroundColor: '#DC9799',
                                hoverBackgroundColor: '#BE7173',
                                data: Object.values(this.newPerWeek)
                            }
                        ]
                    }
                }
                return {}
            },
            userTimeAverage(){
                if (this.totalUserTime){
                    let hour = Math.floor(this.totalUserTime)
                    let decimalPart = this.totalUserTime - hour;

                    let min = 1/60;

                    decimalPart = min * Math.round(decimalPart / min)
                    let minute = Math.floor(decimalPart * 60) + ''

                    if (minute.length < 2){
                        minute = '0' + minute
                    }

                    return hour + 'h' + minute;
                }
                return 0
            },
            averagePdfPerEpisode(){
                if (this.averagePdf){
                    let data = []
                    let completionValues = Object.values(this.averagePdf)
                    this.scenarioLabels.forEach(label => {
                        let index = Object.keys(this.averagePdf).findIndex(e => this.scenarios[e].name == label)
                        if (index != -1){
                            data.push(completionValues[index])
                        } else {
                            data.push(0)
                        }
                    })
                    return {
                        labels:this.scenarioLabels,
                        datasets: [
                            {
                                label: 'Taux de téléchargement des fiches',
                                backgroundColor: '#DC9799',
                                hoverBackgroundColor: '#DC9799',
                                data
                            },
                        ]
                        
                    }
                }
                return {}
            },
            videoWatchPerEpisode(){
                if (this.videoPerScenario){
                    let data = []
                    let completionValues = Object.values(this.videoPerScenario)
                    this.scenarioLabels.forEach(label => {
                        let index = Object.keys(this.videoPerScenario).findIndex(e => this.scenarios[e].name == label)
                        if (index != -1){
                            data.push(completionValues[index])
                        } else {
                            data.push(0)
                        }
                    })
                    return {
                        labels:this.scenarioLabels,
                        datasets: [
                            {
                                label: 'Taux de visionnage des vidéos',
                                backgroundColor: '#DC9799',
                                hoverBackgroundColor: '#DC9799',
                                data
                            },
                        ]
                    }
                }
                return {}
            }
        },
        methods: {
            async ExportCSV(){
                this.loading = true
                let wb = XLSX.utils.book_new();

                this.Export_FormatTotal(wb)
                this.Export_FormatGame(wb)
                this.Export_FormatUsers(wb)
                this.Export_AllUsers(wb)
                await this.Export_AllOrga(wb)

                let today = new Date();
                let name = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}-${today.getSeconds()}_data.xlsx`
                XLSX.writeFile(wb, name);
                this.loading = false
            },
            async Export_AllOrga(wb){
                let sheet_orga = XLSX.utils.aoa_to_sheet([
                    this.orgaHeaders
                ])

                let officeData = await this.$store.dispatch('Logs/GetAllOfficeData', {
                    ids: Object.keys(this.allData.officeData),
                    filters: {
                        company: this.company,
                        from: this.fromDate,
                        to: this.toDate,
                        xp: this.experienced,
                        mailing: this.inscription.mailing,
                        course: this.inscription.course,
                        other: this.inscription.other,
                        seminar: this.inscription.seminar,
                        seniority: this.seniority,
                        status: this.status
                    }
                })

                let index = 1
                let orderedOffices = {}
                let officeByName = {}
                //order user data by name 
                for (const officeID in this.allData.officeData) {
                    if (Object.prototype.hasOwnProperty.call(this.allData.officeData, officeID)) {
                        this.allData.officeData[officeID]['Taux de réussite aux exercices'] = officeData[officeID]['Taux de réussite aux exercices']
                        this.allData.officeData[officeID]['Completion totale'] = officeData[officeID]['Completion totale']
                        this.allData.officeData[officeID]['Utilisateurs ayant terminé'] = officeData[officeID]['Utilisateurs ayant terminé']
                        this.allData.officeData[officeID]['Temps de connexion moyen'] = this.Hour10ToDate(officeData[officeID]['Temps de connexion moyen'])

                        const officeInfo = this.allData.officeData[officeID]
                        officeByName[officeInfo.Nom] = officeInfo
                    }
                }

                Object.keys(officeByName).sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }).forEach(key => {
                    orderedOffices[key] = officeByName[key]
                })

                for (const officeID in orderedOffices) {
                    if (Object.prototype.hasOwnProperty.call(orderedOffices, officeID)) {
                        const officeInfo = orderedOffices[officeID]
                        XLSX.utils.sheet_add_aoa(sheet_orga, [Object.values(officeInfo)], {origin:{r:index, c:0}})
                        index++
                    }
                }

                XLSX.utils.book_append_sheet(wb, sheet_orga, 'Liste Organisationss')
            },
            Export_FormatTotal(wb){
                let sheet_Total = XLSX.utils.aoa_to_sheet([
                    Object.keys(this.allData.totalData),
                    Object.values(this.allData.totalData)
                ])
                XLSX.utils.book_append_sheet(wb, sheet_Total, 'Total')
            },
            Export_FormatGame(wb){
                let sheet = XLSX.utils.aoa_to_sheet([
                    ["Données de scénarios (%)"]
                ])
                let index = 2

                for (const scenarioName in this.allData.scenarioData) {
                    if (Object.prototype.hasOwnProperty.call(this.allData.scenarioData, scenarioName)) {

                        let scenarioData = this.allData.scenarioData[scenarioName]

                        XLSX.utils.sheet_add_aoa(sheet, [
                            [scenarioName],
                        ], {origin:{r:index, c:0}});
                        index++

                        XLSX.utils.sheet_add_aoa(sheet, [
                            Object.keys(scenarioData),
                            Object.values(scenarioData)
                        ], {origin:{r:index, c:0}});
                        index+= 2

                        if (this.allData.gameData[scenarioName]){
                            let scenarioGames = this.allData.gameData[scenarioName];
                            XLSX.utils.sheet_add_aoa(sheet, [
                                Object.keys(scenarioGames),
                                Object.values(scenarioGames)
                            ], {origin:{r:index, c:0}});
                            index+= 2
                        }

                        index++

                    }
                }

                XLSX.utils.book_append_sheet(wb, sheet, 'Scenarios')
            },
            Export_FormatUsers(wb){
                let sheet_userPerWeek = XLSX.utils.aoa_to_sheet([
                    ["Utilisateurs (actifs/nouveaux) par semaine"],
                    [],
                    ["Actifs"]
                ])
                let index = 3

                XLSX.utils.sheet_add_aoa(sheet_userPerWeek, [
                    Object.keys(this.activePerWeek),
                    Object.values(this.activePerWeek)
                    ], {origin:{r:index, c:0}});
                index += 3

                XLSX.utils.sheet_add_aoa(sheet_userPerWeek, [
                    ["Nouveaux"]
                ], {origin:{r:index, c:0}});
                index++

                XLSX.utils.sheet_add_aoa(sheet_userPerWeek, [
                    Object.keys(this.newPerWeek),
                    Object.values(this.newPerWeek)
                ], {origin:{r:index, c:0}});

                XLSX.utils.book_append_sheet(wb, sheet_userPerWeek, 'Activité utilisateurs')
            },
            Export_AllUsers(wb){
                
                let sheet_users = XLSX.utils.aoa_to_sheet([
                    this.userHeaders
                ])

                let index = 1

                let orderedUsers = {}
                let userByName = {}
                //order user data by name 
                for (const userID in this.allData.userData) {
                    if (Object.prototype.hasOwnProperty.call(this.allData.userData, userID)) {
                        const userInfo = this.allData.userData[userID]
                        userByName[userInfo.Nom] = userInfo
                    }
                }

                Object.keys(userByName).sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }).forEach(key => {
                    orderedUsers[key] = userByName[key]
                })

                for (const userID in orderedUsers) {
                    if (Object.prototype.hasOwnProperty.call(orderedUsers, userID)) {
                        const userInfo = orderedUsers[userID]
                        XLSX.utils.sheet_add_aoa(sheet_users, [Object.values(userInfo)], {origin:{r:index, c:0}})
                        index++
                    }
                }

                XLSX.utils.book_append_sheet(wb, sheet_users, 'Liste utilisateurs')

            },
            Hour10ToDate(hour10){
                let hour = Math.floor(hour10)
                let decimalPart = hour10 - hour;

                let min = 1/60;

                decimalPart = min * Math.round(decimalPart / min)
                let minute = Math.floor(decimalPart * 60) + ''

                if (minute.length < 2){
                    minute = '0' + minute
                }

                return hour + 'h' + minute
            },
            async GetDashboardInfo(){
                this.initialized = false
                let waitForAll = []
                this.gameResultList = []
                await this.InitDashboardData()
                await this.GetTotal()
                await this.GetScenarioCompletion()
                waitForAll.push(this.GetFinishedUsers())
                waitForAll.push(this.GetAverageCoTime())
                waitForAll.push(this.GetActiveUsersPerWeek())
                waitForAll.push(this.GetNewUsersPerWeek())
                waitForAll.push(this.GetGameResults())
                Promise.all(waitForAll).then(() => {
                    this.initialized = true
                    window.scrollTo(0,0)
                    this.allData.totalData['Nombre total d\'organisations'] = this.totalOrganization
                    this.allData.totalData['Nombre total d\'utilisateurs'] = this.totalUsers
                })
            },
            async InitDashboardData(){
                const dashboardData = await this.$apollo.query({
                    query: GC_INITALIZE_DASHBOARD_DATA
                })

                if (dashboardData.data && dashboardData.data.scenario && dashboardData.data.game){

                    let scenarios = {}
                    let gameNamesByID = {}
                    let revisions = {}
                    dashboardData.data.scenario.forEach(scenario => {
                        // if (scenario.scenarioLocations && scenario.scenarioLocations.length > 0){
                            if (!scenarios[scenario.id]) {
                                scenarios[scenario.id] = {}
                            }
                            scenarios[scenario.id]['location'] = scenario.scenarioLocations
                            scenarios[scenario.id]['name'] = scenario.name
                            // this.scenarios[scenario.id]['count'] = scenario.nodes_aggregate.aggregate.count
                        // }
                    })

                    dashboardData.data.revisions.forEach(revision => {
                            if (!revisions[revision.id]) {
                                revisions[revision.id] = {}
                            }
                            revisions[revision.id]['location'] = "here" //TODO: populate with real locations
                            revisions[revision.id]['name'] = revision.name
                            // this.scenarios[scenario.id]['count'] = scenario.nodes_aggregate.aggregate.count
                    })

                    this.$store.dispatch('Logs/SetScenarios', scenarios)
                    this.$store.dispatch('Logs/SetRevisions', revisions)

                    dashboardData.data.game.forEach(game => {
                        gameNamesByID[game.id] = game.title
                    })

                    this.$store.dispatch('Logs/SetGameNamesByID', gameNamesByID)
                }
            },
            selectedScenarioData(e, data){
                if (data[0]){
                    let element = data[0]
                    let chart = element._chart
                    let index = element._index

                    this.selectedScenarioName = chart.data.labels[index]

                    this.gameResultList = []

                    for (const exerciceName in this.gameResults[this.selectedScenarioName]) {
                        if (Object.prototype.hasOwnProperty.call(this.gameResults[this.selectedScenarioName], exerciceName)) {
                            const result = this.gameResults[this.selectedScenarioName][exerciceName]
                            this.gameResultList.push({
                                name: exerciceName,
                                percent: result
                            })
                        }
                    }
                }
            },
            async GetTotal(){
                const response = await this.$apollo.query({
                    query: GC_GET_TOTAL,
                    variables: {
                        company: this.company,
                        from: this.fromDate,
                        to: this.toDate,
                        xp: this.experienced,
                        mailing: this.inscription.mailing,
                        course: this.inscription.course,
                        other: this.inscription.other,
                        seminar: this.inscription.seminar,
                        seniority: this.seniority,
                        status: this.status
                    }
                })

                this.totalUsers = 0
                this.allData.userData = {}
                if (response && response.data.users){
                    this.totalUsers = response.data.users.length

                    await this.$store.dispatch('Logs/SetTotalUsers', this.totalUsers)
                    let headerSet = false

                    response.data.users.forEach(user => {
                        if (!this.allData.userData[user.id]){

                            let status;
                            if (!user.status){
                                status = "Administrateur dans un OPH"
                            } else if (user.status == 1){
                                status = "Collaborateur dans un OPH"
                            } else {
                                status = "Autre"
                            }

                            this.allData.userData[user.id] = {
                                'Nom': user.name,
                                'Prénom': user.first_name,
                                Office: user.userOrganisations[0] ? user.userOrganisations[0].organisations.name : '',
                                Email: user.email,
                                Navigation: user.experienced ? 'Libre' : 'Guidée',
                                Status: status,
                                'Employeur': user.company,
                                'Ancienneté': user.seniority ? 'Administrateur expérimenté' : 'Administrateur nouvellement formé',
                                'Formation Administrateur': user.experience_course ? 'Oui' : 'Non',
                                'Séminaire': user.experience_seminar ? 'Oui' : 'Non',
                                'Autre Formation': user.experience_other ? 'Oui' : 'Non',
                                'Infos FOPH': user.mailing ? 'Abonné' : 'Non abonné',
                                'Temps de connexion moyen': '0h00',
                                'Taux de réussite aux exercices': 0,
                                'Completion totale': 0,
                                'Scenario terminés': 0
                            }

                            if (!headerSet){
                                this.userHeaders = Object.keys(this.allData.userData[user.id])
                                headerSet = true
                            }
                        }
                    })
                }

                if(response && response.data.user_organisations){
                    let headerSet = false

                    response.data.user_organisations.forEach(preOrga => {
                        let office = preOrga.organisations
                        if (!this.allData.officeData[office.id]){
                            this.allData.officeData[office.id] = {
                                Nom: office.name,
                                Adresse: office.address,
                                Adresse2: office.address2,
                                Ville: office.city,
                                Pays: office.country,
                                'Code Postal': office.post_code,
                                'Nombre d\'utilisateurs': office.userCount.aggregate.count,
                                'Temps de connexion moyen': 0,
                                'Taux de réussite aux exercices': 0,
                                'Completion totale': 0,
                                'Utilisateurs ayant terminé': 0
                            }
                        }

                        if (!headerSet){
                            this.orgaHeaders = Object.keys(this.allData.officeData[office.id])
                            headerSet = true
                        }
                    })
                    this.totalOrganization = Object.keys(this.allData.officeData).length
                }
            },
            async GetGameResults(){
                let gameResultData = await this.$store.dispatch('Logs/GetGameResults', {
                    company: this.company,
                    from: this.fromDate,
                    to: this.toDate,
                    xp: this.experienced,
                    mailing: this.inscription.mailing,
                    course: this.inscription.course,
                    other: this.inscription.other,
                    seminar: this.inscription.seminar,
                    seniority: this.seniority,
                    status: this.status
                })

                if (gameResultData.dashboard){
                    this.gameResultAverageRaw = gameResultData.dashboard.gameResultAverageRaw
                    this.gameResults = gameResultData.dashboard.gameResults
                    this.allData.gameData = this.gameResults
                    this.Export_FormatGameResultDataByUser(gameResultData.xls.sumPerUser, gameResultData.xls.tryPerUser)
                } else {
                    this.gameResultAverageRaw = 0
                    this.gameResults = {}
                    this.allData.gameData = this.gameResults
                }

            },
            async Export_FormatGameResultDataByUser(sumPerUser, tryPerUser){ //To not disrupt load time because there might be a lot of users
                //Export xls
                for (const userID in sumPerUser) {
                    if (Object.prototype.hasOwnProperty.call(sumPerUser, userID)) {
                        const sum = sumPerUser[userID]
                        const userTry = tryPerUser[userID]
                        
                        if (this.allData.userData[userID]){
                            this.allData.userData[userID]['Taux de réussite aux exercices'] = Math.round((Math.min((sum / userTry), 1) * 100))
                        }
                    }
                }
            },
            async GetScenarioCompletion(){
                let scenarioData = await this.$store.dispatch('Logs/GetScenarioCompletion', {
                    company: this.company,
                    from: this.fromDate,
                    to: this.toDate,
                    xp: this.experienced,
                    mailing: this.inscription.mailing,
                    course: this.inscription.course,
                    other: this.inscription.other,
                    seminar: this.inscription.seminar,
                    seniority: this.seniority,
                    status: this.status
                })

                if (scenarioData.dashboard){
                    this.averagePdf = scenarioData.dashboard.averagePdf
                    this.videoPerScenario = scenarioData.dashboard.videoPerScenario
                    this.scenarioCompletionPre = scenarioData.dashboard.scenarioCompletionPre
                    this.allData.scenarioData = scenarioData.xls.scenarioData
                    this.Export_FormatCompletionDataByUser(scenarioData.xls.progressPerUser, scenarioData.xls.tryPerUser)
                } else {
                    this.averagePdf = 0
                    this.videoPerScenario = 0
                    this.scenarioCompletionPre = 0
                    this.allData.scenarioData = {}
                }

            },
            async Export_FormatCompletionDataByUser(progressPerUser){ //To not disrupt load time because there might be a lot of users
                //Export xls
                for (const userID in progressPerUser) {
                    if (Object.prototype.hasOwnProperty.call(progressPerUser, userID)) {
                        const sum = progressPerUser[userID]

                        if (this.allData.userData[userID]){
                            this.allData.userData[userID]['Completion totale'] = Math.round(((sum / Object.keys(this.scenarios).length) * 100))
                        }
                    }
                }
            },
            async GetActiveUsersPerWeek(){
                let activePerWeek = await this.$store.dispatch('Logs/GetActiveUsersPerWeek', {
                    company: this.company,
                    from: this.fromDate,
                    to: this.toDate,
                    xp: this.experienced,
                    mailing: this.inscription.mailing,
                    course: this.inscription.course,
                    other: this.inscription.other,
                    seminar: this.inscription.seminar,
                    seniority: this.seniority,
                    status: this.status
                })
                this.activePerWeek = activePerWeek
            },
            async GetNewUsersPerWeek(){
                let newPerWeek = await this.$store.dispatch('Logs/NewUsersPerWeek', {
                    company: this.company,
                    from: this.fromDate,
                    to: this.toDate,
                    xp: this.experienced,
                    mailing: this.inscription.mailing,
                    course: this.inscription.course,
                    other: this.inscription.other,
                    seminar: this.inscription.seminar,
                    seniority: this.seniority,
                    status: this.status
                })
                this.newPerWeek = newPerWeek
            },
            async GetFinishedUsers(){
                let finishedData = await this.$store.dispatch('Logs/GetFinishedUsers', {
                    company: this.company,
                    from: this.fromDate,
                    to: this.toDate,
                    xp: this.experienced,
                    mailing: this.inscription.mailing,
                    course: this.inscription.course,
                    other: this.inscription.other,
                    seminar: this.inscription.seminar,
                    seniority: this.seniority,
                    status: this.status
                })

                this.userFinished = finishedData.finished
                for (const userID in finishedData.finishedByUser) {
                    if (Object.prototype.hasOwnProperty.call(finishedData.finishedByUser, userID)) {
                        const completion = finishedData.finishedByUser[userID]
                        this.allData.userData[userID]['Scenario terminés'] = completion
                    }
                }
            },
            async GetAverageCoTime(){
                let coTimeData = await this.$store.dispatch('Logs/GetAverageCoTime', {
                    company: this.company,
                    from: this.fromDate,
                    to: this.toDate,
                    xp: this.experienced,
                    mailing: this.inscription.mailing,
                    course: this.inscription.course,
                    other: this.inscription.other,
                    seminar: this.inscription.seminar,
                    seniority: this.seniority,
                    status: this.status
                })
                this.totalUserTime = coTimeData.totalUserTime

                for (const userID in coTimeData.timePerUser) {
                    if (Object.prototype.hasOwnProperty.call(coTimeData.timePerUser, userID)) {
                        const time = coTimeData.timePerUser[userID]
                        if (this.allData.userData[userID]){
                            this.allData.userData[userID]['Temps de connexion moyen'] = this.Hour10ToDate(time)
                        }
                    }
                }
                this.allData.totalData['Temps de connexion moyen'] = this.Hour10ToDate(this.totalUserTime)
            },
            SelectFilter(i, e){
                let index = this.filterList.findIndex(filter => filter.id == i)
                if (e || e === false || e === 0){
                    this.filterList[index].selected = true
                    this.GetDashboardInfo()
                } else if(index == 4){
                    if (this.inscription.seminar == null && this.inscription.course == null && this.inscription.other == null && this.inscription.mailing == null){
                        this.filterList[index].selected = false
                    }
                    this.GetDashboardInfo()
                }
            },
            ClearSelectedFilter(index){
                //TODO: make this generic for all filters (bad switch case bad)
                switch (this.filterList[index].label) {
                    case 'Période':
                        this.fromDate = null
                        this.toDate = null
                        break;
                    case 'Navigation':
                        this.experienced = null
                        break;
                    case 'Ancienneté':
                        this.seniority = null
                        break;
                    case 'Statut':
                        this.status = null
                        this.company = null
                        break;
                    case 'Inscription':
                        this.inscription.seminar = null
                        this.inscription.course = null
                        this.inscription.other = null
                        this.inscription.mailing = null
                        break;
                }
                this.filterList[index].selected = false
                this.GetDashboardInfo()
            },
        }
    }
</script>

<style lang="scss">
.twn-select-filter{
    button.vs__clear{
        display:none !important;
    }
}
</style>

<style lang="scss" scoped>
.dashboard-header, .dashboard-content {
    @apply flex justify-between mb-10;

    & > div {
        @apply flex flex-col justify-start rounded-lg shadow-twn p-8 flex-1;

        margin-right: 2%;

        &:last-child {
            margin-right: 0;
        }

        & > p {
            @apply text-xs font-principal-bold uppercase mb-4;
        }

        .dashboard-header-content {
            @apply flex justify-between;

            & > img {
                @apply h-12;
            }

            & > span {
                @apply text-3xl text-gray-star;
            }
        }

        &.dashboard-content-wide {
            flex-grow: 3;
        }
        .exercice-info{
            background-color: #f9f9f9;
            .exercice-list {
                > div {
                    @apply my-1 py-2 px-1;
                    background-color: white;
                }
            }
        }
    }
    .game-result > div {
        border-radius: 0;
        > div {
            // @apply items-center;
            background-color: #f9f9f9
        }
    }
}

.dashboard-chart {
    @apply rounded-lg bg-gray-background;

    max-height: 40vh;
}
</style>