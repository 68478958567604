import gql from 'graphql-tag'

const GC_GET_CHARACTER_LIST = gql`
query GetCharacterList {
    character(order_by: {name: asc}) {
    	id
      slug
      name
    	job
      avatar_path
    }
  }
`

const GC_ADD_CHARACTER_ONE = gql`
mutation AddCharacterOne($slug: String = "", $name: String = "", $job: String = "", $avatar_path: String = "") {
	insert_character_one(object: {slug: $slug, name: $name, job: $job, avatar_path: $avatar_path}) {
		id
	}
}
`

const GC_UPDATE_CHARACTER_BY_ID = gql`
mutation UpdateCharacterOne($id: uuid!, $slug: String = "", $name: String = "", $job: String = "", $avatar_path: String = "") {
	update_character(where: {id: {_eq: $id}}, _set: {slug: $slug, name: $name, job: $job, avatar_path: $avatar_path}) {
		affected_rows
    returning {
      id
    }
	}
}
`

const GC_DELETE_CHARACTER_ONE = gql`
mutation DeleteCharacter($id:uuid!) {
  delete_character(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
`

export {
	GC_GET_CHARACTER_LIST,
	GC_ADD_CHARACTER_ONE,
	GC_UPDATE_CHARACTER_BY_ID,
	GC_DELETE_CHARACTER_ONE
}