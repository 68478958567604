import Rete from "rete";
import VueAssetControl from "./AssetControl.vue";

export class AssetControl extends Rete.Control {
  constructor(key, label, restrictTypes, dropZone, subtitles = false) {
    super(key);
    this.component = VueAssetControl;
    this.props = { ikey: key, label, restrictTypes, dropZone, subtitles };
  }
}
