// SRT format regex
const startEndTimesRegex = new RegExp(/^([0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}) --> ([0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3})$/)
const timeRegex = new RegExp(/^([0-9]{2}):([0-9]{2}):([0-9]{2}),([0-9]{3})$/)

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
}

export function toSRTTimeString(timeInMs) {
	const hours = Math.trunc(timeInMs / 3600000, 0)
	const minutes = Math.trunc((timeInMs - (hours * 3600000)) / 60000, 0)
	const seconds = Math.trunc((timeInMs - (hours * 3600000) - (minutes * 60000)) / 1000, 0)
	const millis = Math.trunc((timeInMs - (hours * 3600000) - (minutes * 60000) - (seconds * 1000)), 0)

	let timeStr = [hours, minutes, seconds].map((number) => (number < 10 ? '0' + number : number)).join(':')
	timeStr += ',' + (millis < 100 ? '0' : '') + (millis < 10 ? '0' : '') + millis

	return timeStr
}

export function fromSRTTimeString(timeStr) {
	const matches = timeRegex.exec(timeStr.trim())

	if (!matches || matches.length < 5)
		return 0

	const hours = parseInt(matches[1], 10)
	const minutes = parseInt(matches[2], 10)
	const seconds = parseInt(matches[3], 10)
	const millis = parseInt(matches[4], 10)

	return (hours * 3600000) + (minutes * 60000) + (seconds * 1000) + millis
}

export function parseSRT(content) {
	const subtitles = content.split(/\r?\n\r?\n/)

	return subtitles.reduce((list, subtitle) => {
		// Remove space or newline at start/end of string
		subtitle = subtitle.trim()

		// Filter empty subtitle
		if (!subtitle)
			return list

		// Get parts
		const parts = subtitle.split(/\r?\n/)

		// Filter subtitle without at least 3 parts (subtitle index, times and text)
		if (parts.length < 3)
			return list

		// Get index
		const index = parseInt(parts[0], 10)
		
		// Parse start and end times
		const timesMatches = startEndTimesRegex.exec(parts[1].trim())

		// Filter subtitle without start/end times
		if (!timesMatches || timesMatches.length < 3)
			return list

		const start = fromSRTTimeString(timesMatches[1])
		const end = fromSRTTimeString(timesMatches[2])
		
		// Get subtitle text content
		let lines = []

		for (let i = 2; i < parts.length; i++) {
			lines.push(parts[i])
		}

		list.push({
			index,
			start,
			end,
			text: lines.join('\n')
		})

		return list
	}, []).sort((a, b) => (a.index - b.index))
}

export function formatSRT(lines) {
	return lines.reduce((content, line, index) => {
		if (!line.text.trim())
			return content

		// Add blank line if needed 
		content += (index > 0 ? '\n\n' : '')

		// Add index and start and end times
		content += index + '\n'
		content += toSRTTimeString(line.start) + ' --> ' + toSRTTimeString(line.end) + '\n'

		// Make sure the subtitles doesn't contain any empty line
		content += line.text.split(/\r?\n/).filter((str) => (str || false)).join('\n')

		return content
	}, '')
}