import utils from "@/utils/trackingReportTemplate"

import { sequenceState, sequencesState, userLatestSequenceDates } from "../common/commonSequences"

export default {
	createdWeekId(user) {
		// Generate a uniq week id for the user subscription date
		return utils.weekId(new Date(user.created_at))
	},
	logsData(user) {
		// Init computed data
		let computedLogsData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			perScenario: {},
			sumPerSequence: {},
			averagePerScenario: {},
			averageSpentPerScenario: {},
			lastSessionTimestamp: 0,
			scenariosDone: {},
			bestScorePerGame: {},
			sequencesDone: {},
			loginPerDay: {},
		}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Add log time data
			times.push({
				time: (new Date(log.created_at)).getTime(),
				sequence_id: log.sequence_id,
			})

			// Handle scenario done and times data and best game score
			switch (log.type.slug) {
				case 'scenario':
					if (!computedLogsData.perScenario[log.data_key]) {
						computedLogsData.perScenario[log.data_key] = []
					}

					computedLogsData.perScenario[log.data_key].push({ start: (new Date(log.created_at)).getTime(), update: (new Date(log.updated_at)).getTime() })
					break

				case 'scenario_end':
					if (computedLogsData.perScenario[log.data_key] && computedLogsData.perScenario[log.data_key].length > 0) {
						const time = computedLogsData.perScenario[log.data_key][computedLogsData.perScenario[log.data_key].length - 1]

						time.end = (new Date(log.created_at)).getTime()
					}

					computedLogsData.scenariosDone[log.data_key] = true
					break

				case 'game':
					if (!computedLogsData.bestScorePerGame[log.data_key]) {
						computedLogsData.bestScorePerGame[log.data_key] = log.data
					} else if (log.data > computedLogsData.bestScorePerGame[log.data_key]) {
						computedLogsData.bestScorePerGame[log.data_key] = log.data
					}
					break

				case 'sequence_end':
					computedLogsData.sequencesDone[log.data_key] = new Date(log.created_at)
					break

				case 'login': {
					const dayId = utils.dayId(new Date(log.created_at))

					if (!computedLogsData.loginPerDay[dayId]) {
						computedLogsData.loginPerDay[dayId] = []
					}

					computedLogsData.loginPerDay[dayId].push(log)
					break
				}
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const fiveMinutes = (5 * 60 * 1000)

		let lastLogTime = 0

		times.forEach((logTimeData) => {
			const logTime = logTimeData.time
			const deltaTime = (logTime - lastLogTime)

			if (logTimeData.sequence_id && !computedLogsData.sumPerSequence[logTimeData.sequence_id]) {
				computedLogsData.sumPerSequence[logTimeData.sequence_id] = 0
			}

			// Check if too logs are too far apart
			if (deltaTime > oneHour) {
				// Start a new session with the minimal time (5 min)
				computedLogsData.lastSessionTimestamp = logTime
				computedLogsData.times.push({
					start: logTime,
					duration: fiveMinutes
				})

				// Update sum
				computedLogsData.sum += fiveMinutes

				// Update sequence time
				if (logTimeData.sequence_id) {
					computedLogsData.sumPerSequence[logTimeData.sequence_id] += fiveMinutes
				}

				// Register session week as active
				computedLogsData.weekIds[utils.weekId(new Date(logTime))] = true
			} else {
				// Increment current session time
				computedLogsData.times[computedLogsData.times.length - 1].duration += deltaTime

				// Update sum
				computedLogsData.sum += deltaTime

				// Update sequence time
				if (logTimeData.sequence_id) {
					computedLogsData.sumPerSequence[logTimeData.sequence_id] += deltaTime
				}
			}

			// Update max
			const sessionTime = computedLogsData.times[computedLogsData.times.length - 1].duration

			if (sessionTime > computedLogsData.max) {
				computedLogsData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedLogsData.times.length > 0) {
			computedLogsData.average = (computedLogsData.sum / computedLogsData.times.length)
		}

		// Compute scénario time data
		const scenarioIds = Object.keys(computedLogsData.perScenario)

		scenarioIds.forEach((id) => {
			const times = computedLogsData.perScenario[id]
			
			let doneSum = 0
			let doneCount = 0

			let spentSum = 0
			let spentCount = 0

			// Make an average of all time data
			times.forEach((time) => {
				// Check for valid/done time data for
				if (time.end) {
					const deltaTime = (time.end - time.update)

					if (deltaTime <= oneHour) {
						doneSum += (time.end - time.start)
						doneCount += 1
					}
				}

				spentSum += (time.update - time.start)
				spentCount += 1
			})

			if (doneCount > 0) {
				computedLogsData.averagePerScenario[id] = (doneSum / doneCount)
			}

			if (spentCount > 0) {
				computedLogsData.averageSpentPerScenario[id] = (spentSum / spentCount)
			}
		})

		return computedLogsData
	},
	activeTimeData(user) {
		return {
			times: user.logsData.times,
			sum: user.logsData.sum,
			max: user.logsData.max,
			average: user.logsData.average,
			weekIds: user.logsData.weekIds,
			lastSessionTimestamp: user.logsData.lastSessionTimestamp
		}
	},
	activeTimePerScenario(user) {
		return user.logsData.averagePerScenario
	},
	activeTimeSpentPerScenario(user) {
		return user.logsData.averageSpentPerScenario
	},
	activeTimesPerScenario(user) {
		return user.logsData.perScenario
	},
	scenariosDone(user) {
		return user.logsData.scenariosDone
	},
	latestSequenceDates(user, data) {
		return userLatestSequenceDates(user, data.promotion.sequences)
	},
	sequenceState(user, data) {
		return sequenceState(user.logsData.sequencesDone, user.logsData.loginPerDay, user.latestSequenceDates, data.course.sequences, data.promotion.sequencesById, data.promotionType, data.promotion)
	},
	sequencesState(user, data) {
		return sequencesState(user.sequenceState, data.course.sequences, data.promotion.sequencesById)
	},
}