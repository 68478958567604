// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	float: '#0.00',
	percentage: '0.00%',
	duration: {
		format: '[h]"h" mm"min"',
		transform: (time) => (!isNaN(time) ? (time / oneDay) : false),
	},
	date: {
		format: 'dd/MM/yyyy "à" HH"h"mm',
		transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
	},
	dateOnly: {
		format: 'dd/MM/yyyy',
		transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
	}
}