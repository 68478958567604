import { GC_GET_SUBTITLE_LIST, GC_ADD_SUBTITLE_ONE, GC_UPDATE_SUBTITLE_BY_ID, GC_DELETE_SUBTITLE_ONE } from '@/graphql/subtitle'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_SUBTITLE_LIST(state, subtitles) {
            state.list = (subtitles || []);
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_SUBTITLE_LIST })

            commit('SET_SUBTITLE_LIST', response.data.subtitle)
        },
        async save(ctx, { id, media_id, character_id }) {
            // Create or update subtitle
            if (id) {
                // Update remote data
                const response = await apollo.mutate({
                    mutation: GC_UPDATE_SUBTITLE_BY_ID,
                    variables: {
                        id,
                        media_id,
                        character_id
                    }
                })

                return (response && response.data && response.data.update_subtitle.returning[0] ? response.data.update_subtitle.returning[0].id : null)
            } else {
                // Add remote data
                const response = await apollo.mutate({
                    mutation: GC_ADD_SUBTITLE_ONE,
                    variables: {
                        media_id,
                        character_id
                    }
                })

                return (response && response.data && response.data.insert_subtitle_one ? response.data.insert_subtitle_one.id : null)
            }
        },
        delete(ctx, id) {
            // Delete from db
            return apollo.mutate({
                mutation: GC_DELETE_SUBTITLE_ONE,
                variables: { id }
            })
        },
    }
}