<template>
	<div class="dashboard-kpi shadow-light" :class="classes">
		<div v-if="help" class="dashboard-kpi-tooltip">
			<span :class="(Math.round(help.length / 30) <= 2 ? 'w-48' : 'w-56')">{{help}}</span>
			<p>?</p>
		</div>

		<p v-if="valueOnTop" :class="(valueColor ? 'text-' + valueColor : null)">{{formatedValue}}</p>
		
		<h1 v-if="title">{{title}}</h1>
		<h2 v-if="subtitle">{{subtitle}}</h2>

		<p v-if="!valueOnTop" :class="(valueColor ? 'text-' + valueColor : null)">{{formatedValue}}</p>
	</div>
</template>

<script>
	export default {
		name: 'KPIBlock',
		props: {
			title: {
				type: String,
				required: true
			},
			subtitle: {
				type: String,
				default: null
			},
			help: {
				type: String,
				default: null
			},
			number: {
				type: Number,
				default: null
			},
			percentage: {
				type: Number,
				default: null
			},
			time: {
				type: Number,
				default: null
			},
			date: {
				type: Date,
				default: null
			},
			raw: {
				type: String,
				default: null
			},
			theme: {
				type: String,
				default: null
			},
			backgroundColor: {
				type: String,
				default: null
			},
			textColor: {
				type: String,
				default: null
			},
			valueColor: {
				type: String,
				default: null
			}
		},
		computed: {
			classes() {
				let classes = []

				if (this.backgroundColor) {
					classes.push(this.backgroundColor)
					classes.push(this.textColor || 'text-white')
				} else if (this.textColor) {
					classes.push(this.textColor)
				}

				if (this.theme) {
					classes.push(this.theme)
				}

				return classes.join(' ')
			},
			formatedValue() {
				if (this.raw !== null) {
					return this.raw
				}

				const leadingZero = ((number) => (number < 10 && number > 0 ? '0' + number : number))

				if (this.time != null) {
					const oneHour = (1 * 60 * 60 * 1000)
					
					let remainingTime = (this.time / oneHour)
					const hours = Math.floor(remainingTime)

					remainingTime = (remainingTime - hours) * 60
					const minutes = Math.floor(remainingTime)

					remainingTime = (remainingTime - minutes) * 60
					const seconds = Math.round(remainingTime)

					return (hours > 0 ? hours + 'h ' : '') + (minutes > 0 || seconds <= 0 ? leadingZero(minutes) + 'm ' : '') + (hours <= 0 && seconds > 0 ? leadingZero(seconds) + 's' :  '')
				} else if (this.date) {
					return [leadingZero(this.date.getDate()), leadingZero(this.date.getMonth() + 1), this.date.getFullYear()].join('/')
				} else if (this.percentage != null) {
					const value = (this.percentage * 100)

					if (value > 0 && value < 1) {
						return value.toFixed(2) + ' %'
					}

					return Math.round(value) + ' %'
				}

				return (this.number != null ? this.number : '-')
			},
			valueOnTop() {
				return (['wide-block'].indexOf(this.theme) < 0)
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-kpi {
		@apply relative bg-white text-center rounded-dashboard p-4;

		h1 {
			@apply uppercase text-sm;
		}

		h2 {
			@apply italic text-sm;
		}

		p {
			@apply text-3xl;
		}

		&.wide-block {
			@apply text-left px-8;

			h1 {
				@apply font-principal-medium uppercase text-base;
			}

			p {
				@apply text-xl mt-2;
			}
		}

		&-tooltip {
			@apply absolute top-0 right-0 p-2 cursor-help;

			p {
				@apply w-4 h-4 bg-gray rounded-full text-xs text-dashboard-gray-lighter font-principal-medium;
			}

			span {
				@apply hidden;
			}

			&:hover span {
				@apply absolute block bg-gray rounded-lg text-xs text-white font-principal-medium bottom-8 right-4 py-2 px-4 mb-1 transform translate-x-1/2;

				&:after {
					@apply border border-gray;
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					height: 0;
					width: 0;
					border-bottom-color: transparent;
					border-right-color: transparent;
					border-left-color: transparent;
					border-width: 0.5rem;
					margin-left: -0.5rem;
				}
			}
		}
	}
</style>