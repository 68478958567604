import utils from "@/utils/trackingReportTemplate"

export default {
	scenarioDoneById(user) {
		// Get scenario done by id
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'scenario_end') {
				dict[log.data_key] = true
			}

			return dict
		}, {})
	},
	progression(user, data) {
		// Get number of scenarios done and available to this user
		const progression = user.promotions.reduce((progression, promotion) => {
			// Count scenario done in this promotion
			data.promotionsById[promotion.id].scenarios.forEach((scenario) => {
				if (user.scenarioDoneById[scenario.id]) {
					progression.done += 1
				}
			})

			// Add scenario available in this promotion
			progression.available += data.promotionsById[promotion.id].scenarios.length

			return progression
		}, {
			done: 0,
			available: 0
		})

		// Return progression percentage for this user
		if (progression.available <= 0)
			return 0

		return (progression.done / progression.available)
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			lastSessionTimestamp: 0
		}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Add log creation time
			times.push((new Date(log.created_at)).getTime())

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const fiveMinutes = (5 * 60 * 1000)

		let lastLogTime = 0

		times.forEach((logTime) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if too logs are too far apart
			if (deltaTime > oneHour) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes

				// Register session week as active
				computedTimeData.weekIds[utils.weekId(new Date(logTime))] = true
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	successRatePerGame(user) {
		// Get best success rate for each game played
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'game' && log.data != null) {
				const successRate = (dict[log.data_key] || -1)

				// Only keep best success rate from all attempt
				if (log.data > successRate) {
					dict[log.data_key] = log.data
				}
			}

			return dict
		}, {})
	},
	successRatePerScenario(user, data) {
		// Get best success rate for each game played
		const scenarioDoneIds = Object.keys(user.scenarioDoneById)

		return scenarioDoneIds.reduce((dict, id) => {
			const scenario = data.scenariosById[id]

			if (!scenario) {
				return dict
			}
			
			dict[scenario.id] = utils.averageValues(scenario.nodes, (node) => {
				return user.successRatePerGame[node.node_content]
			})

			return dict
		}, {})
	},
	successRate(user) {
		if (Object.keys(user.successRatePerScenario).length <= 0)
			return -1

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerScenario, (successRate) => successRate)
	},
}