<template>
	<div class="dashboard dashboard-organization">
		<!-- Header -->
		<div class="flex items-center justify-between mb-8 w-full">
			<div>
				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span
					v-if="isSuperAdmin"
					class="ml-2 cursor-pointer"
					@click="() => includeOtherRoles = !includeOtherRoles"
				>
					Inclure les admins/responsables clients
				</span>
			</div>

			<div>
				<button type="button" class="twn-button mr-4" @click="exportPDF" :disabled="loadingPDF || isLoading">
					<span v-if="!loadingPDF">
						Export PDF
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>

				<button type="button" class="twn-button" @click="ExportReport" :disabled="loadingReport || isLoading">
					<span v-if="!loadingReport">
						Export CSV
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>
			</div>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !trackingData">
			Chargement...
		</p>
		<div v-else>
			<!-- Users, customers and groups -->
			<CategoryBlock title="Utilisateurs et promotions">
				<div class="dashboard-kpis">
					<KPIBlock
						title="Utilisateurs inscrits"
						:number="trackingData.usersData.count"
					/>
					<KPIBlock
						title="Utilisateurs en cours"
						:number="trackingData.usersData.activeCount"
						:backgroundColor="(trackingData.usersData.activeCount > 0 ? 'bg-dashboard-blue-dark' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Utilisateurs ayant terminé"
						:number="trackingData.usersData.finishedCount"
						:backgroundColor="(trackingData.usersData.finishedCount > 0 ? 'bg-dashboard-green' : 'bg-dashboard-gray-light')"
					/>
				</div>

				<div class="dashboard-kpis">
					<KPIBlock
						title="Promotions"
						:number="trackingData.groupsData.count"
					/>
					<KPIBlock
						title="Promotions en planification collective"
						:number="trackingData.groupsData.collectiveCount"
						backgroundColor="bg-dashboard-blue"
					/>
					<KPIBlock
						title="Promotions en planification individuelle"
						:number="trackingData.groupsData.individualCount"
						backgroundColor="bg-dashboard-blue"
					/>
				</div>
			</CategoryBlock>

			<!-- Courses -->
			<CategoryBlock title="Parcours">
				<div class="dashboard-kpis">
					<div class="flex-1 flex items-center">
						<p class="text-2xl text-dashboard-gray-darker">
							Parcours plusieurs niveaux
						</p>
					</div>
					<KPIBlock
						title="Promotions"
						:number="trackingData.coursesData.completCount"
					/>
					<KPIBlock
						title="Promotions en cours"
						:number="trackingData.coursesData.completActiveCount"
						:backgroundColor="(trackingData.coursesData.completActiveCount > 0 ? 'bg-dashboard-blue-dark' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Promotions terminées"
						:number="trackingData.coursesData.completFinishedCount"
						:backgroundColor="(trackingData.coursesData.completFinishedCount > 0 ? 'bg-dashboard-green' : 'bg-dashboard-gray-light')"
					/>
				</div>

				<div class="dashboard-kpis">
					<div class="flex-1 flex items-center">
						<p class="text-2xl text-dashboard-gray-darker">
							Parcours 1 niveau
						</p>
					</div>
					<KPIBlock
						title="Promotions"
						:number="trackingData.coursesData.blockCount"
					/>
					<KPIBlock
						title="Promotions en cours"
						:number="trackingData.coursesData.blockActiveCount"
						:backgroundColor="(trackingData.coursesData.blockActiveCount > 0 ? 'bg-dashboard-blue-dark' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Promotions terminées"
						:number="trackingData.coursesData.blockFinishedCount"
						:backgroundColor="(trackingData.coursesData.blockFinishedCount > 0 ? 'bg-dashboard-gray-light' : 'bg-dashboard-gray-light')"
					/>
				</div>
			</CategoryBlock>

			<!-- Courses -->
			<CategoryBlock title="Connexions et réussites">
				<div class="dashboard-kpis">
					<KPIBlock
						title="De formation délivrées"
						:time="trackingData.activityData.totalTime"
					/>
					<KPIBlock
						title="Temps passé pour compléter un scénario (Moyenne)"
						:time="trackingData.activityData.averageScenarioTime"
						help="Pour la première tentative aboutie concernant les MES et VT."
					/>
					<KPIBlock
						title="De réussite sur nos mises en situation (moyenne)"
						help="% moyen de réussite pour les utilisateurs ayant terminé leur parcours"
						:percentage="trackingData.activityData.averageSuccessRate"
					/>
				</div>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'
	import pdfTemplate from '@/utils/pdfTemplate'
	
	import trackingTemplate from "@/constants/organizationTrackingReportTemplate"
	import trackingDataTemplate from "@/constants/dashboard/organizationTrackingData"

	import Toggle from '@/components/Toggle'
	
	import CategoryBlock from "./block/Category"
	import KPIBlock from "./block/KPI"

	export default {
		name: 'OrganizationDashboard',
		props: {
			id: {
				type: String,
				required: true,
			},
			name: {
				type: String,
				required: true,
			},
		},
		components: {
			Toggle,
			CategoryBlock,
			KPIBlock,
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
				loadingReport: false,
				loadingPDF: false,
				includeOtherRoles: false,
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
		},
		watch: {
			includeOtherRoles() {
				this.loadData()
			}
		},
		mounted() {
			this.loadData()
		},
		methods: {
			async loadData(force = false) {
				if (this.isLoading) {
					return
				}

				this.isLoading = true

				// Load tracking data
				const payload = {
					organizationId: this.id,
				}

				if (this.includeOtherRoles) {
					payload.roles = ['user', 'superadmin', 'manager']
					payload.emailFilter = ''
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingDataTemplate, payload, force)

				this.isLoading = false
			},
			async ExportReport(){
				this.loadingReport = true

				try {
					const payload = {
						organizationId: this.id,
					}

					if (this.includeOtherRoles) {
						payload.roles = ['user', 'superadmin', 'manager']
						payload.emailFilter = ''
					}
					
					await trackingReport.export(`TB_${this.name}`, this.$store, trackingTemplate, payload)
				} catch (err) {
					console.error(err)
				}

				this.loadingReport = false
			},
			async exportPDF() {
				this.loadingPDF = true

				pdfTemplate.export(`TB_${this.name}`, require("../../assets/templates/organization_dashboard.svg"), {...this.trackingData, organization: this.name })

				this.loadingPDF = false
			}
		}
	}
</script>

<style lang="scss">
	
</style>