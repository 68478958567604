import extraUsersData from "./extraUsersData"
import commonSheet from "./commonSheet"
import usersSheet from "./usersSheet"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				roles: ['user'],
				emailFilter: '%(actionlogement.fr|afpols.fr|thewalkingnerds.com|grave.cool)',
				logTypes: ['login', 'logout', 'pdf', 'pdf_view', 'open_library', 'sequence_end', 'video_start', 'scenario', 'video_end', 'scenario_end', 'game'],
			},
			groupByUniq: 'id'
		},
		promotions: {
			module: 'Logs',
			action: 'GetPromotionsTrackingData',
			groupByUniq: 'id'
		},
		scenarios: {
			module: 'Scenarios',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		}
	},
	extraData: {
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		promotions: {
			scenarios(promotion) {
				// Get scenario list for this promotion course
				return promotion.course.modules.reduce((scenarios, mod) => {
					// Get scenarios in this module sequences
					mod.sequences.forEach((sequence) => {
						scenarios = scenarios.concat(sequence.scenarios)
					})

					return scenarios
				}, [])
			},
		},
		users: extraUsersData
	},
	sheets: [
		commonSheet,
		usersSheet
	]
}