import gql from "graphql-tag"

//-----------------Initialization-----------------//
const GC_INITALIZE_DASHBOARD_DATA = gql`
	query GetScenarioInfo {
		scenario {
			name
			title
			id
			nodes_aggregate {
				aggregate {
					count
				}
			}
			scenarioLocations {
				location {
					name
				}
			}
		}
		revisions:scenario(distinct_on: name
			where: { scenarioType: { slug: { _eq: "revision" } }} ) {
			name
			id
		}
		game {
			id
			title
		}
	}
`

const GC_GET_TOTAL = gql`
	query Total(
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: { _and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }] }
				experienced: { _eq: $xp }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			name
			first_name
			email
			experienced
			status
			position
			company
			seniority
			experience_course
			experience_other
			experience_seminar
			mailing
			userOrganisations {
				organisations {
					name
				}
			}
		}
		user_organisations(
			where: {
				users: {
					role: { _eq: "user" }
					status: { _eq: $status }
					seniority: { _eq: $seniority }
					experience_seminar: { _eq: $seminar }
					experience_course: { _eq: $course }
					experience_other: { _eq: $other }
					mailing: { _eq: $mailing }
					experienced: { _eq: $xp }
					company: {
						_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
						_eq: $company
					}
				}
				organisations: { _and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }] }
			}
		) {
			organisations {
				address
				address2
				city
				country
				name
				post_code
				id
				userCount:userOrganisations_aggregate {
					aggregate {
						count(columns: user)
					}
				}
			}
		}
	}
`

//-----------------Game results-----------------//
const GC_GET_GAME_RESULTS = gql`
	query GameResults(
		$from: timestamptz
		$to: timestamptz
		$id: uuid
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				data
				data_key
			}
		}
	}
`

const GC_GET_GAME_RESULTS_BY_USERID = gql`
	query GameResultByUser($id: uuid!, $from: timestamptz, $to: timestamptz) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			id
			logs(where: { logType: { slug: { _eq: "game_results" } } }) {
				data
				data_key
			}
		}
	}
`

//-----------------Scenario Complétion-----------------/
const GC_GET_SCENARIO_COMPLETION = gql`
	query ScenarioCompletion(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				data_key
				data
			}
		}
	}
`

const GC_GET_SCENARIO_COMPLETION_BY_USERID = gql`
	query ScenarioCompletionByID($id: uuid!, $from: timestamptz, $to: timestamptz) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_nodes" } } }) {
				created_at
				data
				data_key
			}
		}
	}
`

const GC_GET_SCENARIO_COMPLETED_BY_USERID = gql`
	query ScenarioCompletionByID($id: uuid!) {
		users(
			where: {
				id: { _eq: $id }
			}
		) {
			logs(
				order_by: { created_at: asc }
				where: { logType: { slug: { _eq: "scenario_end" } } }
			) {
				id
				created_at
				data
				data_key
			}
		}
	}
`

//-----------------Average Connexion time-----------------//
const GC_GET_AVERAGE_CO_TIME = gql`
	query AverageCoTime(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$status: Int
		$xp: Boolean
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			logs(
				order_by: { created_at: asc }
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------Active Users-----------------//
const GC_GET_USER_ACTIVITY = gql`
	query ActiveUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$id: uuid
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				userOrganisations: { organisation: { _eq: $id } }
				experienced: { _eq: $xp }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				created_at
			}
		}
	}
`

const GC_GET_USER_ACTIVITY_BY_ID = gql`
	query UserActivityByID($from: timestamptz, $to: timestamptz, $id: uuid!) {
		users(
			where: {
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------New Users-----------------//
const GC_GET_NEW_USERS_PER_WEEK = gql`
	query NewUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
		$id: uuid
	) {
		users(
			where: {
				_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
			order_by: { created_at: asc }
		) {
			id
			created_at
		}
	}
`

//-----------------Finished users-----------------//
const GC_GET_FINISHED_USERS = gql`
	query GetFinishedUsers(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_end" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				role: { _eq: "user" }
				experienced: { _eq: $xp }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_end" } } }) {
				data_key
			}
			id
		}
	}
`

const GC_GET_ORGA_ONE = gql`
	query GetOrga(
		$id: uuid!
		$from: timestamptz
		$to: timestamptz
		$status: Int
		$xp: Boolean
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		organisations_by_pk(id: $id) {
			name
			id
			userOrganisations(
				distinct_on: user
				where: {
					users: {
						company: {
							_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
							_eq: $company
						}
						role: { _eq: "user" }
						experience_seminar: { _eq: $seminar }
						experience_course: { _eq: $course }
						experience_other: { _eq: $other }
						mailing: { _eq: $mailing }
						status: { _eq: $status }
						seniority: { _eq: $seniority }
						experienced: { _eq: $xp }
						_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
					}
				}
			) {
				users {
					id
					name
					first_name
					company
					email
					experience_other
					experience_seminar
					experience_course
					mailing
					position
					seniority
					status
				}
			}
		}
	}
`

const GC_GET_USERS_PROMOTION = gql`
query GetPromotionUserList($ids:[uuid!]!, $from: timestamptz, $to: timestamptz){
	users( where: { id: { _in: $ids }} ){
		id
		name
		first_name
		company
		email
		connexion:logs( where: {
			_or: [
				{ logType: { slug: { _eq: "login" } } }
				{ logType: { slug: { _eq: "logout" } } }
			]
			_and: [
				{ created_at: { _gte: $from } },
				{ created_at: { _lte: $to } }
			]
		} order_by: {created_at: asc}) {
			created_at
			logType {
				slug
			}
		}
		scenario:logs(where: {
			logType: { slug: { _eq: "scenario" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			created_at
			updated_at
			data_key
			data
		}
		sc_start:logs(where: {
			 logType: { slug: { _eq: "scenario_start" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			created_at
			data_key
		}
		sc_end:logs(where: {
			 logType: { slug: { _eq: "scenario_end" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			created_at
			data_key
		}
		game:logs(where: {
			 logType: { slug: { _eq: "game" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			data_key
			data
			created_at
		}
	}
}
`

const GC_INSERT_CONTENT_LOG = gql`
mutation InsertContentLog($obj:content_log_insert_input!) {
  insert_content_log_one(object: $obj) {
    id
  }
}
`

const GC_GET_LOG_TYPES = gql`
query GetLogTypes{
	log_type {
		description
		id
		name
		slug
  }
}
`

const GC_GET_USERS_TRACKING_DATA = gql`
query GetUsersTrackingData($roles: [String!]!, $emailFilter: String!, $logTypes: [String!]!) {
  users(where: {
  	role: {_in: $roles},
  	email: {_nsimilar: $emailFilter},
  }) {
    id
    name
    first_name
    email
    status
    created_at
    registration_date
    promotions: promotion_users {
      id: promotion_id
    }
    logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
      data_key
      created_at
      updated_at
      data(path: "$.value")
      sequence_id: data(path: "sequence_id")
      promotion_id: data(path: "promotion_id")
      type: logType {
        slug
      }
    }
  }
}
`

const GC_GET_USERS_TRACKING_DATA_FOR_DATES = gql`
query GetUsersTrackingDataForDates($roles: [String!]!, $emailFilter: String!, $logTypes: [String!]!, $fromDate: date!, $toDate: date!, $fromTime: timestamptz!, $toTime: timestamptz!) {
  users(where: {
  	role: {_in: $roles},
  	email: {_nsimilar: $emailFilter},
  	_or: [
  		{_and: [
  			{_not: {promotion_users: {}}},
  			{created_at: {_gte: $fromTime, _lte: $toTime}}
  		]},
  		{promotion_users: {promotion: {start_date: {_gte: $fromDate, _lte: $toDate}}}}
  	]
  }) {
    id
    name
    first_name
    email
    status
    created_at
    registration_date
    promotions: promotion_users {
      id: promotion_id
    }
    logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
      data_key
      created_at
      updated_at
      data(path: "$.value")
      sequence_id: data(path: "sequence_id")
      promotion_id: data(path: "promotion_id")
      type: logType {
        slug
      }
    }
  }
}
`

const GC_GET_ORGANIZATION_USERS_TRACKING_DATA = gql`
query GetOrganizationUsersTrackingData(
	$roles: [String!]!,
	$emailFilter: String!,
	$logTypes: [String!]!,
	$organizationId: uuid!
) {
	users(where: {
		role: { _in: $roles },
		email: { _nsimilar: $emailFilter },
		userOrganisations: {organisation: {_eq: $organizationId}}
	}) {
    id
    name
    first_name
    email
    status
    created_at
    promotions: promotion_users {
      id: promotion_id
    }
    logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
      data_key
      created_at
      updated_at
      data(path: "$.value")
      type: logType {
        slug
      }
    }
  }
}
`

const GC_GET_PROMOTION_USERS_TRACKING_DATA = gql`
query GetPromotionUsersTrackingData(
	$id: uuid!,
	$roles: [String!]!,
	$emailFilter: String!,
	$logTypes: [String!]!
) {
	promotion(where: {id: {_eq: $id}}) {
		users(where: {user: {
			role: { _in: $roles },
			email: { _nsimilar: $emailFilter },
		}}) {
			user {
				id
				name
				first_name
				email
				status
				created_at
				logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
					data_key
					created_at
					updated_at
					data(path: "$.value")
					sequence_id: data(path: "sequence_id")
					type: logType {
						slug
					}
				}
			}
		}
	}
}

`

const GC_GET_PROMOTIONS_TRACKING_DATA = gql`
query GetPromotionsTrackingData($fromDate: date!, $toDate: date!) {
  promotion(where: {
  	start_date: {_gte: $fromDate, _lte: $toDate}
  }) {
    id
    title
    start_date
    end_date
    type {
      slug
    }
    course {
    	id
    	name
      type: courseType {
        slug
      }
      modules: levels {
        sequences {
        	id
          scenarios {
            id: scenario_id
          }
        }
      }
    }
    users {
      id: user_id
    }
  }
}
`

const GC_GET_ORGANIZATION_PROMOTIONS_TRACKING_DATA = gql`
query GetOrganizationPromotionsTrackingData($id: uuid!) {
  promotion(where: {users: {user: {userOrganisations: {organisation: {_eq: $id}}}}}) {
    id
    title
    start_date
    end_date
    type {
      slug
    }
    course {
      type: courseType {
        slug
      }
      modules: levels {
        sequences {
          scenarios {
            id: scenario_id
          }
        }
      }
    }
  }
}
`

const GC_GET_SCENARIO_LOGS_TRACKING_DATA = gql`
query GetScenarioLogsTrackingData($roles: [String!]!, $emailFilter: String!, $fromDate: timestamptz!, $toDate: timestamptz!) {
  logs(where: {logType: {slug: {_eq: "scenario"}}, users: {
		role: { _in: $roles },
		email: { _nsimilar: $emailFilter }
		created_at: {_gte: $fromDate, _lte: $toDate}
	}}, order_by: {created_at: asc}) {
    created_at
    updated_at
    data_key
    sequence_id: data(path: "sequence_id")
    promotion_id: data(path: "promotion_id")
    user_id
  }
}
`

const GC_GET_ORGANIZATION_SCENARIO_LOGS_TRACKING_DATA = gql`
query GetOrganizationScenarioLogsTrackingData($roles: [String!]!, $emailFilter: String!, $organizationId: uuid!) {
  logs(where: {logType: {slug: {_eq: "scenario"}}, users: {
		role: { _in: $roles },
		email: { _nsimilar: $emailFilter },
		userOrganisations: {organisation: {_eq: $organizationId}}
	}}, order_by: {created_at: asc}) {
    created_at
    updated_at
    data_key
    user_id
  }
}
`

const GC_GET_USER_TRACKING_DATA = gql`
query GetUserTrackingData($id: uuid!, $logTypes: [String!]! ) {
	users_by_pk(id: $id) {
		logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
			data_key
			created_at
			updated_at
			data(path: "$.value")
			promotion_id: data(path: "$.promotion_id")
			scenario_id: data(path: "$.scenario_id")
			type: logType {
				slug
			}
		}
		promotions: promotion_users {
			promotion {
				id
				title
				start_date
				end_date
				type {
					slug
				}
				sequences(where: {_or: [
					{user_id: {_is_null: true}},
					{user_id: {_eq: $id}}
				]}) {
					id: sequence_id
					user_id
					start_date
					end_date
				}
				course {
					type: courseType {
						slug
					}
					modules: levels(order_by: {order: asc}) {
						id
						order
						scenarios {
							scenario {
								id
							}
						}
						sequences {
							id
							scenarios {
								scenario {
									id
								}
							}
						}
					}
				}
			}
		}
	}
}
`

export {
	GC_GET_LOG_TYPES,
	GC_INSERT_CONTENT_LOG,
	GC_GET_GAME_RESULTS,
	GC_GET_ORGA_ONE,
	GC_GET_TOTAL,
	GC_GET_SCENARIO_COMPLETION,
	GC_GET_USER_ACTIVITY,
	GC_GET_NEW_USERS_PER_WEEK,
	GC_GET_FINISHED_USERS,
	GC_GET_USER_ACTIVITY_BY_ID,
	GC_GET_GAME_RESULTS_BY_USERID,
	GC_GET_AVERAGE_CO_TIME,
	GC_GET_SCENARIO_COMPLETION_BY_USERID,
	GC_INITALIZE_DASHBOARD_DATA,
	GC_GET_USERS_PROMOTION, 
	GC_GET_SCENARIO_COMPLETED_BY_USERID,
	GC_GET_USERS_TRACKING_DATA,
	GC_GET_USERS_TRACKING_DATA_FOR_DATES,
	GC_GET_PROMOTION_USERS_TRACKING_DATA,
	GC_GET_PROMOTIONS_TRACKING_DATA,
	GC_GET_SCENARIO_LOGS_TRACKING_DATA,
	GC_GET_ORGANIZATION_USERS_TRACKING_DATA,
	GC_GET_ORGANIZATION_PROMOTIONS_TRACKING_DATA,
	GC_GET_ORGANIZATION_SCENARIO_LOGS_TRACKING_DATA,
	GC_GET_USER_TRACKING_DATA,
}
