import gql from 'graphql-tag'

const GC_USER_INFOS = gql`
query getCurrentUser ($id:uuid!) {
  users(where: {id: {_eq: $id}}) {
    id
    email
    role
  }
}
`

const CG_GET_ORGA = gql`
query getCurrentOrga($id: uuid!) {
  organisations(where: {admin_id: {_eq: $id}}) {
    id
    name
  }
}

`

export {
    GC_USER_INFOS,
    CG_GET_ORGA
}