<template>
    <b-modal
        ref="debug-progress-modal"
        id="debug-progress-modal"
        class="bootstrap"
        size="lg"
        centered
        hide-footer
        hide-header
    >
        <div class="d-block text-center my-6 uppercase font-semibold">
            <h3>Progression de l'utilisateur {{ firstName }} {{ lastName }}</h3>
        </div>
        <div v-for="group in groups" :key="group.id">
            <p>{{ group.title }}</p>
            <div>
                <p>{{ group.course.name }}</p>
                <details
                    v-for="level in group.course.levels"
                    :key="level.id"
                >
                    <summary class="p-2">
                        Niveau {{ level.order + 1 }} - {{ level.name }} {{ level.title }}{{ level.warning ? '⚠️' : '' }}
                    </summary>

                    <div class="pl-7">
                        <details
                            v-for="sequence in level.sequences"
                            :key="sequence.id"
                        >
                            <summary :title="sequence.name + ' ' + sequence.title + ' (' + sequence.id + ')'">
                                <span>
                                    Séquence {{ sequence.order + 1 }} - 
                                </span>
                                <span v-if="sequence.level_scenario_limit > 0 || sequence.freeScenarioDone.length > 0">
                                    Libres : {{ sequence.freeScenarioDone.length }}/{{ sequence.level_scenario_limit }}
                                </span>
                                <span v-if="(sequence.level_scenario_limit > 0 || sequence.freeScenarioDone.length > 0) && sequence.scenarios.length > 0">
                                     - 
                                </span>
                                <span v-if="sequence.scenarios.length > 0">
                                    Obligatoire : {{ sequence.scenarioDone.length }}/{{ sequence.scenarios.length }}
                                </span>
                                <span v-if="sequence.replayedScenarios.length > 0">
                                     - 
                                </span>
                                <span v-if="sequence.replayedScenarios.length > 0">
                                    Rejouer : {{ sequence.replayedScenarios.length }}
                                </span>
                                <span v-if="sequence.warning">⚠️</span>
                            </summary>

                            <div class="pl-5">
                                <template v-if="sequence.warning">
                                    <p
                                        v-for="(warning, index) in sequence.warningReasons"
                                        :key="index"
                                    >
                                        ⚠️
                                        {{ warning }}
                                    </p>
                                </template>
                                <template v-if="sequence.level_scenario_limit > 0 || sequence.freeScenarioDone.length > 0">
                                    <p>Libres {{ sequence.freeScenarioDone.length }}/{{ sequence.level_scenario_limit }}</p>

                                    <div
                                        v-for="scenario in sequence.freeScenarioDone"
                                        :key="'free' + scenario.id"
                                    >
                                        <p :title="scenario.id">
                                            - {{ scenario.name }} - {{ scenario.title }}
                                            <img
                                                class="inline h-4 cursor-pointer"
                                                src="@/assets/images/EDIT.svg"
                                                @click="editingLog = scenario.log.id;selectedSequenceId = scenario.log.data.sequence_id"
                                            >
                                        </p>
                                        <div
                                            v-if="scenario.log.id == editingLog"
                                            class="p-2"
                                        >
                                            <select
                                                class="block"
                                                v-model="selectedSequenceId"
                                            >
                                                <option
                                                    v-for="sequence in level.sequences"
                                                    :key="'opt' + sequence.id"
                                                    :value="sequence.id"
                                                >
                                                    Séquence {{ sequence.order + 1 }} - {{ sequence.name }}
                                                </option>
                                                <template
                                                    v-for="level in group.course.levels"
                                                >
                                                    <option
                                                        v-for="sequence in level.sequences"
                                                        :key="'opt' + level.id + sequence.id"
                                                        :value="sequence.id"
                                                    >
                                                        Niveau {{ level.order + 1 }} - Séquence {{ sequence.order + 1 }} - {{ sequence.name }}
                                                    </option>
                                                </template>
                                            </select>
                                            <button
                                                class="twn-button p-0"
                                                @click="saveLog(scenario.log)"
                                            >
                                                Save
                                            </button>
                                            <button
                                                class="twn-button p-0"
                                                @click="editingLog = null"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </template>

                                <template v-if="sequence.scenarios.length > 0">
                                    <p>Obligatoires {{ sequence.scenarioDone.length }}/{{ sequence.scenarios.length }}</p>
                                    <p
                                        v-for="scenario in sequence.scenarioDone"
                                        :key="'obl' + scenario.id"
                                        :title="scenario.id"
                                    >
                                        - {{ scenario.name }} - {{ scenario.title }}
                                    </p>
                                </template>

                                <template v-if="sequence.replayedScenarios.length > 0">
                                    <p>Rejouer({{ sequence.replayedScenarios.length }})</p>

                                    <div
                                        v-for="(scenario, index) in sequence.replayedScenarios"
                                        :key="'replay' + index + scenario.id"
                                    >
                                        <p :title="scenario.id">
                                            - {{ scenario.name }} - {{ scenario.title }}
                                            <span v-if="scenario.warning">⚠️</span>
                                            <img
                                                class="inline h-4 cursor-pointer"
                                                src="@/assets/images/EDIT.svg"
                                                @click="editingLog = scenario.log.id;selectedSequenceId = scenario.log.data.sequence_id"
                                            >
                                        </p>
                                        <div
                                            v-if="scenario.log.id == editingLog"
                                            class="p-2"
                                        >
                                            <select
                                                class="block"
                                                v-model="selectedSequenceId"
                                            >
                                                <option
                                                    v-for="sequence in level.sequences"
                                                    :key="'opt' + sequence.id"
                                                    :value="sequence.id"
                                                >
                                                    Séquence {{ sequence.order + 1 }} - {{ sequence.name }}
                                                </option>
                                                <template
                                                    v-for="level in group.course.levels"
                                                >
                                                    <option
                                                        v-for="sequence in level.sequences"
                                                        :key="'obl' + level.id + sequence.id"
                                                        :value="sequence.id"
                                                    >
                                                        Niveau {{ level.order + 1 }} - Séquence {{ sequence.order + 1 }} - {{ sequence.name }}
                                                    </option>
                                                </template>
                                            </select>
                                            <button
                                                class="twn-button p-0"
                                                @click="saveLog(scenario.log)"
                                            >
                                                Save
                                            </button>
                                            <button
                                                class="twn-button p-0"
                                                @click="editingLog = null"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </details>
                    </div>
                </details>
            </div>
        </div>
        <div class="flex flex-row justify-evenly items-center">
            <button type="button" class="mt-4 twn-button" @click="loadData">Reload</button>
            <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('debug-progress-modal')">Fermer</button>
        </div>
    </b-modal>
</template>

<script>
import apollo from '@/apolloClient'
import constants from './gql-constants'
import checkGroupsSequenceData from './checkGroupsSequenceData'

export default {
    name: 'DebugUserProgressModal',
    props: {
        userId: {
            type: String,
            required: true,
        },
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            groups: [],
            editingLog: null,
            selectedSequenceId: null,
        }
    },
    methods: {
        show() {
            this.$refs['debug-progress-modal'].show()
            this.loadData()
        },
        async loadData() {
            // Load scenario done
            const scenarioDoneResponse = await apollo.query({
                query: constants.GC_GET_SCENARIO_COMPLETED_BY_USERID,
                variables: {
                    id: this.userId,
                },
            })

            // Load groups course data
            const groupsResponse = await apollo.query({
                query: constants.GC_GET_GROUPS_COURSE_BY_USERID,
                variables: {
                    id: this.userId,
                },
            })

            this.groups = checkGroupsSequenceData(groupsResponse, scenarioDoneResponse)
        },
        async saveLog(log) {
            await apollo.mutate({
                mutation: constants.GC_SET_LOG_DATA,
                variables: {
                    id: log.id,
                    data: {
                        ...log.data,
                        sequence_id: this.selectedSequenceId,
                    },
                }
            })

            this.editingLog = null
            this.selectedSequenceId = null

            await this.loadData()

            confirm('Done')
        },
    },
}
</script>