import { computeTrackingDataForList, sumField } from '@/utils/trackingDataFactories'

import commonTypes from '../common/commonTypes'

import extraUsersData from "./extraUsersData"
import extraCourseData from "./extraCourseData"

import promotionSheet from "./promotionSheet"
import courseSheet from "./courseSheet"
import usersSheet from "./usersSheet"

const twoAndHalfHour = (150 * 60 * 1000)

export default {
	id: 'groupTrackingReportData',
	types: commonTypes,
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetPromotionUsersTrackingData',
			payload: {
				roles: ['user'],
				emailFilter: '%(actionlogement.fr|afpols.fr|thewalkingnerds.com|grave.cool)',
				logTypes: ['login', 'logout', 'pdf', 'pdf_view', 'open_library', 'sequence_end', 'video_start', 'scenario', 'video_end', 'scenario_end', 'game']
			},
			groupByUniq: 'id'
		}
	},
	extraData: {
		course: extraCourseData,
		promotion: {
			sequencesById(promotion) {
				return promotion.sequences.reduce((dict, promotionSequence) => {
					if (promotionSequence.userID === null) {
						// Fix start/end date GMT delta if needed
						if (promotionSequence.startDate.getHours() !== 0) {
							promotionSequence.startDate.setHours(0, 0, 0, 0) // Start of day
						}
						if (promotionSequence.endDate.getHours() !== 0) {
							promotionSequence.endDate.setHours(24, 0, 0, 0) // End of day
						}

						// Add to the dictionarie
						dict[promotionSequence.id] = promotionSequence
					}

					return dict
				}, {})
			}
		},
		courseScenarios(data) {
			const levelIds = Object.keys(data.course.scenariosPerLevel)
			const scenarios = levelIds.reduce((scenarios, id) => {
				scenarios = scenarios.concat(data.course.scenariosPerLevel[id])

				return scenarios
			}, [])

			return scenarios.sort((sc1, sc2) => {
				if (sc1.name < sc2.name) { return -1 }
				if (sc1.name > sc2.name) { return 1 }
				return 0
			})
		},
		users: extraUsersData,
		activityData(data) {
			// Compute overall active time sum
			const { totalTime } = computeTrackingDataForList(data.users || [], {
				totalTime: sumField('activeTimeData', 'sum'),
			})

			// Compute number of sequence who should be done as of today
			let sequencesDone = 0

			const now = Date.now()
			data.promotion.sequences.forEach((promotionSequence) => {
				if (promotionSequence.userID === null && now > promotionSequence.startDate.getTime()) {
					sequencesDone += 1
				}
			})

			return {
				totalTime,
				averageTime: (totalTime / data.users.length || 1),
				remainingTime: (twoAndHalfHour * sequencesDone),
				perUser: data.users.reduce((dict, user) => {
					dict[user.id] = user.activeTimeData.sum
					return dict
				}, {}),
			}
		}
	},
	sheets: [
		promotionSheet,
		courseSheet,
		usersSheet,
	]
}