<template>
	<div class="course-detail">
		<div class="buttons flex flex-row justify-around md:justify-between">
			<button type="button" class="twn-button text-xs" @click="goBack">
				Retour
			</button>
			<div>
				<button
					type="button"
					class="twn-button text-xs danger mr-4"
					@click="showDeleteModal"
					v-if="!creating"
				>
					Supprimer le parcours
				</button>
				<button
					class="twn-button text-xs"
					@click="save"
				>
					Enregistrer
				</button>
			</div>
		</div>

		<div class="my-10">
			<div class="flex">
				<div v-if="creating" class="uppercase font-principal-bold text-sm">
					Création d'un nouveau parcours
				</div>
				<div v-else class="uppercase font-principal-bold text-sm">
					Éditer le parcours
				</div>
			</div>

			<div class="mt-8">
				<div class="flex items-center">
					<div class="flex-1 mr-8">
						<label>Nom du parcours</label>
						<b-form-input v-model="fields.name" type="text" />
					</div>

					<div class="flex-1 mr-8">
						<label class="mr-8">Type de parcours</label>
						<v-select
							class="flex-1 mr-auto"
							label="name"
							:options="types"
							placeholder="Type du parcours"
							v-model="fields.type"
							@input="changeType"
							:reduce="type => type.id"
						/>
					</div>
				</div>

				<div class="mt-8 level-container" v-if="fields.type">
					<!-- level choice -->
					<div v-if="hasLevels" class="level-selector justify-around rounded border border-gray-light">
						<div
							v-for="(level, i) in fields.levels"
							:key="i"
							class="level-select-container cursor-pointer"
							:class="{'active': activeLevel == i}"
							@click="activeLevel = i"
						>
							<div>
								Niveau {{ level.order + 1 }}
							</div>
						</div>
						<button class="py-2 px-6 font-principal-bold text-xl border-l border-l-gray-light" @click="addLevel">+</button>
					</div>

					<div v-if="hasFreeScenarios && fields.levels[activeLevel]">
						<div class="flex flex-row w-full my-4">
							<div class="w-1/4 pr-8">
								<label for="level-name">Identifiant</label>
								<input type="text" id="level-name" class="d-block twn-input w-full" v-model="fields.levels[activeLevel].name">
							</div>
							<div class="w-3/4">
								<label for="level-title">Titre</label>
								<input type="text" id="level-title" class="d-block twn-input w-full" v-model="fields.levels[activeLevel].title">
							</div>
						</div>

						<div class="w-full mb-4">
							<label for="level-scenario" class="">Scénarios disponible</label>
							<v-select
								id="level-scenario"
								:options="scenarioOptions"
								@search="fetchScenariosByName"
								multiple
								label="name"
								class=""
								v-model="fields.levels[activeLevel].scenarios"
							>
							</v-select>
						</div>


						<button v-if="hasLevels" class="twn-button danger py-2 mb-4 ml-auto block" @click="showDeleteLevelModal">Supprimer le niveau</button>
					</div>

					<span class="font-principal-medium">
						Séquences associées
					</span>
					<div
						v-if="
							fields.levels[activeLevel].sequences && fields.levels[activeLevel].sequences.length > 0
						"
					>
						<draggable v-model="fields.levels[activeLevel].sequences" @input="changeSequenceOrder">
							<div
								v-for="(sequence, j) in fields.levels[activeLevel].sequences"
								:key="j"
								class="flex flex-row my-4 shadowed"
							>
								<div
									class="index w-10 flex-center bg-principal-selected rounded-tl-md rounded-bl-md text-white"
								>
									{{ sequence.order + 1 }}
								</div>
								<div class="content flex flex-wrap p-4 w-full">
									<div class="top flex w-full mr-4 mb-4">
										<div class="flex items-center w-1/4 mr-8">
											<label class="mr-4 mb-0" for="twn-id">Identifiant</label>
											<input type="text" class="twn-input flex-grow" v-model="sequence.name" />
										</div>
										<div class="flex items-center w-3/4">
											<label class="mr-4 mb-0" for="twn-id">Titre</label>
											<input type="text" class="twn-input flex-grow" v-model="sequence.title" />
										</div>
									</div>
									<!-- if course par bloc -->
									<div
										class="bloc-content w-full"
										v-if="fields.type && typeListFormat[fields.type].slug == 'bloc_course'"
									>
										<div class="w-full flex justify-between mb-4">
											<label for="twn-id" class="w-1/6">Scénarios</label>
											<v-select
												:options="scenarioOptions"
												@search="fetchScenariosByName"
												multiple
												label="name"
												class="w-5/6 mr-4"
												v-model="sequence.scenarios"
											>
											</v-select>
										</div>
										<div class="w-full flex">
											<label for="twn-id" class="w-1/6">Révision</label>
											<v-select
												:options="revisionOptions"
												@search="fetchRevisionByName"
												multiple
												label="name"
												class="w-5/6 mr-4"
												v-model="sequence.revisions"
											>
											</v-select>
										</div>
									</div>
									<!-- if parcours complet -->
									<div class="w-full" v-else>
										<div class="w-full mb-4 justify-start flex">
											<label for="nb-scenario"
												>Nombre de scénario à faire lors de cette séquence</label
											>
											<input
												type="number"
												id="nb-scenario"
												class="ml-4 twn-input pr-1"
												v-model="sequence.level_scenario_limit"
												min="0"
											/>
										</div>
										<div class="w-full flex items-center">
											<label for="twn-id" class="mr-4 mb-0">Scénarios additionnels</label>
											<v-select
												:options="scenarioOptions"
												@search="fetchScenariosByName"
												multiple
												label="name"
												class="w-5/6 mr-4"
												v-model="sequence.scenarios"
												@input="setScenarioOrder($event, j)"
											>
											
											<!-- <template #selected-option="data">
												{{data.order}}
											</template> -->
											</v-select>
										</div>
									</div>
								</div>
								<div
									class="buttons w-1/12 bg-principal-selected flex flex-col justify-around items-center rounded-tr-md rounded-br-md text-white"
								>
									<div
										@click="removeSequence(j)"
										class="cursor-pointer text-xl font-principal-bold border-2 w-10 h-10 rounded-md flex-center"
									>
										-
									</div>
									<div
										@click="addSequence(j)"
										class="cursor-pointer text-xl font-principal-bold border-2 w-10 h-10 rounded-md flex-center"
									>
										+
									</div>
								</div>
							</div>
						</draggable>
					</div>
					<div v-else></div>
				</div>
			</div>
		</div>

		<!-- Modals -->
		<b-modal
			ref="delete-modal-course"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-course"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Confirmer la suppression {{isDeleteLevel ? "du niveau" : ""}}</h3>
			</div>
			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="hideModal" >
					Retour
				</button>
				<button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import { debounce } from "@/utils/utils"
import Draggable from "vuedraggable"
import {SEARCH_SCENARIO} from '@/graphql/course'

export default {
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
	components: {
		Draggable
	},
	data(){
		return{
			creating: false,
			isDeleteLevel: false,
			nameTaken: false,
			fields: {
				id: null,
				name: null,
				type: null,
				levels: [
					{
						id: null,
						name: "",
						title: "",
						order: 0,
						scenarios: [],
						sequences: [
							{
								name: "",
								title: "",
								level_scenario_limit: 3,
								order: 0,
								scenarios: [],
								revisions: []
							}
						]
					}
				]
			},
			hasLevels: true,
			hasFreeScenarios: true,
			activeLevel: 0,
			scenarioOptions: [],
			revisionOptions: [],
		}
	},

	computed: {
		...mapGetters("EditItem", ["getCurrentID"]),
		...mapState("Course", ["types"]),
		...mapGetters("Course", ["typeListFormat"])
	},

	created() {
		if (this.getCurrentID === -1 && !this.itemID) {
			this.creating = true
		}
		this.$store.dispatch("Course/GetCourseTypes")
		this.getScenario("", false)
		this.getScenario("", true)

		if (this.itemID){
			this.mapFields()
		}
	},

	methods: {
		async confirmDelete(){
			if (this.isDeleteLevel){
				this.deleteLevel()
			} else {
				try {
					await this.$store.dispatch("Course/Delete", this.itemID)
					this.goBack()
				} catch (err) {
					alert('Une erreur est survenue, le parcour est peut être utilisé dans une promotion.')
				}
			}

			this.hideModal()
		},
		fetchScenariosByName(name, loading) {
			loading(true)
			this.searchScenario(loading, name, false, this)
		},

		fetchRevisionByName(name, loading) {
			loading(true)
			this.searchScenario(loading, name, true, this)
		},

		searchScenario: debounce((loading, name, isRevision, vm) => {
			vm.getScenario(name, isRevision).then(() => loading(false));
		}, 350),

		getScenario(name, isRevision){
			return new Promise((resolve) => {
				this.$apollo.query({
					query: SEARCH_SCENARIO,
					variables: {
						name: `%${name}%`,
						slugs: isRevision ? ['revision'] : ['scenario', 'edl'] //todo: not revision instead of only scenario
					}
				}).then((res) => {
					if (res.data){
						if (isRevision){
							this.revisionOptions = res.data.scenario
							resolve()
						}else{
							this.scenarioOptions = res.data.scenario
							resolve()
						}
					}
				})
			})
		},

		goBack() {
			this.$router.push({name:'course-list', params: {
				previousPage: this.previousPage
			}})
		},

		async mapFields(){
			const fields = await this.$store.dispatch("Course/GetCourse", this.itemID)
			fields.levels = fields.levels.map(level => {
				let levelret = level

				levelret.scenarios = level.scenarios.map(sc => {
					return {
						id: sc.scenario.id,
						name: sc.scenario.name
					}
				})
				levelret.sequences = level.sequences.map(sequence => {
					let sequenceret = sequence;

					const scenarios = sequence.scenarios.map(sc => {
						return {
							id:sc.scenario.id,
							name:sc.scenario.name,
							order: sc.order,
							type: sc.scenario.type,
						}
					}).sort((a, b) => {
						return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
					})

					sequenceret.scenarios = scenarios.filter((sc) => {
						return (sc.type.slug != 'revision')
					})

					sequenceret.revisions = scenarios.filter((sc) => {
						return (sc.type.slug == 'revision')
					})

					return sequenceret
				})
				return levelret;
			})

			this.fields = fields

			this.changeType(this.fields.type);
		},

		showDeleteModal() {
			this.$refs["delete-modal-course"].show()
		},
		showDeleteLevelModal(){
			this.isDeleteLevel = true
			this.$refs["delete-modal-course"].show()
		},
		removeSequence(index) {
			//remove
			if (this.fields.levels[this.activeLevel].sequences.length > 1){
				this.fields.levels[this.activeLevel].sequences.splice(index, 1)
			}

			this.fields.levels[this.activeLevel].sequences.forEach((sequence, i) => {
				if (i >= index){
					//
					sequence.order--
				}
			})
		},
		addSequence(index) {
			//Add
			this.fields.levels[this.activeLevel].sequences.splice(index + 1, 0, {
				id: null,
				name: "",
				level_scenario_limit: 2,
				order: index + 1,
				scenarios: [],
				revisions: []
			})
		},
		changeType(typeID) {
			if (this.typeListFormat[typeID] && (this.typeListFormat[typeID].slug == "bloc_course" || this.typeListFormat[typeID].slug == "free_course")) {
				this.activeLevel = 0
				this.hasLevels = false
				this.hasFreeScenarios = (this.typeListFormat[typeID].slug == "free_course")
			} else {
				this.hasLevels = true
				this.hasFreeScenarios = true
			}
		},
		addLevel(){
			this.fields.levels.push(this.defaultLevel(this.fields.levels.length))
			this.activeLevel = this.fields.levels.length - 1
		},
		defaultLevel(order = 0){
			return {
				id: null,
				name: "",
				title: "",
				scenarios: [],
				order,
				sequences: [
					{
						name: "",
						title: "",
						order: 0,
						level_scenario_limit: 2,
						scenarios: [],
						revisions: []
					}
				]
			}
		},
		deleteLevel(){
			if (this.fields.levels.length > 1){
				this.fields.levels.splice(this.activeLevel, 1)
				this.fields.levels.forEach((level, i) => {
					if (i >= this.activeLevel){
						level.order--
					}
				})
				if (this.activeLevel - 1 >= 0){
					this.activeLevel -= 1
				}

				this.$forceUpdate();
			}
		},
		async save(){
			if (this.fields.name && this.fields.name !== "") {
				const response = await this.$store.dispatch('Course/saveCourse', this.fields)
				if (response && response.error){
					this.$bvToast.toast('Une erreur est survenue, l\'un des champs requis doit être manquant', { title: `Erreur` })
					return;
				}

				if (response == false){
					this.nameTaken = true
					this.$bvToast.toast('Le nom est déjà pris !', { title: `Erreur` })
					return;
				}

				if (this.creating && response.id){
					this.fields.id = response.id
					this.creating = false
					this.$router.push({
						name: 'course-edit',
						params: {
							itemID: response.id,
							previousPage: this.previousPage
						}
					})
				}

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
			}
		},
		setScenarioOrder(scenarios, sequenceIndex){
			if (scenarios){
				this.fields.levels[this.activeLevel].sequences[sequenceIndex].scenarios = scenarios.map((sc, i) => {
					let scenario = sc
					scenario.order = i
					return scenario
				})
			}
		},
		changeSequenceOrder(sequences){
			this.fields.levels[this.activeLevel].sequences = sequences.map((sq, i) => {
				let sequence = sq
				sequence.order = i
				return sequence
			})
		},
		hideModal(){
			this.$bvModal.hide('delete-modal-course')
			this.isDeleteLevel = false
		}
	}
}
</script>
<style lang="scss" scoped>
.course-detail {
	.shadowed {
		box-shadow: $cardShadow;
	}
	.level-container{
		// border: 1px solid #DC9799;
		box-shadow: $cardShadow;
		padding: 1rem;
	}
	.level-selector{
		@apply flex flex-no-wrap flex-row mb-8;
	}
	.level-select-container{
		@apply w-full font-principal-medium flex justify-center items-center;

		&.active{
			@apply bg-principal-selected text-white;
		}
	}
	// .content{
	//     border-top: 1px solid $textMainColor;
	//     border-bottom: 1px solid $textMainColor;
	// }
}
</style>
