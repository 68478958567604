<template>
  <div>
      <List
        listing="course"
        orderBy="name: asc"
        :previousPage="previousPage"
        :fields="fields"
        :filters="filters"
        modify-button-text="Éditer le parcours"
        :with-delete-button="false"
        searchText="Rechercher un parcours"
      >
        <template v-slot:buttons>
            <div class="md:flex flex-row justify-around hidden md:w-1/3">
                <button type="button" class="twn-button danger whitespace-no-wrap overflow-hidden text-xs mr-2" @click="addCourse">Ajouter un parcours</button>
            </div>
        </template>
      </List>
  </div>
</template>

<script>
import List from '@/components/List'

export default {
    components: {
        List,
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    data(){
        return{
            fields: [
                {
                    key:'name',
                    label: 'Nom',
                    sortable: true,
                },
                {
                    key:'courseType',
                    label: 'Type de parcours',
                    sortable: true,
                    db: `courseType{
                            name
                        }`,
                    nested: 'name'
                },
                {
                    key:'levels_aggregate',
                    label:'Nombre de niveaux',
                    sortable: true,
                    db: `
                        levels_aggregate {
                            aggregate {
                                count
                            }
                        }
                    `,
                    nested: 'count'
                }
            ],
            filters: [
                {
                    key:'type',
                    label: 'Parcours'
                },
            ]
        }
    },
    methods:{
        addCourse(){
            this.$router.push({name: 'course-create'})
        }
    }
}
</script>

<style>

</style>