import gql from "graphql-tag"

const GC_GET_ORGANIZATION_LIST = gql`
query GetOrganizationList {
	organisations(order_by: {name: asc}) {
		id
		name
		admin{
			id
			name
		}
	}
}
`

const GC_GET_ORGANIZATION_BY_ID = gql`
query GetOrganization($id: uuid!) {
	organisations_by_pk(id: $id) {
		id
		name
		city
		address2
		address
		post_code
		country
		admin_id
		federation_id
		housing
		hlm
		admin{
			id
			name
		}
		userOrganisations(distinct_on: user) {
			user
		}
	}
}
`

const GC_ADD_ORGANIZATION_ONE = gql`
mutation AddOrganization($organization: organisations_insert_input!) {
	insert_organisations_one(object: $organization) {
		id
	}
}`

const GC_UPDATE_ORGANIZATION_BY_ID = gql`
mutation UpdateOrganization($id: uuid!, $organization: organisations_set_input!) {
	update_organisations_by_pk(pk_columns: {id: $id}, _set: $organization) {
		id
	}
}
`

const GC_DELETE_ORGANIZATION_BY_ID = gql`
mutation DeleteOrganization($id: uuid!) {
	delete_organisations_by_pk(id: $id) {
		id
	}
}
`

export {
	GC_GET_ORGANIZATION_LIST,
	GC_GET_ORGANIZATION_BY_ID,
	GC_ADD_ORGANIZATION_ONE,
	GC_UPDATE_ORGANIZATION_BY_ID, 
	GC_DELETE_ORGANIZATION_BY_ID,
}