import utils from "@/utils/trackingReportTemplate"

const oneHour = (1 * 60 * 60 * 1000)

export default {
	scenariosAttempts(user) {
		// Get every time a scenario was tried and how much time the user spent on it
		let lastAttempt = {}

		return user.logs.reduce((list, log) => {
			if (log.type.slug === 'scenario') {
				// Logs are sorted by date so we can consider this to always be a new attempt
				lastAttempt = {
					scenarioId: log.data_key,
					start: (new Date(log.created_at)).getTime(),
					update: (new Date(log.updated_at)).getTime(),
				}

				// Add to attempt list
				list.push(lastAttempt)
			} else if (log.type.slug == 'scenario_end') {
				// Set end time
				const endTime = (new Date(log.created_at)).getTime()
				const lastUpdateDelta = (endTime - lastAttempt.update)

				// Avoid bug and delta due to inactivity who will cause a bigger/smaller time to be registered
				if (lastUpdateDelta < oneHour) {
					lastAttempt.end = endTime
				}
			}

			return list
		}, [])
	},
	scenarioDoneById(user) {
		// Get scenario done by id
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'scenario_end') {
				dict[log.data_key] = true
			}

			return dict
		}, {})
	},
	progression(user, data) {
		// Get number of scenarios done and available to this user
		const progression = user.promotions.reduce((progression, promotion) => {
			if (!data.promotionsById[promotion.id] || !data.promotionsById[promotion.id].scenarios) {
				return progression
			}

			// Count scenario done in this promotion
			data.promotionsById[promotion.id].scenarios.forEach((scenario) => {
				if (user.scenarioDoneById[scenario.id]) {
					progression.done += 1
				}
			})

			// Add scenario available in this promotion
			progression.available += data.promotionsById[promotion.id].scenarios.length

			return progression
		}, {
			done: 0,
			available: 0
		})

		// Return progression percentage for this user
		if (progression.available <= 0)
			return 0

		return (progression.done / progression.available)
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			lastSessionTimestamp: 0,
			timeBySequence: {},
			timeByGroup: {},
			sequencesDone: {},
		}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Add log creation time
			times.push({
				logTime: (new Date(log.created_at)).getTime(),
				sequenceId: log.sequence_id,
				groupId: log.promotion_id,
			})

			if (log.type.slug === 'sequence_end') {
				if (!computedTimeData.sequencesDone) {
					computedTimeData.sequencesDone = {}
				}

				computedTimeData.sequencesDone[log.data_key] = new Date(log.created_at)
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const fiveMinutes = (5 * 60 * 1000)

		let lastLogTime = 0

		times.forEach(({ logTime, sequenceId, groupId }) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if too logs are too far apart
			if (deltaTime > oneHour) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes

				// Register session week as active
				computedTimeData.weekIds[utils.weekId(new Date(logTime))] = true
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime

				// Update sequence and group times
				if (sequenceId && computedTimeData.sequencesDone[sequenceId]) {
					if (!computedTimeData.timeBySequence[sequenceId]) {
						computedTimeData.timeBySequence[sequenceId] = 0
					}

					computedTimeData.timeBySequence[sequenceId] += deltaTime
				}

				if (groupId) {
					if (!computedTimeData.timeByGroup[groupId]) {
						computedTimeData.timeByGroup[groupId] = 0
					}
					
					computedTimeData.timeByGroup[groupId] += deltaTime
				}
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	successRatePerGame(user) {
		// Get best success rate for each game played
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'game' && log.data != null) {
				const successRate = (dict[log.data_key] || -1)

				// Only keep best success rate from all attempt
				if (log.data > successRate) {
					dict[log.data_key] = log.data
				}
			}

			return dict
		}, {})
	},
	successRatePerScenario(user, data) {
		// Get best success rate for each game played
		const scenarioDoneIds = Object.keys(user.scenarioDoneById)

		return scenarioDoneIds.reduce((dict, id) => {
			const scenario = data.scenariosById[id]

			if (!scenario) {
				return dict
			}
			
			dict[scenario.id] = utils.averageValues(scenario.nodes, (node) => {
				return user.successRatePerGame[node.node_content]
			})

			return dict
		}, {})
	},
	successRate(user) {
		if (Object.keys(user.successRatePerScenario).length <= 0)
			return -1

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerScenario, (successRate) => successRate)
	},
}