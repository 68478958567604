import utils from "@/utils/trackingReportTemplate"

import statusLabels from "../userStatus"

export default {
	name: 'Global',
	content: [
		{
			text: 'nb d\'utilisateur inscrit',
			value: (data) => {
				return data.usersData.count
			},
		},
		{
			text: 'nb d\'utilisateur en attente ',
			value: (data) => {
				return data.usersData.waitingCount
			},
		},
		{
			text: 'nb d\'utilisateur en cours',
			value: (data) => {
				return data.usersData.activeCount
			},
		},
		{
			text: 'nb d\'utilisateur ayant terminé',
			value: (data) => {
				return data.usersData.finishedCount
			},
		},
		{
			text: 'nb d\'organisation',
			value: (data) => {
				return data.organizationsData.count
			},
		},
		{
			text: 'nb de promotions',
			value: (data) => {
				return data.groupsData.count
			},
		},
		{
			text: 'nb moyen d\'utilisateur par promo',
			value: (data) => {
				return data.groupsData.averageUserCount
			},
			type: 'float',
		},
		{
			text: 'nb de promotions en planification collective',
			value: (data) => {
				return data.groupsData.collectiveCount
			},
		},
		{
			text: 'nb de promotions en planification individuelle',
			value: (data) => {
				return data.groupsData.individualCount
			},
		},
		{
			text: 'nb d\'heures de formation délivrées',
			value: (data) => {
				return data.activityData.totalTime
			},
			type: 'duration',
		},
		{
			text: 'temps passé pour compléter un scénario (moyenne)',
			value: (data) => {
				return data.activityData.averageScenarioTime
			},
			type: 'duration',
		},
		{
			text: '% de réussite moyen',
			value: (data) => {
				return data.activityData.averageSuccessRate
			},
			type: 'percentage',
		},
		{
			text: 'taux moyen de complétion sur les promos terminés',
			value: (data) => {
				return utils.averageField(data.doneGroups, 'averageUserProgression')
			},
			type: 'percentage',
		},
		{
			text: 'temps total passé sur un scénario (moyenne)',
			value: (data) => {
				return data.activityData.averageTimePerUserAndScenario
			},
			type: 'duration',
		},
		{},
		{},
		{
			table: 'doneCourses',
			headers: [
				'Nom du parcours',
				'Durée moyenne de connexion (Durée totale de connexion de l\'ensemble des utilisateurs du parcours / le nombre d\'utilisateurs)',
				'Durée médiane de connexion',
				'Moyenne du temps totale passé dans les séquences (première passation)',
				'Médiane du temps totale passé dans les séquences (première passation)',
				'Temps moyen passé dans une séquence (première passation)',
			],
			row: (course) => {
				return [
					'name',
					{ value: course.averageLoginTime, type: 'duration' },
					{ value: course.medianLoginTime, type: 'duration' },
					{ value: course.averageInSequenceTime, type: 'duration' },
					{ value: course.medianInSequenceTime, type: 'duration' },
					{ value: course.averageSequenceTime, type: 'duration' },
				]
			},
		},
		{
			cells: [
				{ text: 'TOUS' },
				{
					value: (data) => utils.averageField(data.doneCourses, 'averageLoginTime'),
					type: 'duration',
				},
				{
					value: (data) => utils.averageField(data.doneCourses, 'medianLoginTime'),
					type: 'duration',
				},
				{
					value: (data) => utils.averageField(data.doneCourses, 'averageSequenceTime'),
					type: 'duration',
				},
				{
					value: (data) => utils.averageField(data.doneCourses, 'averageInSequenceTime'),
					type: 'duration',
				},
				{
					value: (data) => utils.medianField(data.doneCourses, 'medianInSequenceTime'),
					type: 'duration',
				},
			],
		},
		{},
		{},
		{},
		{
			table: 'scenarios',
			headers: ['Nom du scénario', 'Durée moyenne', '% de réussite moyen'],
			row: (scenario) => {
				return [
					{ value: [scenario.name, scenario.title].join(' - ') },
					{ value: scenario.averageTime, type: 'duration' },
					{ value: scenario.averageSuccessRate, type: 'percentage' },
				]
			},
		},
	]
}