<template>
    <div class="header px-10 text-xl font-principal text-principal-gray bg-white h-16 font-medium flex-initial">
        <div class="mobile-header">
            <b-icon-list @click="toggleNav($event)" class="h1 mb-0"></b-icon-list>
            <div>Tableau de bord</div>
        </div>
        <div class="desktop-header">
            <router-link to="/">
                <div class="hover:text-gray-header-hover">{{title}}</div>
            </router-link>
            <div @click="logout" class="cursor-pointer">Déconnexion</div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'Header',
    computed:{
        title(){
            if (this.$route.meta.titleFn) {
                return this.$route.meta.titleFn(this.$route, this.$store)
            }
            return this.$route.meta.title
        }
    },
    methods: {
        ...mapActions('Auth', ['logout']),
        ...mapActions('Header', ['toggleNav'])
    },
}
</script>

<style lang="scss">

.header{
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.header > div {
    @apply flex-row justify-between items-center h-full;
    &.desktop-header{
        @apply hidden;
        @screen md {
            @apply flex;
        }
    }
    &.mobile-header{
        @apply flex;
        @screen md {
            @apply hidden;
        }
    }
}
</style>