import Vue from 'vue'

import { jsPDF } from 'jspdf'
import 'svg2pdf.js'

export default {
	leadingZero(number) {
		return (number < 10 && number > 0 ? '0' + number : number)
	},
	formatValue(value, format) {
		switch (format) {
			case 'time': {
				const oneHour = (1 * 60 * 60 * 1000)
			
				let remainingTime = (value / oneHour)
				const hours = Math.floor(remainingTime)

				remainingTime = (remainingTime - hours) * 60
				const minutes = Math.floor(remainingTime)

				remainingTime = (remainingTime - minutes) * 60
				const seconds = Math.round(remainingTime)

				return (hours > 0 ? hours + 'h ' : '') + (minutes > 0 || seconds <= 0 ? this.leadingZero(minutes) + 'm ' : '') + (hours <= 0 && seconds > 0 ? this.leadingZero(seconds) + 's' :  '')
			}

			case 'date': {
				const date = new Date(value)

				return [
					this.leadingZero(date.getDate()),
					this.leadingZero(date.getMonth() + 1),
					date.getFullYear()
				].join('/')
			}
			
			case 'percentage': {
				value = (value * 100)

				if (value > 0 && value < 1) {
					return value.toFixed(2) + ' %'
				}

				return Math.round(value) + ' %'
			}
		}

		return (value !== null ? value : '-')
	},
	async exportFromSvg(fileNamePrefix, svgElement) {
		// Create PDF document with SVG content
		const svgWidth = svgElement.width.baseVal.value
		const svgHeight = svgElement.height.baseVal.value

		const doc = new jsPDF({
			orientation: (svgWidth > svgHeight ? 'l' : 'p'),
			format: [svgWidth, svgHeight],
		})

		await doc.svg(svgElement, {
			width: svgWidth,
			height: svgHeight,
		})

		// Save the created pdf
		const today = new Date()
		doc.save(`${fileNamePrefix.replaceAll(' ', '_').substring(0, 60)}_${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}.pdf`)
	},
	async exportFromComponent(fileNamePrefix, component, propsData) {
		const ComponentClass = Vue.extend(component)
		const instance = new ComponentClass({
			propsData,
		})
		instance.$mount()
		
		await this.exportFromSvg(fileNamePrefix, instance.$el)
	},
	async export(fileNamePrefix, svgTemplateURL, trackingData) {
		// Load svg template file content
		const response = await fetch(svgTemplateURL)
		const svgString = await response.text()

		if (!svgString) {
			return
		}

		// Parse svg template to a DOM object
		var parser = new DOMParser();
		const svgDOM = parser.parseFromString(svgString, "image/svg+xml")
		const svgElement = svgDOM.firstChild

		if (!svgElement) {
			return
		}

		// Fill dynamic text with tracking data values
		const tspans = svgDOM.getElementsByTagName('tspan')

		for (var i = 0; i < tspans.length; i++) {
			const tspan = tspans[i]
			const matches = tspan.textContent.match(/%(.*)%/)

			if (matches && matches.length > 1) {
				const [identifier, format] = matches[1].split('$')

				let value = trackingData

				identifier.split('.').forEach((key) => {
					value = value[key]
				})

				tspan.textContent = this.formatValue(value, format)
				tspan.setAttribute('text-anchor', 'middle')
			}
		}

		await this.exportFromSvg(fileNamePrefix, svgElement)
	},
}