<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1163"
		:height="contentBlockHeight + 112"
		:viewBox="`0 0 1163 ${contentBlockHeight + 112}`"
	>
		<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="22">
			<tspan x="581.5" y="31" text-anchor="middle">{{ group.title }}</tspan>
		</text>
		<g fill="none" fill-rule="evenodd" transform="translate(56 56)">
			<rect width="1051" :height="contentBlockHeight" fill="#FBFBFB" fill-rule="nonzero" rx="16"/>
			<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="22">
				<tspan x="30" y="47.632">Connexions</tspan>
			</text>
			<!-- KPIs -->
			<g fill-rule="nonzero" transform="translate(30 94)">
				<rect width="317" height="91" fill="#FFF" rx="16"/>
				<text fill="#555" font-family="Helvetica" font-size="30">
					<tspan x="158.5" y="43.18" text-anchor="middle">{{ pdfTemplate.formatValue(trackingData.activityData.totalTime, 'time') }}</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="46.756" y="67.084">DURÉE TOTALE DE CONNEXION</tspan>
				</text>
			</g>
			<g fill-rule="nonzero" transform="translate(367 94)">
				<rect width="317" height="91" fill="#FFF" rx="16"/>
				<g transform="translate(293 10)">
					<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
					<text fill="#FFF" font-family="Helvetica" font-size="9">
						<tspan x="4.75" y="10.804">?</tspan>
					</text>
				</g>
				<text fill="#555" font-family="Helvetica" font-size="30">
					<tspan x="158.5" y="43.18" text-anchor="middle">{{ pdfTemplate.formatValue(trackingData.activityData.averageTime, 'time') }}</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="36.52" y="67.084">DURÉE MOYENNE DE CONNEXION</tspan>
				</text>
			</g>
			<g transform="translate(573 21)">
				<polygon fill="#404040" points="94 71 103 80 85 80" transform="matrix(1 0 0 -1 0 151)"/>
				<rect width="187" height="72" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
					<tspan x="23.451" y="19">Durée totale de connexion </tspan>
					<tspan x="45.324" y="33">de l&apos;ensemble des </tspan>
					<tspan x="18.462" y="47">utilisateurs de la promotion / </tspan>
					<tspan x="31.991" y="61">le nombre d&apos;utilisateurs</tspan>
				</text>
			</g>
			<g fill-rule="nonzero" transform="translate(704 94)">
				<rect width="317" height="91" fill="#FFF" rx="16"/>
				<text fill="#555" font-family="Helvetica" font-size="30">
					<tspan x="158.5" y="43.18" text-anchor="middle">{{ pdfTemplate.formatValue(trackingData.activityData.remainingTime, 'time') }}</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="102.878" y="67.084">DURÉE ESTIMÉE</tspan>
				</text>

				<g transform="translate(293 10)">
					<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
					<text fill="#FFF" font-family="Helvetica" font-size="9">
						<tspan x="4.75" y="10.804">?</tspan>
					</text>
				</g>
			</g>

			<!-- User times -->
			<g fill-rule="nonzero" transform="translate(30 224)">
				<rect width="991" height="457" fill="#FFF" rx="16"/>
				<text fill="#555" font-family="Helvetica" font-size="22">
					<tspan x="30" y="47.632">Durée totale de connexion par apprenant</tspan>
				</text>

				<!-- Average -->
				<path fill="#B7B7B7" :d="getUserAverageTimeBarPath(trackingData.activityData.averageTime)" opacity=".3"/>
				<text fill="#555" font-family="Helvetica" font-size="12">
					<tspan x="30" y="73.072">Moyenne de la promotion :</tspan>
				</text>
				<text fill="#555" font-family="Helvetica" font-size="12">
					<tspan x="200" y="73.072" text-anchor="middle">{{ pdfTemplate.formatValue(trackingData.activityData.averageTime, 'time') }}</tspan>
				</text>

				<!-- Time bars -->
				<g
					v-for="(userTime, id, index) in trackingData.activityData.perUser"
					:key="id"
				>
					<text fill="#555" font-family="Helvetica" font-size="12">
						<tspan
							:x="26 + (maxBarWidth / 2) + (maxBarWidth * index)"
							:y="getUserTimeBarHeight(userTime) - 8"
							text-anchor="middle"
						>
							{{ pdfTemplate.formatValue(userTime, 'time') }}
						</tspan>
					</text>
					
					<path
						fill="#006FB7"
						:d="getUserTimeBarPath(userTime, index)"
					/>

					<g fill="#555" font-family="Helvetica" font-size="11">
						<text>
							<tspan
								:x="26 + (maxBarWidth / 2) + (maxBarWidth * index)"
								y="348"
								text-anchor="middle"
							>
								{{ trackingData.usersById[id].first_name }}
							</tspan>
						</text>
						<text>
							<tspan
								:x="26 + (maxBarWidth / 2) + (maxBarWidth * index)"
								y="362"
								text-anchor="middle"
							>
								{{ trackingData.usersById[id].name }}
							</tspan>
						</text>
					</g>
				</g>
			</g>
			<g fill-rule="nonzero" transform="translate(997 234)">
				<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
				<text fill="#FFF" font-family="Helvetica" font-size="9">
					<tspan x="4.75" y="10.804">?</tspan>
				</text>
			</g>
			<g transform="translate(910 21)">
				<polygon fill="#404040" points="94 71 103 80 85 80" transform="matrix(1 0 0 -1 0 151)"/>
				<rect width="187" height="72" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
					<tspan x="33.799" y="19">Estimation de la durée </tspan>
					<tspan x="24.128" y="33">nécessaire à la réalisation </tspan>
					<tspan x="16.78" y="47">des séquences à date (fiable </tspan>
					<tspan x="24.45" y="61">a la date de fin de niveau)</tspan>
				</text>
			</g>
			<g transform="translate(910 252)">
				<polygon fill="#404040" points="94 0 103 9 85 9"/>
				<rect width="187" height="58" y="8" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
					<tspan x="17.451" y="27">La zone grisée correspond à </tspan>
					<tspan x="35.129" y="41">la durée moyenne par </tspan>
					<tspan x="67.821" y="55">utilisateur</tspan>
				</text>
			</g>

			<!-- User progress table -->
			<g transform="translate(30 721)">
				<rect width="991" :height="progressBlockHeight" fill="#FFF" fill-rule="nonzero" rx="16"/>
				<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="22">
					<tspan x="30" y="47.632">Avancement des apprenants</tspan>
				</text>
				<g transform="translate(30 73.584)">
					<path :fill="sequenceStateData.done.color" fill-rule="nonzero" d="M20,10.416 C20,15.939 15.523,20.416 10,20.416 C4.4772,20.416 0,15.939 0,10.416 C0,4.893 4.4772,0.416 10,0.416 C15.523,0.416 20,4.893 20,10.416 Z"/>
					<circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" />
					<path fill="#555" fill-rule="nonzero" d="M8 8.166C8 8.856 7.4404 9.416 6.75 9.416 6.0596 9.416 5.5 8.856 5.5 8.166 5.5 7.476 6.0596 6.916 6.75 6.916 7.4404 6.916 8 7.476 8 8.166ZM14.5 8.166C14.5 8.856 13.94 9.416 13.25 9.416 12.56 9.416 12 8.856 12 8.166 12 7.476 12.56 6.916 13.25 6.916 13.94 6.916 14.5 7.476 14.5 8.166Z"/>
					<path fill="#555" d="M12.927,12.932 C13.192,12.617 13.663,12.576 13.98,12.84 C14.298,13.105 14.341,13.578 14.076,13.896 L13.5,13.416 C14.076,13.896 14.076,13.896 14.076,13.897 L14.075,13.897 L14.074,13.898 L14.072,13.901 L14.066,13.909 C14.061,13.914 14.055,13.921 14.047,13.929 C14.033,13.946 14.013,13.968 13.988,13.994 C13.939,14.047 13.868,14.118 13.778,14.201 C13.598,14.368 13.336,14.584 12.994,14.8 C12.31,15.232 11.298,15.666 10,15.666 C8.702,15.666 7.69,15.232 7.006,14.8 C6.664,14.584 6.402,14.368 6.222,14.201 C6.132,14.118 6.061,14.047 6.012,13.994 C5.987,13.968 5.967,13.946 5.952,13.929 C5.945,13.921 5.939,13.914 5.934,13.909 L5.928,13.901 L5.926,13.898 L5.925,13.897 L5.924,13.897 C5.924,13.896 5.924,13.896 6.5,13.416 L5.924,13.896 C5.659,13.578 5.702,13.105 6.02,12.84 C6.337,12.576 6.807,12.617 7.073,12.932 L7.077,12.936 C7.081,12.942 7.091,12.952 7.104,12.967 C7.132,12.996 7.177,13.042 7.239,13.099 C7.364,13.214 7.555,13.373 7.807,13.532 C8.31,13.85 9.048,14.166 10,14.166 C10.952,14.166 11.69,13.85 12.193,13.532 C12.445,13.373 12.636,13.214 12.761,13.099 C12.823,13.042 12.868,12.996 12.896,12.967 C12.909,12.952 12.919,12.942 12.923,12.936 L12.927,12.932 Z"/>
					<rect width="26" height="20" x="26" y=".416" :fill="sequenceStateData.done.color" fill-rule="nonzero"/>
					<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
						<tspan x="31.581" y="12.976">{{ sequenceStateData.done.content }}</tspan>
					</text>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="58" y="14">{{ sequenceStateData.done.name }}</tspan>
					</text>
					<path :fill="sequenceStateData.planned.color" fill-rule="nonzero" d="M171,10.416 C171,15.939 166.523,20.416 161,20.416 C155.477,20.416 151,15.939 151,10.416 C151,4.893 155.477,0.416 161,0.416 C166.523,0.416 171,4.893 171,10.416 Z"/>
					<circle stroke="#555" stroke-width="1.5" cx="161" cy="10" r="9.5" />
					<path fill="#555" fill-rule="nonzero" d="M159 8.166C159 8.856 158.44 9.416 157.75 9.416 157.06 9.416 156.5 8.856 156.5 8.166 156.5 7.476 157.06 6.916 157.75 6.916 158.44 6.916 159 7.476 159 8.166ZM165.5 8.166C165.5 8.856 164.94 9.416 164.25 9.416 163.56 9.416 163 8.856 163 8.166 163 7.476 163.56 6.916 164.25 6.916 164.94 6.916 165.5 7.476 165.5 8.166Z"/>
					<path fill="#555" d="M158,14.166 C158,13.752 158.3358,13.416 158.75,13.416 L163.25,13.416 C163.664,13.416 164,13.752 164,14.166 C164,14.58 163.664,14.916 163.25,14.916 L158.75,14.916 C158.3358,14.916 158,14.58 158,14.166 Z"/>
					<rect width="26" height="20" x="177" y=".416" :fill="sequenceStateData.planned.color" fill-rule="nonzero"/>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="209" y="14">{{ sequenceStateData.planned.name }}</tspan>
					</text>
					<path :fill="sequenceStateData.missed.color" fill-rule="nonzero" d="M295,10.416 C295,15.939 290.523,20.416 285,20.416 C279.477,20.416 275,15.939 275,10.416 C275,4.893 279.477,0.416 285,0.416 C290.523,0.416 295,4.893 295,10.416 Z"/>
					<circle stroke="#555" stroke-width="1.5" cx="285" cy="10" r="9.5" />
					<path fill="#555" fill-rule="nonzero" d="M283 8.166C283 8.856 282.44 9.416 281.75 9.416 281.06 9.416 280.5 8.856 280.5 8.166 280.5 7.476 281.06 6.916 281.75 6.916 282.44 6.916 283 7.476 283 8.166ZM289.5 8.166C289.5 8.856 288.94 9.416 288.25 9.416 287.56 9.416 287 8.856 287 8.166 287 7.476 287.56 6.916 288.25 6.916 288.94 6.916 289.5 7.476 289.5 8.166Z"/>
					<path fill="#555" d="M282,14.166 C282,13.752 282.336,13.416 282.75,13.416 L287.25,13.416 C287.664,13.416 288,13.752 288,14.166 C288,14.58 287.664,14.916 287.25,14.916 L282.75,14.916 C282.336,14.916 282,14.58 282,14.166 Z"/>
					<rect width="26" height="20" x="301" y=".416" :fill="sequenceStateData.missed.color" fill-rule="nonzero"/>
					<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
						<tspan x="312.608" y="12.976">{{ sequenceStateData.missed.content }}</tspan>
					</text>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="333" y="14">{{ sequenceStateData.missed.name }}</tspan>
					</text>
					<path :fill="sequenceStateData.todo.color" fill-rule="nonzero" d="M449,10.416 C449,15.939 444.523,20.416 439,20.416 C433.477,20.416 429,15.939 429,10.416 C429,4.893 433.477,0.416 439,0.416 C444.523,0.416 449,4.893 449,10.416 Z"/>
					<circle stroke="#555" stroke-width="1.5" cx="439" cy="10" r="9.5" />
					<path fill="#555" fill-rule="nonzero" d="M437 8.166C437 8.856 436.44 9.416 435.75 9.416 435.06 9.416 434.5 8.856 434.5 8.166 434.5 7.476 435.06 6.916 435.75 6.916 436.44 6.916 437 7.476 437 8.166ZM443.5 8.166C443.5 8.856 442.94 9.416 442.25 9.416 441.56 9.416 441 8.856 441 8.166 441 7.476 441.56 6.916 442.25 6.916 442.94 6.916 443.5 7.476 443.5 8.166Z"/>
					<path fill="#555" d="M436,14.166 C436,13.752 436.336,13.416 436.75,13.416 L441.25,13.416 C441.664,13.416 442,13.752 442,14.166 C442,14.58 441.664,14.916 441.25,14.916 L436.75,14.916 C436.336,14.916 436,14.58 436,14.166 Z"/>
					<rect width="26" height="20" x="455" y=".416" :fill="sequenceStateData.todo.color" fill-rule="nonzero"/>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="487" y="14">{{ sequenceStateData.todo.name }}</tspan>
					</text>
					<path :fill="sequenceStateData.late.color" fill-rule="nonzero" d="M641,10.416 C641,15.939 636.523,20.416 631,20.416 C625.477,20.416 621,15.939 621,10.416 C621,4.893 625.477,0.416 631,0.416 C636.523,0.416 641,4.893 641,10.416 Z"/>
					<circle stroke="#555" stroke-width="1.5" cx="631" cy="10" r="9.5" />
					<path fill="#555" fill-rule="nonzero" d="M629 8.166C629 8.856 628.44 9.416 627.75 9.416 627.06 9.416 626.5 8.856 626.5 8.166 626.5 7.476 627.06 6.916 627.75 6.916 628.44 6.916 629 7.476 629 8.166ZM635.5 8.166C635.5 8.856 634.94 9.416 634.25 9.416 633.56 9.416 633 8.856 633 8.166 633 7.476 633.56 6.916 634.25 6.916 634.94 6.916 635.5 7.476 635.5 8.166Z"/>
					<path fill="#555" d="M628.073,15.4 C627.808,15.715 627.337,15.756 627.02,15.492 C626.702,15.227 626.659,14.754 626.924,14.436 L627.5,14.916 C626.924,14.436 626.924,14.436 626.924,14.435 L626.925,14.435 L626.926,14.434 L626.928,14.431 L626.934,14.423 C626.939,14.418 626.945,14.411 626.953,14.403 C626.967,14.386 626.987,14.364 627.012,14.338 C627.061,14.285 627.132,14.214 627.222,14.131 C627.402,13.964 627.664,13.748 628.006,13.532 C628.69,13.1 629.702,12.666 631,12.666 C632.298,12.666 633.31,13.1 633.994,13.532 C634.336,13.748 634.598,13.964 634.778,14.131 C634.868,14.214 634.939,14.285 634.988,14.338 C635.013,14.364 635.033,14.386 635.048,14.403 C635.055,14.411 635.061,14.418 635.066,14.423 L635.072,14.431 L635.074,14.434 L635.075,14.435 L635.076,14.435 C635.076,14.436 635.076,14.436 634.5,14.916 L635.076,14.436 C635.341,14.754 635.298,15.227 634.98,15.492 C634.663,15.756 634.193,15.715 633.927,15.4 L633.923,15.396 C633.919,15.39 633.909,15.38 633.896,15.365 C633.868,15.336 633.823,15.29 633.761,15.233 C633.636,15.118 633.445,14.959 633.193,14.8 C632.69,14.482 631.952,14.166 631,14.166 C630.048,14.166 629.31,14.482 628.807,14.8 C628.555,14.959 628.364,15.118 628.239,15.233 C628.177,15.29 628.132,15.336 628.104,15.365 C628.091,15.38 628.081,15.39 628.077,15.396 L628.073,15.4 Z"/>
					<rect width="26" height="20" x="647" y=".416" :fill="sequenceStateData.late.color" fill-rule="nonzero"/>
					<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
						<tspan x="656.577" y="11.976">{{ sequenceStateData.late.content }}</tspan>
					</text>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="679" y="14">{{ sequenceStateData.late.name }}</tspan>
					</text>
				</g>
				<g :transform="`translate(29 ${userTableEndY + 40})`">
					<path :fill="sequenceStateData.done.color" fill-rule="nonzero" d="M20,10.416 C20,15.939 15.523,20.416 10,20.416 C4.4772,20.416 0,15.939 0,10.416 C0,4.893 4.4772,0.416 10,0.416 C15.523,0.416 20,4.893 20,10.416 Z"/>
					<circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" />
					<path fill="#555" fill-rule="nonzero" d="M8 8.166C8 8.856 7.4404 9.416 6.75 9.416 6.0596 9.416 5.5 8.856 5.5 8.166 5.5 7.476 6.0596 6.916 6.75 6.916 7.4404 6.916 8 7.476 8 8.166ZM14.5 8.166C14.5 8.856 13.94 9.416 13.25 9.416 12.56 9.416 12 8.856 12 8.166 12 7.476 12.56 6.916 13.25 6.916 13.94 6.916 14.5 7.476 14.5 8.166Z"/>
					<path fill="#555" d="M12.927,12.932 C13.192,12.617 13.663,12.576 13.98,12.84 C14.298,13.105 14.341,13.578 14.076,13.896 L13.5,13.416 C14.076,13.896 14.076,13.896 14.076,13.897 L14.075,13.897 L14.074,13.898 L14.072,13.901 L14.066,13.909 C14.061,13.914 14.055,13.921 14.047,13.929 C14.033,13.946 14.013,13.968 13.988,13.994 C13.939,14.047 13.868,14.118 13.778,14.201 C13.598,14.368 13.336,14.584 12.994,14.8 C12.31,15.232 11.298,15.666 10,15.666 C8.702,15.666 7.69,15.232 7.006,14.8 C6.664,14.584 6.402,14.368 6.222,14.201 C6.132,14.118 6.061,14.047 6.012,13.994 C5.987,13.968 5.967,13.946 5.952,13.929 C5.945,13.921 5.939,13.914 5.934,13.909 L5.928,13.901 L5.926,13.898 L5.925,13.897 L5.924,13.897 C5.924,13.896 5.924,13.896 6.5,13.416 L5.924,13.896 C5.659,13.578 5.702,13.105 6.02,12.84 C6.337,12.576 6.807,12.617 7.073,12.932 L7.077,12.936 C7.081,12.942 7.091,12.952 7.104,12.967 C7.132,12.996 7.177,13.042 7.239,13.099 C7.364,13.214 7.555,13.373 7.807,13.532 C8.31,13.85 9.048,14.166 10,14.166 C10.952,14.166 11.69,13.85 12.193,13.532 C12.445,13.373 12.636,13.214 12.761,13.099 C12.823,13.042 12.868,12.996 12.896,12.967 C12.909,12.952 12.919,12.942 12.923,12.936 L12.927,12.932 Z"/>
					<rect width="26" height="20" x="26" y=".416" :fill="sequenceStateData.done.color" fill-rule="nonzero"/>
					<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
						<tspan x="31.581" y="12.976">{{ sequenceStateData.done.content }}</tspan>
					</text>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="58" y="14">{{ sequenceStateData.done.name }}</tspan>
					</text>
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan x="177" y="14">{{ sequenceStateData.done.description }}</tspan>
					</text>
					<g transform="translate(0 33)">
						<path :fill="sequenceStateData.planned.color" fill-rule="nonzero" d="M20,10.416 C20,15.939 15.523,20.416 10,20.416 C4.477,20.416 0,15.939 0,10.416 C0,4.893 4.477,0.416 10,0.416 C15.523,0.416 20,4.893 20,10.416 Z"/>
						<circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" />
						<path fill="#555" fill-rule="nonzero" d="M8 8.166C8 8.856 7.44 9.416 6.75 9.416 6.06 9.416 5.5 8.856 5.5 8.166 5.5 7.476 6.06 6.916 6.75 6.916 7.44 6.916 8 7.476 8 8.166ZM14.5 8.166C14.5 8.856 13.94 9.416 13.25 9.416 12.56 9.416 12 8.856 12 8.166 12 7.476 12.56 6.916 13.25 6.916 13.94 6.916 14.5 7.476 14.5 8.166Z"/>
						<path fill="#555" d="M7,14.166 C7,13.752 7.3358,13.416 7.75,13.416 L12.25,13.416 C12.664,13.416 13,13.752 13,14.166 C13,14.58 12.664,14.916 12.25,14.916 L7.75,14.916 C7.3358,14.916 7,14.58 7,14.166 Z"/>
						<rect width="26" height="20" x="26" y=".416" :fill="sequenceStateData.planned.color" fill-rule="nonzero"/>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="58" y="14">{{ sequenceStateData.planned.name }}</tspan>
						</text>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="177" y="14">{{ sequenceStateData.planned.description }}</tspan>
						</text>
					</g>
					<g transform="translate(0 66)">
						<path :fill="sequenceStateData.missed.color" fill-rule="nonzero" d="M20,10.416 C20,15.939 15.523,20.416 10,20.416 C4.477,20.416 0,15.939 0,10.416 C0,4.893 4.477,0.416 10,0.416 C15.523,0.416 20,4.893 20,10.416 Z"/>
						<circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" />
						<path fill="#555" fill-rule="nonzero" d="M8 8.166C8 8.856 7.44 9.416 6.75 9.416 6.06 9.416 5.5 8.856 5.5 8.166 5.5 7.476 6.06 6.916 6.75 6.916 7.44 6.916 8 7.476 8 8.166ZM14.5 8.166C14.5 8.856 13.94 9.416 13.25 9.416 12.56 9.416 12 8.856 12 8.166 12 7.476 12.56 6.916 13.25 6.916 13.94 6.916 14.5 7.476 14.5 8.166Z"/>
						<path fill="#555" d="M7,14.166 C7,13.752 7.336,13.416 7.75,13.416 L12.25,13.416 C12.664,13.416 13,13.752 13,14.166 C13,14.58 12.664,14.916 12.25,14.916 L7.75,14.916 C7.336,14.916 7,14.58 7,14.166 Z"/>
						<rect width="26" height="20" x="26" y=".416" :fill="sequenceStateData.missed.color" fill-rule="nonzero"/>
						<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
							<tspan x="37.608" y="12.976">{{ sequenceStateData.missed.content }}</tspan>
						</text>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="58" y="14">{{ sequenceStateData.missed.name }}</tspan>
						</text>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="177" y="14">{{ sequenceStateData.missed.description }}</tspan>
						</text>
					</g>
					<g transform="translate(0 99)">
						<path :fill="sequenceStateData.todo.color" fill-rule="nonzero" d="M20,10.416 C20,15.939 15.523,20.416 10,20.416 C4.477,20.416 0,15.939 0,10.416 C0,4.893 4.477,0.416 10,0.416 C15.523,0.416 20,4.893 20,10.416 Z"/>
						<circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" />
						<path fill="#555" fill-rule="nonzero" d="M8 8.166C8 8.856 7.44 9.416 6.75 9.416 6.06 9.416 5.5 8.856 5.5 8.166 5.5 7.476 6.06 6.916 6.75 6.916 7.44 6.916 8 7.476 8 8.166ZM14.5 8.166C14.5 8.856 13.94 9.416 13.25 9.416 12.56 9.416 12 8.856 12 8.166 12 7.476 12.56 6.916 13.25 6.916 13.94 6.916 14.5 7.476 14.5 8.166Z"/>
						<path fill="#555" d="M7,14.166 C7,13.752 7.336,13.416 7.75,13.416 L12.25,13.416 C12.664,13.416 13,13.752 13,14.166 C13,14.58 12.664,14.916 12.25,14.916 L7.75,14.916 C7.336,14.916 7,14.58 7,14.166 Z"/>
						<rect width="26" height="20" x="26" y=".416" :fill="sequenceStateData.todo.color" fill-rule="nonzero"/>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="58" y="14">{{ sequenceStateData.todo.name }}</tspan>
						</text>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="177" y="14">{{ sequenceStateData.todo.description }}</tspan>
						</text>
					</g>
					<g transform="translate(0 132)">
						<path :fill="sequenceStateData.late.color" fill-rule="nonzero" d="M20,10.416 C20,15.939 15.523,20.416 10,20.416 C4.477,20.416 0,15.939 0,10.416 C0,4.893 4.477,0.416 10,0.416 C15.523,0.416 20,4.893 20,10.416 Z"/>
						<circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" />
						<path fill="#555" fill-rule="nonzero" d="M8 8.166C8 8.856 7.44 9.416 6.75 9.416 6.06 9.416 5.5 8.856 5.5 8.166 5.5 7.476 6.06 6.916 6.75 6.916 7.44 6.916 8 7.476 8 8.166ZM14.5 8.166C14.5 8.856 13.94 9.416 13.25 9.416 12.56 9.416 12 8.856 12 8.166 12 7.476 12.56 6.916 13.25 6.916 13.94 6.916 14.5 7.476 14.5 8.166Z"/>
						<path fill="#555" d="M7.073,15.4 C6.808,15.715 6.337,15.756 6.02,15.492 C5.702,15.227 5.659,14.754 5.924,14.436 L6.5,14.916 C5.924,14.436 5.924,14.436 5.924,14.435 L5.925,14.435 L5.926,14.434 L5.928,14.431 L5.934,14.423 C5.939,14.418 5.945,14.411 5.953,14.403 C5.967,14.386 5.987,14.364 6.012,14.338 C6.061,14.285 6.132,14.214 6.222,14.131 C6.402,13.964 6.664,13.748 7.006,13.532 C7.69,13.1 8.702,12.666 10,12.666 C11.298,12.666 12.31,13.1 12.994,13.532 C13.336,13.748 13.598,13.964 13.778,14.131 C13.868,14.214 13.939,14.285 13.988,14.338 C14.013,14.364 14.033,14.386 14.048,14.403 C14.055,14.411 14.061,14.418 14.066,14.423 L14.072,14.431 L14.074,14.434 L14.075,14.435 L14.076,14.435 C14.076,14.436 14.076,14.436 13.5,14.916 L14.076,14.436 C14.341,14.754 14.298,15.227 13.98,15.492 C13.663,15.756 13.193,15.715 12.927,15.4 L12.923,15.396 C12.919,15.39 12.909,15.38 12.896,15.365 C12.868,15.336 12.823,15.29 12.761,15.233 C12.636,15.118 12.445,14.959 12.193,14.8 C11.69,14.482 10.952,14.166 10,14.166 C9.048,14.166 8.31,14.482 7.807,14.8 C7.555,14.959 7.364,15.118 7.239,15.233 C7.177,15.29 7.132,15.336 7.104,15.365 C7.091,15.38 7.081,15.39 7.077,15.396 L7.073,15.4 Z"/>
						<rect width="26" height="20" x="26" y=".416" :fill="sequenceStateData.late.color" fill-rule="nonzero"/>
						<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
							<tspan x="35.577" y="11.976">{{ sequenceStateData.late.content }}</tspan>
						</text>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="58" y="14">{{ sequenceStateData.late.name }}</tspan>
						</text>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
							<tspan x="177" y="14">
								{{ sequenceStateData.late.description }}
							</tspan>
						</text>
					</g>
				</g>

				<!-- Headers -->
				<g
					v-for="(level, index) in course.levels"
					:key="level.id"
				>
					<!-- Levels -->
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan
							:x="levelRects[index].x + (levelRects[index].width / 2)"
							y="137.584"
							text-anchor="middle"
						>
							{{ `Niveau ${(level.order + 1)}` }}
						</tspan>
					</text>

					<!-- Horizontal line -->
					<line
						:x1="levelRects[index].x"
						:x2="levelRects[index].x + levelRects[index].width"
						y1="143.5"
						y2="143.5"
						stroke="#B7B7B7"
					/>

					<!-- Vertical line -->
					<line
						v-if="index < (course.levels.length - 1)"
						:x1="levelRects[index].x + levelRects[index].width"
						:x2="levelRects[index].x + levelRects[index].width"
						y1="124"
						y2="163"
						stroke="#B7B7B7"
					/>

					<!-- Sequences -->
					<g
						v-for="(sequence, sequenceIndex) in level.sequences"
						:key="sequence.id"
					>
						<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="12">
							<tspan
								:x="levelRects[index].x + (maxSequenceWidth * sequenceIndex) + (maxSequenceWidth / 2)"
								y="158.072"
								text-anchor="middle"
							>
								{{ (sequence.name.split(' ')[0] || `${(level.order + 1)}.${(sequence.order + 1)}`) }}
							</tspan>
						</text>
						<line
							v-if="sequenceIndex > 0"
							:x1="levelRects[index].x + (maxSequenceWidth * sequenceIndex)"
							:x2="levelRects[index].x + (maxSequenceWidth * sequenceIndex)"
							y1="144"
							y2="163"
							stroke="#B7B7B7"
						/>
					</g>
				</g>

				<!-- Lines -->
				<g
					v-for="(user, index) in trackingData.users"
					:key="user.id"
				>
					<!-- Emote -->
					<g :transform="`translate(30 ${userRects[index].y})`">
						<g v-if="user.sequencesState == 'todo'" fill="none" fill-rule="evenodd"><path :fill="sequenceStateData.todo.color" fill-rule="nonzero" d="M20,10 C20,15.523 15.523,20 10,20 C4.4772,20 0,15.523 0,10 C0,4.477 4.4772,0 10,0 C15.523,0 20,4.477 20,10 Z"/><circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" /><path fill="#555" fill-rule="nonzero" d="M8 7.75C8 8.44 7.4404 9 6.75 9 6.0596 9 5.5 8.44 5.5 7.75 5.5 7.06 6.0596 6.5 6.75 6.5 7.4404 6.5 8 7.06 8 7.75ZM14.5 7.75C14.5 8.44 13.94 9 13.25 9 12.56 9 12 8.44 12 7.75 12 7.06 12.56 6.5 13.25 6.5 13.94 6.5 14.5 7.06 14.5 7.75Z"/><path fill="#555" d="M7,13.75 C7,13.336 7.3358,13 7.75,13 L12.25,13 C12.664,13 13,13.336 13,13.75 C13,14.164 12.664,14.5 12.25,14.5 L7.75,14.5 C7.3358,14.5 7,14.164 7,13.75 Z"/></g>

						<g v-if="user.sequencesState == 'done'" fill="none"><path :fill="sequenceStateData.done.color" fill-rule="nonzero" d="M20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 Z"/><circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" /><path fill="#555" fill-rule="nonzero" d="M8 7.75C8 8.44 7.44 9 6.75 9 6.06 9 5.5 8.44 5.5 7.75 5.5 7.06 6.06 6.5 6.75 6.5 7.44 6.5 8 7.06 8 7.75ZM14.5 7.75C14.5 8.44 13.94 9 13.25 9 12.56 9 12 8.44 12 7.75 12 7.06 12.56 6.5 13.25 6.5 13.94 6.5 14.5 7.06 14.5 7.75Z"/><path fill="#555" d="M12.927,12.516 C13.192,12.201 13.663,12.16 13.98,12.424 C14.298,12.689 14.341,13.162 14.076,13.48 L13.5,13 C14.076,13.48 14.076,13.48 14.076,13.481 L14.075,13.481 L14.074,13.482 L14.072,13.485 L14.066,13.493 C14.061,13.498 14.055,13.505 14.047,13.513 C14.033,13.53 14.013,13.552 13.988,13.578 C13.939,13.631 13.868,13.702 13.778,13.785 C13.598,13.952 13.336,14.168 12.994,14.384 C12.31,14.816 11.298,15.25 10,15.25 C8.702,15.25 7.69,14.816 7.006,14.384 C6.664,14.168 6.402,13.952 6.222,13.785 C6.132,13.702 6.061,13.631 6.012,13.578 C5.987,13.552 5.967,13.53 5.952,13.513 C5.945,13.505 5.939,13.498 5.934,13.493 L5.928,13.485 L5.926,13.482 L5.925,13.481 L5.924,13.481 C5.924,13.48 5.924,13.48 6.5,13 L5.924,13.48 C5.659,13.162 5.702,12.689 6.02,12.424 C6.337,12.16 6.807,12.201 7.073,12.516 L7.077,12.52 C7.081,12.526 7.091,12.536 7.104,12.551 C7.132,12.58 7.177,12.626 7.239,12.683 C7.364,12.798 7.555,12.957 7.807,13.116 C8.31,13.434 9.048,13.75 10,13.75 C10.952,13.75 11.69,13.434 12.193,13.116 C12.445,12.957 12.636,12.798 12.761,12.683 C12.823,12.626 12.868,12.58 12.896,12.551 C12.909,12.536 12.919,12.526 12.923,12.52 L12.927,12.516 Z"/></g>

						<g v-if="user.sequencesState == 'planned'" fill="none" fill-rule="evenodd"><path :fill="sequenceStateData.planned.color" fill-rule="nonzero" d="M20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 Z"/><circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" /><path fill="#555" fill-rule="nonzero" d="M8 7.75C8 8.44 7.44 9 6.75 9 6.06 9 5.5 8.44 5.5 7.75 5.5 7.06 6.06 6.5 6.75 6.5 7.44 6.5 8 7.06 8 7.75ZM14.5 7.75C14.5 8.44 13.94 9 13.25 9 12.56 9 12 8.44 12 7.75 12 7.06 12.56 6.5 13.25 6.5 13.94 6.5 14.5 7.06 14.5 7.75Z"/><path fill="#555" d="M7,13.75 C7,13.336 7.336,13 7.75,13 L12.25,13 C12.664,13 13,13.336 13,13.75 C13,14.164 12.664,14.5 12.25,14.5 L7.75,14.5 C7.336,14.5 7,14.164 7,13.75 Z"/></g>

						<g v-if="user.sequencesState == 'missed'" fill="none" fill-rule="evenodd"><path :fill="sequenceStateData.missed.color" fill-rule="nonzero" d="M20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 Z"/><circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" /><path fill="#555" fill-rule="nonzero" d="M8 7.75C8 8.44 7.44 9 6.75 9 6.06 9 5.5 8.44 5.5 7.75 5.5 7.06 6.06 6.5 6.75 6.5 7.44 6.5 8 7.06 8 7.75ZM14.5 7.75C14.5 8.44 13.94 9 13.25 9 12.56 9 12 8.44 12 7.75 12 7.06 12.56 6.5 13.25 6.5 13.94 6.5 14.5 7.06 14.5 7.75Z"/><path fill="#555" d="M7,13.75 C7,13.336 7.336,13 7.75,13 L12.25,13 C12.664,13 13,13.336 13,13.75 C13,14.164 12.664,14.5 12.25,14.5 L7.75,14.5 C7.336,14.5 7,14.164 7,13.75 Z"/></g>

						<g v-if="user.sequencesState == 'late'" fill="none" fill-rule="evenodd"><path :fill="sequenceStateData.late.color" fill-rule="nonzero" d="M20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 Z"/><circle stroke="#555" stroke-width="1.5" cx="10" cy="10" r="9.5" /><path fill="#555" fill-rule="nonzero" d="M8 7.75C8 8.44 7.44 9 6.75 9 6.06 9 5.5 8.44 5.5 7.75 5.5 7.06 6.06 6.5 6.75 6.5 7.44 6.5 8 7.06 8 7.75ZM14.5 7.75C14.5 8.44 13.94 9 13.25 9 12.56 9 12 8.44 12 7.75 12 7.06 12.56 6.5 13.25 6.5 13.94 6.5 14.5 7.06 14.5 7.75Z"/><path fill="#555" d="M7.073,14.984 C6.808,15.299 6.337,15.34 6.02,15.076 C5.702,14.811 5.659,14.338 5.924,14.02 L6.5,14.5 C5.924,14.02 5.924,14.02 5.924,14.019 L5.925,14.019 L5.926,14.018 L5.928,14.015 L5.934,14.007 C5.939,14.002 5.945,13.995 5.953,13.987 C5.967,13.97 5.987,13.948 6.012,13.922 C6.061,13.869 6.132,13.798 6.222,13.715 C6.402,13.548 6.664,13.332 7.006,13.116 C7.69,12.684 8.702,12.25 10,12.25 C11.298,12.25 12.31,12.684 12.994,13.116 C13.336,13.332 13.598,13.548 13.778,13.715 C13.868,13.798 13.939,13.869 13.988,13.922 C14.013,13.948 14.033,13.97 14.048,13.987 C14.055,13.995 14.061,14.002 14.066,14.007 L14.072,14.015 L14.074,14.018 L14.075,14.019 L14.076,14.019 C14.076,14.02 14.076,14.02 13.5,14.5 L14.076,14.02 C14.341,14.338 14.298,14.811 13.98,15.076 C13.663,15.34 13.193,15.299 12.927,14.984 L12.923,14.98 C12.919,14.974 12.909,14.964 12.896,14.949 C12.868,14.92 12.823,14.874 12.761,14.817 C12.636,14.702 12.445,14.543 12.193,14.384 C11.69,14.066 10.952,13.75 10,13.75 C9.048,13.75 8.31,14.066 7.807,14.384 C7.555,14.543 7.364,14.702 7.239,14.817 C7.177,14.874 7.132,14.92 7.104,14.949 C7.091,14.964 7.081,14.974 7.077,14.98 L7.073,14.984 Z"/></g>
					</g>

					<!-- User name -->
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<template v-if="userRects[index].height > 24">
							<tspan
								x="56"
								:y="userRects[index].y + 14"
							>
								{{ user.first_name }}
							</tspan>
							<tspan
								x="56"
								:y="userRects[index].y + 36"
							>
								{{ user.name }}
							</tspan>
						</template>
						<tspan
							v-else
							x="56"
							:y="userRects[index].y + 14"
						>
							{{ [user.first_name, user.name].join(' ') }}
						</tspan>
					</text>

					<!-- User sequence states -->
					<template v-for="(level, levelIndex) in course.levels">
						<g
							v-for="(sequence, sequenceIndex) in level.sequences"
							:key="sequence.id"
						>
							<rect
								:width="maxSequenceWidth - 1"
								:height="userRects[index].height - 1"
								:x="levelRects[levelIndex].x + (maxSequenceWidth * sequenceIndex)"
								:y="userRects[index].y"
								:fill="sequenceStateData[user.sequenceState[sequence.id]].color"
								fill-rule="nonzero"
							/>
							<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="10">
								<tspan
									:x="levelRects[levelIndex].x + (maxSequenceWidth * sequenceIndex) + (maxSequenceWidth / 2)"
									:y="userRects[index].y + (userRects[index].height / 2) + 2"
									text-anchor="middle"
								>
									{{ sequenceStateData[user.sequenceState[sequence.id]].content }}
								</tspan>
							</text>
						</g>
					</template>
				</g>
			</g>
			<g fill-rule="nonzero" transform="translate(997 234)">
				<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
				<text fill="#FFF" font-family="Helvetica" font-size="9">
					<tspan x="4.75" y="10.804">?</tspan>
				</text>
			</g>
		</g>
	</svg>
</template>

<script>
	import pdfTemplate from '@/utils/pdfTemplate'

	export default {
		name: 'GroupPDFTemplate',
		props: {
			group: {
				type: Object,
				required: true,
			},
			course: {
				type: Object,
				required: true,
			},
			trackingData: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				pdfTemplate,
				sequenceStateData: {
					done: {
						color: '#89E481',
						name: 'Réalisée',
						content: 'OK',
						description: 'La séquence a été réalisée dans sa totalité.',
					},
					planned: {
						color: '#83CBFF',
						name: 'Planifiée',
						content: '',
						description: 'La séquence est planifiée mais n\'a pas encore été réalisée.',
					},
					missed: {
						color: '#FFC369',
						name: 'À réaliser',
						content: '!',
						description: 'La séquence n\'a pas été réalisée à la date programmée.',
					},
					todo: {
						color: '#F0F4F4',
						name: 'À planifier',
						content: '',
						description: 'La séquence n\'a pas encore été planifiée.',
					},
					late: {
						color: '#FF8796',
						name: 'En retard',
						content: 'X',
						description: 'La date limite du parcours est atteinte, l\'utilisateur n\'a pas réalisé sa séquence à temps.',
					},
				},
			}
		},
		computed: {
			maxBarWidth() {
				const userCount = Object.keys(this.trackingData.activityData.perUser).length

				return (930 / userCount)
			},
			maxUserTime() {
				const userTimes = this.trackingData.activityData.perUser
				const userIds = Object.keys(userTimes)

				return userIds.reduce((max, userId) => {
					max = Math.max(userTimes[userId], max)
					return max
				}, 0)
			},
			maxSequenceWidth() {
				const sequenceCount = this.course.levels.reduce((count, level) => {
					return (count + level.sequences.length)
				}, 0)

				return ((960 - 200) / sequenceCount)
			},
			levelRects() {
				// Origin X
				let lastRectEnd = 200

				// Each level as a different width because they can have different sequences count
				return this.course.levels.map((level) => {
					const width = (level.sequences.length * this.maxSequenceWidth)
					
					const x = lastRectEnd
					
					lastRectEnd = (x + width)

					return {
						x,
						width,
					}
				})
			},
			userRects() {
				// Origin Y
				let lastRectEnd = 163

				return this.trackingData.users.map((user) => {
					// Increase rect height if the user name is too long
					const name = [user.first_name, user.name].join(' ')
					const height = (name.length > 18 ? 2 : 1) * 24

					const y = lastRectEnd
					
					lastRectEnd = (lastRectEnd + height)

					return {
						y,
						height,
					}
				})
			},
			userTableEndY() {
				const lastUserRect = this.userRects[this.trackingData.users.length - 1]

				if (!lastUserRect) {
					return 163
				}

				return (lastUserRect.y + lastUserRect.height)
			},
			progressBlockHeight() {
				return (this.userTableEndY + 40 + 167 + 40)
			},
			contentBlockHeight() {
				return (721 + this.progressBlockHeight + 30)
			}
		},
		methods: {
			getUserTimeBarHeight(userTime) {
				const value = (userTime / this.maxUserTime)
				const min = 331
				const max = 134
				const maxHeight = (min - max)

				return Math.min(min - (maxHeight * value), min - 6)
			},
			getUserTimeBarPath(userTime, index) {
				const height = this.getUserTimeBarHeight(userTime)

				const barWidth = Math.min(this.maxBarWidth - 15, 60)
				const deltaX = 26 + (index * this.maxBarWidth) + (this.maxBarWidth / 2) - (barWidth / 2)

				return `
					M${deltaX},${height + 6}
					C${deltaX},${height + 3} ${2 + deltaX},${height} ${6 + deltaX},${height}
					L${barWidth + deltaX},${height}
					C${3 + barWidth + deltaX},${height} ${6 + barWidth + deltaX},${height + 3} ${6 + barWidth + deltaX},${height + 6}
					L${6 + barWidth + deltaX},331
					L${deltaX},331 L${deltaX},${height + 6} Z
				`
			},
			getUserAverageTimeBarPath(averageUserTime) {
				const height = this.getUserTimeBarHeight(averageUserTime)

				return `
					M30,${height + 3}
					C30,${height} 30.8954,${height} 32,${height}
					L959,${height}
					C960.1,${height} 961,${height} 961,${height + 3}
					L961,331
					L30,331
					L30,${height + 3} Z
				`
			},
		},
	}
</script>