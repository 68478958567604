export default function checkGroupsSequenceData(groupsResponse, scenarioDoneResponse) {
    if (!groupsResponse || !groupsResponse.data || !groupsResponse.data.users || !groupsResponse.data.users[0] || !groupsResponse.data.users[0].promotion_users) {
        return []
    }

    const groups = groupsResponse.data.users[0].promotion_users.map((pu) => pu.promotion)

    let scenarioCompletedById = {}
    let replayedScenariosById = {}

    // Compute data from scenario done
    groups.forEach((group) => {
        // Simulate how the old build check completion (not ordered by date and limited by course id)
        scenarioCompletedById = {}
        replayedScenariosById = {}

        scenarioDoneResponse.data.users[0].logs.forEach((log) => {
            const id = log.data_key

            // Only take the first log/time a scenario is done for this course
            if (!scenarioCompletedById[id] && log.data.parcours_id === group.course.id) {
                scenarioCompletedById[id] = log
            } else {
                // Store replayed ones in another dictionary
                if (!replayedScenariosById[id]) {
                    replayedScenariosById[id] = [log]
                } else {
                    replayedScenariosById[id].push(log)
                }
            }
        })

        const sequenceById = group.course.levels.reduce((dict, level) => {
            level.sequences.forEach((sequence) => {
                dict[sequence.id] = {
                    ...sequence,
                    level,
                }
            })
            return dict
        }, {})

        group.course.levels.forEach((level) => {
            level.sequences.forEach((sequence) => {
                sequence.freeScenarioDone = level.scenarios.reduce((arr, s) => {
                    const log = scenarioCompletedById[s.scenario.id]
                    
                    if (log && log.data && log.data.sequence_id === sequence.id) {
                        arr.push({
                            ...s.scenario,
                            log,
                            level,
                            sequence,
                        })
                    }

                    return arr
                }, [])

                // Check if we have done more free scenario in this sequence than needed
                sequence.warning = (sequence.freeScenarioDone.length > sequence.level_scenario_limit)

                if (sequence.warning) {
                    if (!sequence.warningReasons) {
                        sequence.warningReasons = []
                    }
                    sequence.warningReasons.push('Trop de scénarios libres. Les scénarios en trop doivent être déplacés dans une autre séquence ou il reste des places libres.')
                }

                sequence.scenarioDone = sequence.scenarios.reduce((arr, s) => {
                    const log = scenarioCompletedById[s.scenario.id]

                    if (log && log.data && (!log.data.sequence_id || log.data.sequence_id === sequence.id)) {
                        arr.push({
                            ...s.scenario,
                            log,
                            level,
                            sequence,
                        })
                    }

                    return arr
                }, [])

                // List replayed scenario first done in this sequence
                sequence.replayedScenarios = []
                sequence.freeScenarioDone.concat(sequence.scenarioDone).forEach((scenario) => {
                    const replayedScenarios = replayedScenariosById[scenario.id]

                    if (replayedScenarios) {
                        sequence.replayedScenarios = 
                            sequence.replayedScenarios.concat(
                                replayedScenarios.map((log) => {
                                    // Check if replay is done in a sequence before this one
                                    let warning = false
                                    const replaySequence = sequenceById[log.data.sequence_id]
                                    const replayLevel = replaySequence && replaySequence.level

                                    if (replaySequence && (replayLevel.order < level.order || replaySequence.order < sequence.order)) {
                                        warning = true
                                        sequence.warning = true

                                        if (!sequence.warningReasons) {
                                            sequence.warningReasons = []
                                        }

                                        sequence.warningReasons.push('Le scénario ' + scenario.name + ' à été rejoué dans une séquence avant celle ou il a été joué pour la première fois. Les logs des scénarios rejoué doivent être déplacés dans la même séquence que le première fois.')
                                    }

                                    return {
                                        ...scenario,
                                        warning,
                                        log,
                                        level,
                                        sequence,
                                    }
                                })
                            )
                    }
                })

                if (sequence.warning) {
                    level.warning = true
                }
            })
        })
    })

    return groups
}