<template>
    <div class="user-detail mt-8 md:mt-0">
        <div class="buttons flex flex-row justify-around md:justify-between">
            <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>

            <div class="ml-auto">
                <button type="button" class="twn-button text-xs danger mr-4" @click="showDeleteModal" v-if="!creating">Supprimer le profil</button>
                <button type="button" class="twn-button text-xs" @click="createOrEdit">Enregistrer le profil</button>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="twn-card user-card mt-10 mb-8">
                <div class="flex justify-between items-center">
                    <div class="uppercase font-principal-bold text-sm" v-if="creating">Ajouter un profil</div>
                    <div class="uppercase font-principal-bold text-sm" v-else>éditer le profil</div>
                    <button v-if="!creating" type="button" class="twn-button text-xs" @click="sendValidationCode" :disabled="isUserValidated">Envoyer un code d'inscription</button>
                </div>
                <hr>
                <form class="flex flex-row flex-wrap justify-around">
                    <div class="left">
                        <div class="input-group mt-0 md:mt-10">
                            <label for="email">Adresse e-mail</label>
                            <b-form-input v-model="fields.email" type="email" id="email" :state="emailValidation"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="password">Mot de passe</label>
                            <b-form-input :disabled="creating && fields.role == 'user'" v-model="fields.password" type="text" id="password" :placeholder="(creating && fields.role == 'user' ? 'Défini à la première connexion' : '')"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="name">Nom</label>
                            <b-form-input v-model="fields.name" type="text" id="name" :state="nameValidation"></b-form-input>

                        </div>
                        <div class="input-group">
                            <label for="firstName">Prénom</label>
                            <b-form-input v-model="fields.firstName" type="text" id="firstName"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="registrationDate">Date d'inscription</label>
                            <b-form-datepicker
                                id="registrationDate"
                                reset-button
                                label-reset-button="Effacer"
                                v-model="fields.registrationDate"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
                            />
                        </div>
                    </div>
                    <div class="right">
                        <div class="input-group">
                            <label for="organisation">Organisation(s)</label>
                            <v-select
                                label="name"
                                :options="organizationList"
                                v-model="fields.organizations"
                                multiple
                                id="organisation"
                                class="twn-select"
                                :reduce="orga => orga.id"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="status">Statut</label>
                            <v-select
                                v-model="fields.status"
                                id="status"
                                :options="statusList"
                                label="name"
                                class="twn-select"
                                :reduce="status => {
                                    return status.slug
                                }"
                            >
                                <template #search="{attributes, events}">
                                    <input
                                    class="vs__search"
                                    v-bind="attributes"
                                    v-on="events"
                                    />
                                </template>
                            </v-select>
                        </div>
                        <div class="input-group" v-if="fields.status && fields.status == 2">
                            <label for="company">Employeur</label>
                            <b-form-input v-model="fields.company" type="text" id="company"></b-form-input>
                        </div>
                        <div class="input-group" v-if="(fields.role && fields.role !== 'superadmin') || isAdmin && !visitor">
                            <label for="role">Rôle</label>
                            <v-select
                                v-model="fields.role"
                                id="role"
                                :options="roleList"
                                label="name"
                                class="twn-select"
                                :reduce="role => role.slug"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="seniority">Ancienneté</label>
                            <v-select
                                v-model="fields.seniority"
                                id="seniority"
                                :options="seniorityList"
                                label="name"
                                class="twn-select"
                                :reduce="seniority => seniority.slug"
                            >
                            </v-select>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <button
                    v-if="!creating"
                    class="float-right twn-button text-xs mb-6"
                    type="button"
                    @click="showCheckProgressModal"
                >
                    Déblocage session libre
                </button>
                <button
                    v-if="!creating && isTWN"
                    class="float-right twn-button text-xs mb-6 mr-2"
                    type="button"
                    @click="showDebugProgressModal"
                >
                    Debug progression
                </button>

                <b-table
                    :items="fields.promotions"
                    :fields="promotionArrFields"
                >
                    <template #cell(title)="data">
                        <router-link :to="{ name: 'promotions-edit', params: { itemID: data.item.id }}">
                            {{ data.value }}
                        </router-link>
                    </template>
                </b-table>
            </div>

            <div v-if="fields.id && fields.promotions" class="mt-8">
              <UserDashboard :user="fields" />
            </div>

        </div>
        <b-modal ref="delete-modal-detail-user" class="bootstrap" centered hide-footer id="delete-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-user')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>

        <CheckUserProgressModal
            ref="check-progress-modal"
            :user-id="getUser.id"
            :first-name="fields.firstName"
            :last-name="fields.name"
        />

        <DebugUserProgressModal
            ref="debug-progress-modal"
            :user-id="getUser.id"
            :first-name="fields.firstName"
            :last-name="fields.name"
        />
    </div>
</template>
<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import { FC_DELETE_ITEM } from '@/graphql/list'
import UserDashboard from '@/components/dashboard/User'
import CheckUserProgressModal from '@/components/user/CheckUserProgressModal'
import DebugUserProgressModal from '@/components/user/DebugUserProgressModal'
import router from '@/router/index'


export default {
    name: 'UserDetail',
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
    components:{
        UserDashboard,
        CheckUserProgressModal,
        DebugUserProgressModal,
    },
    created(){
        if (this.userInfo.role == 'superadmin'){
            this.isAdmin = true
        } else if(this.userInfo.role == 'organisationadmin') {
            this.isAdmin = false
        } else {
            this.visitor = true
        }
    },
    async mounted() {
        if (this.getCurrentID !== -1 || this.itemID){
            let id = this.itemID;
            if (this.getCurrentID !== -1 && !this.itemID){
                id = this.getCurrentID;
            }
            await this.setUser(id)
            this.mapFields();
        } else {
            this.creating = true;
            await this.initUserEditLists();
            this.organizationList = this.getOrganizationList;
            // this.statusList = this.getStatusList;
        }
    },
    data() {
        return {
            isUserValidated: false,
            star: {
                number: 8,
                colored: 2
            },
            selectedID: '',
            organizationList: [],
            isAdmin: false,
            visitor: false,
            selectedRole: null,
            selectedStatus: null,
            statusList: [
                {
                    slug: 0,
                    name: 'GI'
                },
                {
                    slug: 1,
                    name: 'RS'
                },
                {
                    slug: 2,
                    name: 'Autre poste dans le logement social'
                }
            ],
            experienceList: [
                {
                    slug: true,
                    name: 'Navigation libre'
                },
                {
                    slug: false,
                    name: 'Navigation guidée'
                },
            ],
            seniorityList: [
                {
                    slug: 0,
                    name: '0 an'
                },
                {
                    slug: 1,
                    name: '0-2 ans'
                },
                {
                    slug: 2,
                    name: '2-5 ans'
                },
                {
                    slug: 3,
                    name: '5-10 ans'
                },
                {
                    slug: 4,
                    name: '+10 ans'
                },
                {
                    slug: 5,
                    name: 'Non concerné'
                },
            ],
            fields: {
                id: '',
                firstName: '',
                name: '',
                organizations: [],
                status: null,
                email: '',
                seniority: null,
                company: '',
                experienced: false,
                role: 'user',
                password: '',
                registrationDate: null,
            },
            promotionArrFields:[
				{
					key: "index",
					label: "",
					sortable: false
				},
				{
					key: "title",
					label: "Promotions associées",
					sortable: false
                },
				{
					key: "start_date",
					label: "Date de début",
					sortable: false
                },
				{
					key: "end_date",
					label: "Date de fin",
					sortable: false
				},
			],
            delayer: true,
            creating: false
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        ...mapGetters('EditItem', ['getUser', 'getOrganizationList', 'getStatusList', 'getCurrentID']),
        nameValidation(){
            return this.fields.name !== undefined && this.fields.name !== null && this.fields.name !== '';
        },
        emailValidation(){
            return this.fields.email !== undefined && this.fields.email !== null && this.fields.email !== '';
        },
        allValid(){
            return this.nameValidation
                && this.emailValidation
        },
        roleList(){
            if (this.isAdmin){
                return [
                    {slug:"user", name:"Utilisateur"},
                    {slug:"organisationadmin", name:"AdminClient"},
                    {slug:"superadmin", name:"SuperAdmin"},
                ]
            }
            return [
                    {slug:"user", name:"Utilisateur"},
                    {slug:"organisationadmin", name:"AdminClient"},
                ]
        },
        isTWN() {
            return this.userInfo.email.indexOf('thewalkingnerds.com') === (this.userInfo.email.length - 19)
        }
    },
    methods: {
        ...mapActions('EditItem', ['setUser', 'editUser', 'initUserEditLists', 'addUser']),
        goBack(){
            router.push({name:'users-list', params: {
                previousPage: this.previousPage
            }})
        },
        showDeleteModal(){
            this.$refs['delete-modal-detail-user'].show();
        },
        showCheckProgressModal() {
            this.$refs['check-progress-modal'].show();
        },
        showDebugProgressModal() {
            this.$refs['debug-progress-modal'].show();
        },
        async deleteItem(){
            await this.$apollo.query({
                query: FC_DELETE_ITEM("users"),
                variables: {
                    id: this.fields.id
                }
            })
            this.$refs['delete-modal-detail-user'].hide();
            this.$router.go(-1);
        },
        mapFields(){
            this.fields = this.getUser;
            this.organizationList = this.getOrganizationList;
            // this.statusList = this.getStatusList;
        },
        createOrEdit(e){
            e.preventDefault();
            if(this.creating){
                this.confirmCreate();
            } else {
                this.confirmEdit();
            }
        },
        async confirmEdit(){
            // if (this.allValid && this.delayer){
                await this.editUser(this.fields);
                this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
            //     this.delayer = false;
            //     setTimeout(this.resetDelayer, 500);
            // }
        },
        async confirmCreate(){
            // if (this.allValid && this.delayer){
            //     this.delayer = false;
            //     //create
                await this.addUser(this.fields);
                this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
            //     this.creating = false;
            //     setTimeout(this.resetDelayer, 500);
            // }
        },
        resetDelayer(){
            this.delayer = true;
        },
        async sendValidationCode() {
            if (this.isUserValidated)
                return

            this.isUserValidated = true

            try {
                await this.$store.dispatch('Auth/sendValidationToken', this.fields.email)//todo: email check ? real email ?
                alert('Code envoyer à ' + this.fields.email)
            } catch (error) {
                this.isUserValidated = false
            }
        },
    },
}
</script>
<style lang="scss">
.user-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/3 text-xs;
            }
            > input, > div {
                &:not(.is-invalid){
                    @apply border-line-top;
                }
                @apply w-2/3 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-2/3;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
        }
    }
    @screen md {
        .left, .right {
            > div {
                @apply my-10;
            }
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
    .stars{
        .star{
            @apply w-8 mx-1 fill-current text-gray-star;
            &.complete{
                @apply text-principal-selected;
            }
        }
    }
}
</style>