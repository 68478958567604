<template>
	<div class="dashboard dashboard-user">
		<!-- Header -->
		<div class="flex items-center justify-between mb-8 w-full">
            <div></div>
			<div>
				<button type="button" class="twn-button mr-4" @click="exportPDF" :disabled="loadingPDF || isLoading">
					<span v-if="!loadingPDF">
						Export PDF
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>

				<!-- <button type="button" class="twn-button" @click="ExportReport" :disabled="loadingReport || isLoading || true">
					<span v-if="!loadingReport">
						Export CSV
					</span>
					<span v-else>
						Chargement...
					</span>
				</button> -->
			</div>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !trackingData">
			Chargement...
		</p>
		<div v-else>
			<!-- Progress and times -->
			<CategoryBlock
                v-for="group in trackingData.user.groupsById"
                :key="group.id"
                title="Connexions"
                :subtitle="`${group.title} - ${formatDate(group.start_date)} - ${formatDate(group.end_date)}`"
            >
				<div class="dashboard-kpis">
					<KPIBlock
						title="Complétion de la formation"
						:percentage="group.progressRate"
                        help="Nombre de scénarios réalisés / nombre total de scénarios"
                        backgroundColor="bg-dashboard-blue-dark"
					/>
                    <KPIBlock
                        title="Durée totale de connexion"
                        :time="group.activeTimeData.totalTime"
                    />
					<KPIBlock
						title="Temps passé pour compléter un scénario (Moyenne)"
						:time="group.activeTimeData.averageScenarioTime"
                        help="Pour la première tentative aboutie concernant les MES et VT."
					/>
					<KPIBlock
						title="Durée estimée"
						:time="group.activeTimeData.remainingTime"
						:help="(group.type.slug === 'collective_promotion' ?
							'Estimation de la durée nécessaire à la réalisation des séquences à date' :
							'Estimation de la durée nécessaire à la réalisation des séquences à date (fiable a la date de fin de niveau)'
						)"
					/>
				</div>
                
                <!-- Games scores -->
                <SubCategoryBlock
                    title="Réussite de l’apprenant"
                    help="% de bonnes réponses aux mini-jeux par thème"
                >
                    <div class="dashboard-subcategory-scores">
                        <div
                            class="w-1/2"
                            v-for="category in group.scoresByCategory"
                            :key="category.id"
                        >
                            <p>{{ category.name }}</p>
                            <div>
                                <span
                                    :style="{
                                        right: Math.min(101 - (category.score * 100), 95) + '%'
                                    }"
                                >
                                    {{ (category.score * 100).toFixed(0) + ' %' }}
                                </span>
                                <progress
                                    class="w-full"
                                    :value="category.score * 100"
                                    max="100"
                                >
                                    {{ (category.score * 100).toFixed(0) + ' %' }}
                                </progress>
                            </div>
                        </div>
                    </div>
                </SubCategoryBlock>

                <!-- Scenarios scores -->
                <SubCategoryBlock title="Météo des épisodes">
                    <div class="dashboard-subcategory-scores">
                        <div
                            class="w-1/2"
                            v-for="scenario in group.scoresByScenario"
                            :key="scenario.id"
                        >
                            <div class="flex pb-1" :title="scenario.name + ' : ' + (scenario.score * 100).toFixed(0) + ' % de moyenne'">
                                <p class="flex-grow">{{ scenario.name }}</p>
                                <img v-if="scenario.score < 0.40" src="@/assets/images/STORM_ICON.svg">
                                <img v-else-if="scenario.score < 0.80" src="@/assets/images/CLOUD_ICON.svg">
                                <img v-else src="@/assets/images/SUN_ICON.svg">
                            </div>
                        </div>
                    </div>
                </SubCategoryBlock>

                <!-- Scenario states and logs -->
                <SubCategoryBlock
                    class="dashboard-subcategory-logs"
                    title="Avancement de l’apprenant"
                >
                    <img :src="sequenceStateData[group.sequencesState].icon">

                    <table>
                        <thead>
                            <tr>
                                <th>Niveau</th>
                                <th>Reste à faire</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="module in group.course.modules"
                                :key="module.id"
                            >
                                <td>
                                    {{ module.name || `Niveau ${module.order + 1}` }}
                                </td>
                                <td>
                                    <span
                                        v-if="group.remainingScenariosByModuleId[module.id].length === 0"
                                    >
                                        Aucun
                                    </span>
                                    <span
                                        v-else
                                    >
                                        {{ group.remainingScenariosByModuleId[module.id].map((scenario) => {
                                            return trackingData.scenariosById[scenario.id].name
                                        }).join(', ') }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                            <tr>
                                <th>Scénario</th>
                                <th>Date</th>
                                <th>Heure</th>
                                <th>Statut</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="log in group.scenarioLogs.slice(currentPage * 20, (currentPage * 20) + 20)"
                                :key="log.id"
                            >
                                <td v-if="trackingData.scenariosById[log.data_key]">
                                    {{ trackingData.scenariosById[log.data_key].name }} - {{ trackingData.scenariosById[log.data_key].title }}
                                </td>
                                <td>
                                    {{ formatDate(log.created_at) }}
                                </td>
                                <td>
                                    {{ formatTime(log.created_at) }}
                                </td>
                                <td>
                                    {{ (log.type.slug === 'scenario_end' ? 'Fin' : 'Début') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="maxPageForGroup(group) > 0" class="flex items-center justify-center mt-6">
                        <span
                            class="px-2 cursor-pointer hover:bg-dashboard-gray-lighter border-b border-transparent"
                            @click="(currentPage > 0 ? currentPage -= 1 : null)"
                        >
                            &lt;
                        </span>
                        <span
                            v-for="page in maxPageForGroup(group)"
                            :key="page"
                            class="px-2 cursor-pointer hover:bg-dashboard-gray-lighter border-b border-transparent"
                            :class="{ 'font-principal-medium border-gray': (currentPage === (page - 1)) }"
                            @click="currentPage = (page - 1)"
                        >
                            {{ page }}
                        </span>
                        <span
                            class="px-2 cursor-pointer hover:bg-dashboard-gray-lighter border-b border-transparent"
                            @click="(currentPage < (maxPageForGroup(group) - 1) ? currentPage += 1 : null)"
                        >
                            &gt;
                        </span>
                    </div>
                </SubCategoryBlock>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
    import moment from 'moment'

	import trackingReport from '@/utils/trackingReport'
	import pdfTemplate from '@/utils/pdfTemplate'
	
	import trackingTemplate from "@/constants/userTrackingReportTemplate"
	
	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"

    import UserPDFTemplate from "./pdfTemplates/User"
	
	export default {
		name: 'UserDashboard',
		props: {
			user: {
				type: Object,
				required: true,
			},
		},
		components: {
			CategoryBlock,
            SubCategoryBlock,
			KPIBlock,
		},
		data() {
			return {
                currentPage: 0,
				isLoading: false,
				trackingData: null,
				loadingReport: false,
				loadingPDF: false,
                sequenceStateData: {
                    todo: {
                        icon: require('../../assets/images/EMOTE_TODO.svg'),
                    },
                    done: {
                        icon: require('../../assets/images/EMOTE_DONE.svg'),
                    },
                    planned: {
                        icon: require('../../assets/images/EMOTE_PLANNED.svg'),
                    },
                    missed: {
                        icon: require('../../assets/images/EMOTE_MISSED.svg'),
                    },
                    late: {
                        icon: require('../../assets/images/EMOTE_LATE.svg'),
                    },
                },
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
		},
		watch: {
			user: {
				handler() {
					this.loadData()
				},
				immediate: true,
			},
		},
		methods: {
            formatDate(dt) {
                return moment(new Date(dt)).locale('fr').format('L')
            },
            formatTime(dt) {
                return moment(new Date(dt)).locale('fr').format('LTS')
            },
            maxPageForGroup(group) {
                return Math.ceil(group.scenarioLogs.length / 20)
            },
			async loadData(force = false) {
				if (!this.user?.id) {
					return
				}

				if (this.isLoading) {
					return
				}

				this.isLoading = true

				// Load tracking data
				const payload = {
					id: this.user.id,
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingTemplate, payload, force)

				this.isLoading = false
			},
			async ExportReport() {
				this.loadingReport = true

				try {
					// Set contextual data
					const payload = {
                        id: this.user.id,
                    }

					// Create report file
					await trackingReport.export(`TB_${[this.user.firstName, this.user.name].join(' ')}`, this.$store, trackingTemplate, payload)
				} catch (err) {
                    // eslint-disable-next-line no-console
					console.error(err)
				}

				this.loadingReport = false
			},
			async exportPDF() {
                this.loadingPDF = true

                pdfTemplate.exportFromComponent(`TB_${[this.user.firstName, this.user.name].join(' ')}`, UserPDFTemplate, { user: this.user, trackingData: this.trackingData })

                this.loadingPDF = false
            }
		}
	}
</script>

<style lang="scss">
    .dashboard-user {
        .dashboard-category {
            @apply relative;

            & > p:nth-child(1) {
                @apply mb-4;
            }

            & > p:nth-child(2) {
                @apply absolute w-full text-center left-0;
                top: 1.75rem;
            }
        }

        .dashboard-subcategory-logs {
            & > p:first-of-type, & > img:first-of-type {
                @apply inline-block mb-1 mr-2;
                height: 1.75rem;
            }

            & > table:first-of-type {
                tr > th:first-child {
                    min-width: 8rem;
                }
            }
        }

        table {
            @apply w-full mt-4;

            tr {
                th, td {
                    &:first-child {
                        @apply pl-2;
                    }
                }
            }

            th {
                @apply bg-dashboard-blue-dark text-white pt-3 pb-2;
            }

            td {
                @apply pt-2 pb-1;
                border-bottom: 1px solid #EEEEEE;
            }
        }

        .dashboard-subcategory-scores {
            @apply flex flex-wrap;

            & > div:nth-child(odd) {
                @apply pr-4;
            }

            & > div:nth-child(even) {
                @apply pl-4;
            }

            & > div > div {
                @apply relative;

                & > span {
                    @apply absolute text-xs text-white;
                    top: 2px;
                }
            }

            progress {
                @apply rounded-full overflow-hidden border-2 border-dashboard-black appearance-none;
                height: 20px;
            }

            ::-webkit-progress-bar {
                background-color: #EEEEEE;
            }
            ::-moz-progress-bar {
                background-color: #EEEEEE;
            }
            ::-webkit-progress-value {
                @apply bg-dashboard-green;
            }
        }
    }
</style>