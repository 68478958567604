<template>
	<div class="dashboard-category">
		<p class="text-2xl">{{title}}</p>
		<p v-if="subtitle">{{subtitle}}</p>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'CategoryBlock',
		props: {
			title: {
				type: String,
				required: true
			},
			subtitle: {
				type: String,
				default: null,
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-category {
		@apply bg-dashboard-gray-lighter rounded-dashboard-md px-12 pt-5 pb-12;

		& > p {
			@apply text-dashboard-gray-darker;

			&:last-of-type {
				@apply mb-4;
			}
		}
	}
</style>