import gql from "graphql-tag"

const GC_GET_GAME_LIST = gql`
	query GetGameList {
		game {
			id
			name
			title
			game_type_id
			scores {
				score
				jauge_id
			}
		}
	}
`

const GC_GET_GAME_TYPES = gql`
	query GetGameTypes {
		game_type {
			id
			name
			description
			picto
			slug
		}
	}
`

const GC_GET_QUESTION_TYPES = gql`
query GetQuestionTypes {
	question_type {
		id
		name
		slug
	}
}
`

const GC_GET_JAUGES = gql`
query GetJauges{
	jauges(order_by: {slug: asc}) {
		id
		name
		slug
	}
}
`

const GC_ADD_GAME_ONE = gql`
	mutation AddGameOne(
		$name: String
		$title: String
		$instruction: String
		$media_id: uuid
		$gameType: uuid!
		$questions: [question_insert_input!]!
		$headers: [table_header_insert_input!]!
		$hideAnswers: Boolean
		$prepareTime: Int
		$executeTime: Int
		$scores: [game_scores_insert_input!]!
		$medias: [game_media_insert_input!]!
		$correction: String
	) {
		insert_game_one(
			object: {
				name: $name
				title: $title
				correction: $correction
				media_id: $media_id
				prepareTime: $prepareTime
				executeTime: $executeTime
				hideAnswers: $hideAnswers
				instruction: $instruction
				game_type_id: $gameType
				questions: { data: $questions }
				headers: { data: $headers }
				medias: { data: $medias }
				scores: { data: $scores }
			}
		) {
			id
		}
	}
`

const GC_GET_GAME_BY_ID = gql`
	query GameByID($id: uuid!) {
		game(where: { id: { _eq: $id } }) {
			name
			title
			instruction
			hideAnswers
			id
			correction
			media_id
			prepareTime
			executeTime
			contentLogs(order_by: {created_at: desc}) {
				created_at
				user {
					name
					first_name
				}
			}
			headers {
				content
				column
				image
				line
			}
			medias{
				media_id
			}
			scores{
				score
				jauge_id
			}
			type: gameType {
				name
				id
				description
				slug
			}
			questions {
				correction
				column
				line
				question_type_id
				id
				coordinates
				media_id
				text
				answers {
					text
					is_correct
					id
				}
			}
		}
	}
`

const GC_DELETE_GAME_BY_ID = gql`
	mutation DeleteGameOne($id: uuid!) {
		delete_game(where: { id: { _eq: $id } }) {
			affected_rows
		}
		delete_question(where: { game_id: { _eq: $id } }) {
			affected_rows
		}
		delete_table_header(where: { game_id: { _eq: $id } }) {
			affected_rows
		}
		delete_game_scores(where: { game_id: { _eq: $id } }){
			affected_rows
		}
		delete_game_media(where: { game_id: { _eq: $id } }){
			affected_rows
		}
	}
`

const GC_UPDATE_GAME_BY_ID = gql`
	mutation UpdateGameOne(
		$id: uuid!
		$instruction: String
		$name: String
		$title: String
		$gameType: uuid!
		$correction: String
		$media_id: uuid
		$questions: [question_insert_input!]!
		$headers: [table_header_insert_input!]!
		$hideAnswers: Boolean
		$prepareTime: Int
		$executeTime: Int
		$scores: [game_scores_insert_input!]!
		$medias: [game_media_insert_input!]!
	) {
		delete_game(where: { id: { _eq: $id } }) {
			affected_rows
		}
		delete_question(where: { game_id: { _eq: $id } }) {
			affected_rows
		}
		delete_table_header(where: { game_id: { _eq: $id } }) {
			affected_rows
		}
		delete_game_scores(where: { game_id: { _eq: $id } }) {
			affected_rows
		}
		delete_game_media(where: { game_id: { _eq: $id } }) {
			affected_rows
		}
		insert_game_one(
			object: {
				name: $name
				title: $title
				correction: $correction
				media_id: $media_id
				prepareTime: $prepareTime
				executeTime: $executeTime
				hideAnswers: $hideAnswers
				instruction: $instruction
				id: $id
				game_type_id: $gameType
				questions: { data: $questions }
				headers: { data: $headers }
				medias: { data: $medias }
				scores: { data: $scores }
			}
		) {
			id
		}
	}
`

const GC_CHECK_NAME_EXISTENCE = gql`
	query NameExist($name: String!) {
		game(where: { name: { _eq: $name } }) {
			id
		}
	}
`

export {
	GC_GET_GAME_TYPES,
	GC_GET_QUESTION_TYPES,
	GC_GET_GAME_LIST,
	GC_ADD_GAME_ONE,
	GC_GET_JAUGES,
	GC_GET_GAME_BY_ID,
	GC_UPDATE_GAME_BY_ID,
	GC_DELETE_GAME_BY_ID,
	GC_CHECK_NAME_EXISTENCE
}
