<template>
  <div class="flex flex-col h-full">
    <p v-if="!asset || !asset.subtitles_path">Loading...</p>
    <div v-else class="overflow-y-auto overflow-x-visible">
      <div
        class="my-6 pr-2"
        v-for="(line, index) in lines"
        :key="index"
      >
        <!-- Text -->
        <label :for="'text' + index" :class="{ 'text-red': (!line.text.trim() || line.text.split(/\r?\n\r?\n/).length > 1) }">Texte</label>

        <div class="flex mb-2">
          <textarea
          :id="'text' + index"
          class="flex-grow twn-input resize-none"
          :class="{ error: (!line.text.trim() || line.text.split(/\r?\n\r?\n/).length > 1) }"
          name="text"
          v-model="line.text"
          :disabled="loading"
          :rows="(line.text ? line.text.split(/\r?\n/).length : 1)" />

          <button tabindex="-1" class="text-center w-8 ml-2 hover:opacity-75" type="button" :disabled="loading" @click="onRemoveClick(index)">
            <img src="@/assets/images/DELETE.svg" class="d-inline h-5" />
          </button>
        </div>

        <!-- Start/End -->
        <label class="mr-2" :for="'start' + index" :class="{ 'text-red': line.start < (index > 0 ? lines[index - 1].end : 0)}">
          Début/Fin (en ms)
        </label>

        <div class="flex mb-2">
          <input :id="'start' + index" class="twn-input mr-2" type="number" name="start"
          :class="{ 'error': line.start < (index > 0 ? lines[index - 1].end : 0)}"
          :min="(index > 0 ? lines[index - 1].end : 0)" 
          :disabled="loading"
          v-model.number="line.start">

          <input :id="'end' + index" class="twn-input" type="number" name="end"
          :class="{ 'error': line.end <= line.start }"
          :min="line.start + 1" 
          :disabled="loading"
          v-model.number="line.end">
        </div>
      </div>
    </div>

    <button class="twn-button mb-2 mt-4" type="button" :disabled="loading || !asset" @click="onAddClick">Ajouter une ligne</button>
    <button class="twn-button mb-2" type="button" :disabled="loading || !asset" @click="onSaveClick">Enregister</button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

  export default {
    mixins: [ dispatchStoreRequest ],
    props: {
      assetId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loading: false,
      lines: []
    }),
    computed: {
      ...mapGetters("Assets", ["assetList"]),
      asset() {
        return this.assetList.find((asset) => (asset.id == this.assetId))
      },
    },
    watch: {
      asset: {
        async handler(asset, oldAsset) {
          if (!asset) {
            this.lines = []
            return
          }
          
          if (oldAsset && asset.id == oldAsset.id) {
            return
          }

          // Load subtitles data from asset SRT file
          this.lines = await this.dispatchStoreRequest('Assets/loadSubtitles', asset)
        },
        immediate: true
      }
    },
    methods: {
      onAddClick() {
        const start = (this.lines.length > 0 ? this.lines[this.lines.length - 1].end : 0)

        this.lines.push({ text: '', start, end: (start + 1000) })
      },
      onRemoveClick(index) {
        this.lines.splice(index, 1)
      },
      async onSaveClick() {
        if (this.loading || !this.asset)
          return

        this.loading = true

        // Update srt file
        await this.dispatchStoreRequest('Assets/saveSubtitles', {
          asset: this.asset,
          lines: this.lines
        })

        this.$emit('saved')
        
        this.loading = false
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>