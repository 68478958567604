import utils from "@/utils/trackingReportTemplate"

import statusLabels from "../userStatus"

export default {
	name: 'Global',
	content: [
		{ text: 'DONNEES D\'INSCRIPTION' },
		{},
		{ text: 'Nb d\'utilisateurs' },
		{
			text: 'Tous',
			textStyle: { font: { bold: true } },
			value: (data) => data.users.length,
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData) => groupData.length,
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{},
		{ text: 'Nb d\'utilisateurs ayant terminé' },
		{
			text: 'Tous',
			textStyle: { font: { bold: true } },
			value: (data) => utils.countMatch(data.users, (user) => {
				return (user.progression == 1)
			})
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData) => utils.countMatch(groupData, (user) => {
				return (user.progression == 1)
			}), 
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{ text: 'Nb d\'utilisateurs en cours' },
		{
			text: 'Tous',
			textStyle: { font: { bold: true } },
			value: (data) => utils.countMatch(data.users, (user) => {
				return (user.progression > 0 && user.progression < 1)
			})
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData) => utils.countMatch(groupData, (user) => {
				return (user.progression > 0 && user.progression < 1)
			}), 
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{ text: 'Nb de nouveau utilisateurs par semaine (en moyenne)' },
		{
			text: 'Tous',
			textStyle: { font: { bold: true } },
			value: (data) => utils.groupAverage(utils.groupByWeek(data.users, 'createdWeekId', data.firstCreatedUserDate), (groupByWeekData) => groupByWeekData.length),
			type: 'float'
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData, data) => utils.groupAverage(utils.groupByWeek(groupData, 'createdWeekId', data.firstCreatedUserDate), (groupByWeekData) => groupByWeekData.length), 
			type: 'float',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{},
		{ text: 'DONNEES DE CONNEXION' },
		{},
		{ text: 'Durée totale de connexion' },
		{
			text: 'Pour tous les utilisateurs',
			textStyle: { font: { bold: true } },
			value: (data) => utils.sumField(data.users, 'activeTimeData', 'sum'),
			type: 'duration'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.sumField(groupData, 'activeTimeData', 'sum'),
			type: 'duration',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{ text: 'Durée moyenne de connexion par utilisateur' },
		{
			text: 'Pour tous les utilisateurs',
			textStyle: { font: { bold: true } },
			value: (data) => utils.averageField(data.users, 'activeTimeData', 'average'),
			type: 'duration'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.averageField(groupData, 'activeTimeData', 'average'),
			type: 'duration',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{ text: 'Durée maximale de connexion' },
		{
			text: 'Pour tous les utilisateurs',
			textStyle: { font: { bold: true } },
			value: (data) => utils.maxField(data.users, 'activeTimeData', 'max'),
			type: 'duration'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.maxField(groupData, 'activeTimeData', 'max'),
			type: 'duration',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{ text: 'DONNEES PEDAGOGIQUES' },
		{},
		{ text: 'Taux d\'avancement moyen' },
		{
			text: 'Pour tous les utilisateurs',
			textStyle: { font: { bold: true } },
			value: (data) => utils.averageField(data.users, 'progression'),
			type: 'percentage'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.averageField(groupData, 'progression'),
			type: 'percentage',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
		{ text: 'Taux de réussite moyen aux quiz' },
		{
			text: 'Pour tous les utilisateurs',
			textStyle: { font: { bold: true } },
			value: (data) => {
				// Only count user with at least one success rate (ie. exclude user with no game result)
				const users = data.users.filter((user) => {
					return user.successRate > -1
				})

				return utils.averageField(users, 'successRate')
			},
			type: 'percentage'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => {
				// Only count user with at least one success rate (ie. exclude user with no game result)
				const users = groupData.filter((user) => {
					return user.successRate > -1
				})

				return utils.averageField(users, 'successRate')
			},
			type: 'percentage',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'status'
			}
		},
		{},
	]
}