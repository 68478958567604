import apollo from '@/apolloClient'

import {
    GC_GET_ORGANIZATION_LIST,
    GC_GET_ORGANIZATION_BY_ID,
    GC_ADD_ORGANIZATION_ONE,
    GC_UPDATE_ORGANIZATION_BY_ID,
    GC_DELETE_ORGANIZATION_BY_ID,
} from '@/graphql/organization'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {}
    },
    mutations: {
        SET_ORGANIZATION_LIST(state, organizations) {
            state.list = (organizations || []);
        },
        SET_ORGANIZATION(state, organization) {
            if (!organization || !organization.id)
                return

            state.items[organization.id] = organization
        },
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_ORGANIZATION_LIST })

            commit('SET_ORGANIZATION_LIST', response.data.organisations)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_ORGANIZATION_BY_ID,
                variables: { id }
            })

            if (!response.data.organisations_by_pk) {
                return
            }

            commit('SET_ORGANIZATION', response.data.organisations_by_pk)
        },
        async save({ dispatch }, data) {
            let response = null
            let result = {}

            const organization = {
                id: data.id,
                name: data.name,
                address: data.address,
                address2: data.address2,
                city: data.city,
                hml: data.hml,
                housing: data.housing,
                federation_id: data.federation_id,
                post_code: data.post_code,
                country: data.country,
                admin_id: data.admin_id
            }

            // Update or add the organization
            if (data.id) {
                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_ORGANIZATION_BY_ID,
                    variables: {
                        id: data.id,
                        organization
                    }
                })

                await dispatch('Logs/ContentLog', {
                    id: data.id,
                    action: 'content_update'
                }, { root: true })

                result.success = true
            } else {
                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_ORGANIZATION_ONE,
                    variables: {
                        organization
                    }
                })

                await dispatch('Logs/ContentLog', {
                    id: response.data.insert_organisations_one.id,
                    action: 'content_create'
                }, { root: true })

                result.id = response.data.insert_organisations_one.id
            }

            return result
        }
    },
    async delete({ dispatch }, id) {
        await dispatch('Logs/ContentLog', {
            id,
            action: 'content_delete'
        }, { root: true })

        // Delete from db
        const response = await apollo.mutate({
            mutation: GC_DELETE_ORGANIZATION_BY_ID,
            variables: { id }
        })

        return response
    },
}