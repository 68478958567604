<template>
	<div class="node" :class="[selected(), node.name, customClass] | kebab"
	:title="nodeTitle"
	@pointerup="pointerUp">
		<div class="cross-container text-right">
			<div class="cross cursor-pointer" @pointerdown.stop="removeNode">
				<img src="@/assets/images/NODE_CROSS.svg">
			</div>
		</div>

		<div class="content bg-white">
			<div class="title">{{title}}</div>
			<div class="description">{{description}}</div>

			<!-- Inputs-->
			<div class="input" v-for="input in inputs()" :key="input.key" :class="{ connected: input.hasConnection() }">
				<Socket v-socket:input="input" type="input" :socket="input.socket"></Socket>
				<div class="input-title" v-show="!input.showControl() && input.name">{{input.name}}</div>
				<div class="input-control" v-show="input.showControl()" v-control="input.control"></div>
			</div>

			<!-- Controls-->
			<div class="control" v-for="control in controls()" :key="node.id + control.key" v-control="control" @content-update="controlContentUpdate(control)"></div>

			<!-- Outputs-->
			<div class="outputs" v-for="output in outputs()" :key="output.key" :class="{ connected: output.hasConnection(), highlighted: output.highlighted }">
				<div v-if="output.isDynamic" class="output-title output-dynamic">
					<div v-if="output.isEditing">
						<input
						type="text"
						v-model="output.newName"
						v-focus
						@pointerdown.stop
						@keyup.enter="saveDynamicOutputName(output)"
						@keyup.esc="cancelDynamicOutputEditing(output)"
						>
						<span class="button-save-edit" @pointerdown.stop="saveDynamicOutputName(output)">
							<img src="@/assets/images/CONFIRM.svg">
						</span>
						<span class="button-cancel-edit" @pointerdown.stop="cancelDynamicOutputEditing(output)">
							<img src="@/assets/images/CANCEL.svg">
						</span>
					</div>
					<div v-else>
						<span class="dynamic-output-name" @pointerdown.stop="editDynamicOutput(output)">{{output.name}}</span>
						<span class="button-edit" @pointerdown.stop="editDynamicOutput(output)">
							<img src="@/assets/images/EDIT.svg">
						</span>
						<span class="button-delete" @pointerdown.stop="deleteDynamicOutput(output)">
							<img src="@/assets/images/DELETE.svg">
						</span>
					</div>
				</div>
				<div v-else class="output-title">{{output.name}}</div>
				<Socket v-socket:output="output" type="output" :socket="output.socket"></Socket>
			</div>

			<div class="button" v-if="canAddDynamicOutput">
				<button :disabled="isEditing" @click="addOutput">
					<img src="@/assets/images/ADD_BUTTON.svg">
					Ajouter {{ dynamicOutputName[node.dynamicOutputType] }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	// Extends default Node component to be able to apply a custom style and add description for each node type
	import VueRender from 'rete-vue-render-plugin';
	import Rete from 'rete';
	import CustomSocket from './CustomSocket.vue';

	import * as Socket from '../sockets';

	import store from "../../store"

	export default {
		extends: VueRender.Node,
		mixins: [VueRender.mixin],
		components: {
			Socket: CustomSocket
		},
		directives: {
			focus: {
				inserted: function (el) {
					// setTimeout instead of nextTick because nextTick seems to fire too early(... & ???)
					setTimeout(() => {
						el.focus()
					}, 100)
				},
			}
		},
		props: {
			title: String,
			description: String,
			controlContentUpdated: Function,
		},
		data() {
			return {
				selectedOutput: null,
				pendingOutput: null,
				isEditing: false,
				dynamicOutputCount: 0,
				dynamicOutputName: {
					button: 'un bouton',
					signal: 'un déclencheur',
				},
			};
		},
		computed: {
			customClass() {
				if (!this.node.data.game)
					return ''

				// Handle custom class for game node
				const game = (store.getters['Games/listById'] ? store.getters['Games/listById'][this.node.data.game] : null)
				const type = (game && game.game_type_id ? store.getters['Games/typeById'][game.game_type_id] : null)

				return (type ? type.slug : '')
			},
			nodeTitle() {
				if (!this.node || !this.node.data)
					return this.title

				return (this.node.data.title ? this.node.data.title : this.title)
			},
			canAddDynamicOutput() {
				return (this.node.dynamicOutputType && (!this.node.dynamicOutputLimit || this.dynamicOutputCount < this.node.dynamicOutputLimit))
			},
		},
		created() {
			//todo move to Scenecomponent.js ? (via custom triggers data ? see: interactivecomponent.js)
			if (this.node.dynamicOutputType && this.node.data[this.node.dynamicOutputType]) {
				const keys = Object.keys(this.node.data[this.node.dynamicOutputType])
				var outputs = {}

				for (var i = 0; i < keys.length; i++) {
					this.dynamicOutputCount += 1

					let keyCount = this.dynamicOutputCount
					let outputKey = this.node.dynamicOutputType + '|' + keyCount

					while (outputs[outputKey]) {
						keyCount += 1
						outputKey = this.node.dynamicOutputType + '|' + keyCount
					}

					outputs[outputKey] = this.node.data[this.node.dynamicOutputType][keys[i]]

					const output = new Rete.Output(outputKey, (outputs[outputKey].name || 'Aucun signal'), Socket.trigger, false)
					output.isDynamic = true
					output.highlighted = false

					this.node.addOutput(output)
				}
			}
		},
		mounted() {
			this.$el.addEventListener('set-selected', this.setDynamicOutputSelected)
			this.$el.addEventListener('set-item', this.setDynamicOutputItem)
		},
		beforeDestroy() {
			this.$el.removeEventListener('set-selected', this.setDynamicOutputSelected)
			this.$el.removeEventListener('set-item', this.setDynamicOutputItem)
		},
		methods: {
			pointerUp(event) {
				// Handle node 'selection' for the custom 'connectiondrop' event
				if (!event.target || !event.target.className || event.target.className.indexOf('socket ') > -1) {
					this.editor.custom_selected_node = null
				} else {
					this.editor.custom_selected_node = this.node
				}
			},
			updateNodeView(force = false) {
				if (force)
					this.$forceUpdate()

				// Update display of all connections of this node
				this.$nextTick(() => {
					this.editor.view.updateConnections({node: this.node})
				})
			},
			async controlContentUpdate(control) {
				let force = false

				// Call custom method if specified by the node type, this method can be used to update the node inputs, outputs, ... before updating the node view
				if (this.controlContentUpdated) {
					force = await this.controlContentUpdated(control, this.node, this.editor)
				}

				// Update node view
				this.updateNodeView(force)
			},
			addOutput() {
				if (!this.node.data[this.node.dynamicOutputType]) {
					this.node.data[this.node.dynamicOutputType] = {}
				}

				this.dynamicOutputCount = Object.keys(this.node.data[this.node.dynamicOutputType]).length

				if (this.isEditing || (this.node.dynamicOutputLimit && this.dynamicOutputCount >= this.node.dynamicOutputLimit))
					return

				this.isEditing = true

				this.dynamicOutputCount += 1

				let keyCount = this.dynamicOutputCount
				let outputKey = this.node.dynamicOutputType + '|' + keyCount

				while (this.node.data[this.node.dynamicOutputType][outputKey]) {
					keyCount += 1
					outputKey = this.node.dynamicOutputType + '|' + keyCount
				}

				// Create new output initial data
				var outputData = {}

				switch (this.node.dynamicOutputType) {
					case 'button':
						outputData.name = 'Bouton #' + keyCount
					break

					case 'signal':
						outputData.signal_id = null
					break
				}

				this.node.data[this.node.dynamicOutputType][outputKey] = outputData

				const output = new Rete.Output(outputKey, (outputData.name || 'Aucun signal'), Socket.trigger, false)
				output.isDynamic = true
				output.highlighted = false

				this.node.addOutput(output)

				this.isEditing = false

				this.updateNodeView(true)
			},
			editDynamicOutput(output) {
				if (this.node.dynamicOutputType == 'button') {
					this.editDynamicOutputName(output)
				} else {
					this.editDynamicOutputItem(output)
				}
			},
			editDynamicOutputName(output) {
				output.newName = output.name

				this.updateDynamicOutputEditing(output, true)
			},
			saveDynamicOutputName(output) {
				output.name = output.newName

				this.node.data[this.node.dynamicOutputType][output.key].name = output.name

				this.updateDynamicOutputEditing(output, false)
			},
			editDynamicOutputItem(output) {
				this.pendingOutput = output

				// Use custom event to make them available outside of the current Vue.js context
				this.$el.dispatchEvent(new CustomEvent('select-item', { bubbles: true, composed: true, detail: this.$el }))
				this.$el.dispatchEvent(new CustomEvent('select-item-' + this.node.dynamicOutputType, { bubbles: true, composed: true }))
			},
			setDynamicOutputItem(e) {
				if (!this.selectedOutput)
					return

				const itemJSON = (e.dataTransfer ? e.dataTransfer.getData("application/json") : e.detail)

				if (itemJSON) {
					const item = (itemJSON ? JSON.parse(itemJSON) : null)       

					if (item && item.id) {
						// Update output name and data
						this.selectedOutput.name = (item.name || 'Aucun signal')
						
						this.node.data[this.node.dynamicOutputType][this.selectedOutput.key].signal_id = item.id
					}

					// Use custom event to make them available outside of the current Vue.js context
					this.$el.dispatchEvent(new CustomEvent('item-selected', { bubbles: true, composed: true }))
				}
			},
			cancelDynamicOutputEditing(output) {
				this.updateDynamicOutputEditing(output, false)
			},
			updateDynamicOutputEditing(output, startEditing) {
				if (this.isEditing)
					return

				this.isEditing = true

				output.isEditing = startEditing

				this.isEditing = false

				this.updateNodeView(true)
			},
			updateDynamicOutputHighlighted(output, highlighted) {
				if (this.isEditing)
					return

				this.isEditing = true

				output.highlighted = highlighted

				this.isEditing = false

				this.updateNodeView(true)
			},
			deleteDynamicOutput(output) {
				if (this.isEditing)
					return

				this.isEditing = true

				// Remove outputs connections (must be done via editor.removeConnection because it's not done in removeOutput...)
				output.connections.slice().map(this.editor.removeConnection.bind(this.editor));

				// Remove output
				this.node.removeOutput(output)

				delete this.node.data[this.node.dynamicOutputType][output.key]

				this.dynamicOutputCount = Object.keys(this.node.data[this.node.dynamicOutputType]).length

				this.isEditing = false

				this.updateNodeView(true)
			},
			removeNode() {
				this.editor.removeNode(this.node)
			},
			setDynamicOutputSelected(e) {
				if (e.detail !== false && e.detail !== true)
					return

				if ((e.detail && !this.pendingOutput) || (!e.detail && !this.selectedOutput))
					return

				// Start item editing
				if (e.detail) {
					this.selectedOutput = this.pendingOutput
					this.pendingOutput = null
				}

				this.selectedOutput.highlighted = e.detail

				// Cancel item editing
				if (!e.detail) {
					this.selectedOutput = null
				}

				this.updateNodeView(true)
			},
		},
	}
</script>

<style lang="scss" scoped>
$node-color: #FFF;
$node-color-selected: #ffd92c;
$group-color: rgba(15,80,255,0.2);
$group-handler-size: 40px;
$group-handler-offset: -10px;
$socket-size: 24px;
$socket-margin: 6px;
$socket-color: #dc9799;
$node-width: 320px;

.node {
	background: $node-color;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	cursor: grab;
	min-width: $node-width;
	height: auto;
	box-sizing: content-box;
	position: relative;
	user-select: none;
	padding: 10px 0 22px 0;

	&:hover {;
		background-color: lighten($node-color,4%);
	}
	
	&:active {
		cursor: grabbing;
	}

	.title {
		padding: 0 22px;
		margin-bottom: 4px;
		@apply text-xs uppercase font-principal-bold;
	}

	.description {
		color: $textLight;
		padding: 0 22px;
		@apply text-xs;
	}

	.outputs {
		text-align: left;
		border-radius: 5px;
		border: solid 1px #e1e1e1;
		padding: 0 11px;
		margin-left: 22px;
		margin-top: 14px;
		margin-bottom: 14px;
		margin-right: - $socket-size;
		background: white;

		&.highlighted {
			@apply text-red;
		}
	}

	.output-title {
		width: calc(100% - #{$socket-size + 2*$socket-margin});
	}

	.output-dynamic {
		white-space: nowrap;
		
		span {
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			margin-bottom: 4px;

			img {
				display: inline-block;
				vertical-align: middle;
				height: 16px;
				color: red;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		.dynamic-output-name, .dynamic-output-item {
			margin: 0;
			height: 26px;
			line-height: 26px;
		}

		input {
			padding: 0 10px;
			border: 1px solid #E1E1E1;
			border-radius: 0.275rem;
		}

		.dynamic-output-name, .dynamic-output-item, .button-edit, .button-delete {
			cursor: pointer;
		}

		.button-save-edit, .button-cancel-edit {
			cursor: pointer;
			margin-left: 8px;
			opacity: 0.6;

			&:hover {
				opacity: 1;
			}

			img {
				height: 12px;
			}
		}
	}

	.input {
		.socket {
			position: absolute;
			top: calc(50% - #{$socket-size/2});
			left: 0;
		}
	}

	.input-title, .output-title {
		@apply text-sm;
		vertical-align: middle;
		display: inline-block;
		margin: $socket-margin;
		line-height: $socket-size;
	}

	.input-control {
		z-index: 1;
		width: calc(100% - #{$socket-size + 2*$socket-margin});
		vertical-align: middle;
		display: inline-block;
	}

	.control, .button {
		@apply text-sm;
		color: $textLight;
		padding: 0 22px;
	}

	.control {
		margin-top: 14px;
		margin-bottom: 14px;
	}

	.button {
		img {
			display: inline-block;
			margin-right: 14px;
		}

		button {
			height: 26px;
		}
	}

	.cross-container {
		line-height: 14px;
	}

	.cross {
		display: inline-block;
		margin-right: 10px;
	}

	.connected {
		.socket {
			background: $socket-color;
		}
	}
}
</style>
