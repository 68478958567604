import statusLabels from "../userStatus"

const oneHour = (1 * 60 * 60 * 1000)

export default {
	list: 'users',
	itemName: 'user',
	name: (user) => {
		return user.first_name + ' ' + user.name
	},
	content: [
		{
			value: (data) => (data.user.first_name + ' ' + data.user.name)
		},
		{},
		{ text: 'Calendrier stagiaire' },
		{
			table: (data) => data.course.sequences,
			headers: ['Séquence', 'Statut', 'Date de réalisation du dernier scénario de la séquence', 'Durée (sur la séquence)'],
			row: (sequence, data) => {
				let statut = 'A programmer'
				let doneDate = { value: '-' }
				let duration = { value: '-' }
				
				if (data.user.logsData.sequencesDone[sequence.id]) {
					statut = 'Réalisé'
					doneDate = {
						value: data.user.logsData.sequencesDone[sequence.id].getTime(),
						type: 'date',
					}
					duration = {
						value: data.user.logsData.sumPerSequence[sequence.id] || 0,
						type: 'duration',
					}
				} else if (data.user.latestSequenceDates[sequence.id]) {
					statut = 'Programmée'
				}

				return [
					{ value: sequence.name },
					{ value: statut },
					doneDate,
					duration,
				]
			}
		},
		{},
		{
			table: (data) => data.user.activeTimeData.times,
			headers: ['Date de la session', 'Durée totale de connexion'],
			row: (time) => {
				return [
					{ value: time.start, type: 'date' },
					{ value: time.duration, type: 'duration' },
				]
			}
		},
		{},
		{ text: 'Réalisation des scénario par niveau' },
		{
			table: (data) => {
				return data.course.levels.reduce((list, level) => {
					list.push({ level })

					data.course.scenariosPerLevel[level.id].forEach((scenario) => {
						list.push({ scenario })
					})
					
					return list
				}, [])
			},
			headers: ['Scénario', 'Nom', 'Statut', 'Temps total (toutes tentatives confondues)', 'Tentatives'],
			row: (item, data) => {
				// Handle level separator item
				if (item.level)
					return [ { value: 'Niveau ' + (item.level.order + 1) } ]

				// Handle scenario item
				if (!item.scenario)
					return []

				// Get total time spent on this scenario
				let timeSpent = null

				const activeTimes = data.user.activeTimesPerScenario[item.scenario.id]

				if (data.user.scenariosDone[item.scenario.id] && activeTimes) {
					activeTimes.forEach((time) => {
						const deltaTime = ((time.end || time.update) - time.update)

						// Safety check for inactivity
						if (deltaTime <= oneHour) {
							timeSpent += ((time.end || time.update) - time.start)
						}
					})
				}

				return [
					{ value: item.scenario.name },
					{ value: item.scenario.title },
					{ value: (data.user.scenariosDone[item.scenario.id] ? 'Réalisé' : 'Non réalisé') },
					{ value: (timeSpent || 0), type: 'duration' },
					{ value: (activeTimes ? activeTimes.length : 0) },
				]
			}
		},
	]
}