<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="1163" :height="svgHeight" :viewBox="`0 0 1163 ${svgHeight}`">
		<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="22">
			<tspan x="581.5" y="31" text-anchor="middle">{{ [user.firstName, user.name].join(' ') }}</tspan>
		</text>
		<g
			v-for="(group, id, groupIndex) in trackingData.user.groupsById"
			:key="group.id"
			fill="none"
			fill-rule="evenodd"
			:transform="`translate(56 ${56 + ((groupBlockSizes[groupIndex - 1] && groupBlockSizes[groupIndex - 1].contentBlockHeight) || 0) + (40 * groupIndex)})`"
		>
			<rect width="1051" :height="groupBlockSizes[groupIndex].contentBlockHeight" fill="#FBFBFB" fill-rule="nonzero" rx="16"/>
			<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
				<tspan x="525.5" y="20" text-anchor="middle">
					{{ `${group.title} - ${pdfTemplate.formatValue(group.start_date, 'date')} - ${pdfTemplate.formatValue(group.end_date, 'date')}` }}
				</tspan>
			</text>
			<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="22">
				<tspan x="30" y="50.632">Connexions</tspan>
			</text>
			<g fill-rule="nonzero" transform="translate(30 97)">
				<rect width="233" height="91" fill="#006FB7" rx="16"/>
				<g transform="translate(209 10)">
					<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
					<text fill="#FFF" font-family="Helvetica" font-size="9">
						<tspan x="4.75" y="10.804">?</tspan>
					</text>
				</g>
				<text fill="#FFF" font-family="Helvetica" font-size="30">
					<tspan x="116.5" y="35.18" text-anchor="middle">
						{{ pdfTemplate.formatValue(group.progressRate, 'percentage') }}
					</tspan>
				</text>
				<text fill="#FFF" font-family="Helvetica" font-size="14">
					<tspan x="45.819" y="59.084">COMPLÉTION DE LA</tspan>
				</text>
				<text fill="#FFF" font-family="Helvetica" font-size="14">
					<tspan x="73.264" y="75.084">FORMATION</tspan>
				</text>
			</g>
			<g fill-rule="nonzero" transform="translate(282 97)">
				<rect width="233" height="91" x=".75" fill="#FFF" rx="16"/>
				<text fill="#555" font-family="Helvetica" font-size="30">
					<tspan x="116.5" y="35.18" text-anchor="middle">
						{{ pdfTemplate.formatValue(group.activeTimeData.totalTime, 'time') }}
					</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="52.772" y="59.084">DURÉE TOTALE DE</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="72.072" y="75.084">CONNEXION</tspan>
				</text>
			</g>
			<g fill-rule="nonzero" transform="translate(535 97)">
				<rect width="233" height="91" x=".5" fill="#FFF" rx="16"/>
				<g transform="translate(209.5 10)">
					<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
					<text fill="#FFF" font-family="Helvetica" font-size="9">
						<tspan x="4.75" y="10.804">?</tspan>
					</text>
				</g>
				<text fill="#555" font-family="Helvetica" font-size="30">
					<tspan x="116.5" y="35.18" text-anchor="middle">
						{{ pdfTemplate.formatValue(group.activeTimeData.averageScenarioTime, 'time') }}
					</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="8" y="59">TEMPS PASSÉ POUR COMPLÉTER</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="34" y="75">UN SCÉNARIO (MOYENNE)</tspan>
				</text>
			</g>
			<g fill-rule="nonzero" transform="translate(788 97)">
				<rect width="233" height="91" x=".25" fill="#FFF" rx="16"/>
				<text fill="#555" font-family="Helvetica" font-size="30">
					<tspan x="116.5" y="43.18" text-anchor="middle">
						{{ pdfTemplate.formatValue(group.activeTimeData.remainingTime, 'time') }}
					</tspan>
				</text>
				<text fill="#B7B7B7" font-family="Helvetica" font-size="14">
					<tspan x="61.128" y="67.084">DURÉE ESTIMÉE</tspan>
				</text>
				<g transform="translate(209.25 10)">
					<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
					<text fill="#FFF" font-family="Helvetica" font-size="9">
						<tspan x="4.75" y="10.804">?</tspan>
					</text>
				</g>
			</g>
			<g transform="translate(910 24)">
				<polygon fill="#404040" points="94 71 103 80 85 80" transform="matrix(1 0 0 -1 0 151)"/>
				<rect width="187" height="72" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
					<tspan x="33.799" y="19">Estimation de la durée </tspan>
					<tspan x="24.128" y="33">nécessaire à la réalisation </tspan>
					<tspan x="16.78" y="47">des séquences à date (fiable </tspan>
					<tspan x="24.45" y="61">a la date de fin de niveau)</tspan>
				</text>
			</g>
			<g transform="translate(658 38)">
				<polygon fill="#404040" points="93 57 102 66 84 66" transform="matrix(1 0 0 -1 0 123)"/>
				<rect width="186" height="58" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
					<tspan x="24.299" y="19">Pour la première tentative </tspan>
                    <tspan x="32.625" y="33">aboutie concernant les</tspan>
					<tspan x="62.991" y="47">MES et VT.</tspan>
				</text>
			</g>
			<g transform="translate(154 38)">
				<polygon fill="#404040" points="92 57 101 66 83 66" transform="matrix(1 0 0 -1 0 123)"/>
				<rect width="183" height="58" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
					<tspan x="34.474" y="19">Nombre de scénarios </tspan>
					<tspan x="24.132" y="33">réalisés / nombre total de </tspan>
					<tspan x="65.821" y="47">scénarios</tspan>
				</text>
			</g>
			<g transform="translate(30 228)">
				<rect width="991" :height="groupBlockSizes[groupIndex].categoryScoreBlockHeight" fill="#FFF" fill-rule="nonzero" rx="16"/>
				<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="22">
					<tspan x="30" y="47.63">Réussite de l’apprenant</tspan>
				</text>
				<g transform="translate(967 10)">
					<path fill="#555" d="M0,7 C0,3.134 3.13,0 7,0 C10.87,0 14,3.134 14,7 C14,10.866 10.87,14 7,14 C3.13,14 0,10.866 0,7 Z"/>
					<text fill="#FFF" font-family="Helvetica" font-size="9">
						<tspan x="4.75" y="10.804">?</tspan>
					</text>
				</g>

				<g
					v-for="(category, index) in group.scoresByCategory"
					:key="category.id"
				>
					<!-- Name -->
					<text fill="#555" fill-rule="nonzero" font-family="Helvetica" font-size="14">
						<tspan
							:x="(index % 2 === 0 ? 30 : 515.5)"
							:y="85.58 + (52 * Math.floor(index / 2))"
						>{{ category.name }}</tspan>
					</text>

					<!-- Bar -->
					<rect
						width="447.5"
						height="22"
						:x="(index % 2 === 0 ? 29 : 514.5)"
						:y="95 + (52 * Math.floor(index / 2))"
						fill="#EEE"
						fill-rule="nonzero"
						rx="11"
					/>

					<path
						v-if="category.score > 0 && category.score < 1"
						fill="#6FB700"
						fill-rule="nonzero"
						:d="getScoreBarPath(category.score, index)"
					/>
					<path v-else-if="category.score > 0" fill="#6FB700" fill-rule="nonzero" d="M30,158 C30,152.48 34.4772,148 40,148 L465.5,148 C471.023,148 475.5,152.48 475.5,158 C475.5,163.52 471.023,168 465.5,168 L40,168 C34.4772,168 30,163.52 30,158 Z"/>

					<rect
						width="447.5"
						height="22"
						:x="(index % 2 === 0 ? 29 : 514.5)"
						:y="95 + (52 * Math.floor(index / 2))"
						stroke="#555"
						stroke-width="2"
						rx="11"
					/>

					<!-- Value -->
					<text fill="#FFF" fill-rule="nonzero" font-family="OpenSans-Bold, Open Sans" font-size="12" font-weight="bold">
						<tspan
							:x="(index % 2 === 0 ? 0 : 490) + (category.score * 450)"
							:y="110.66 + (52 * Math.floor(index / 2))"
						>
							{{ (category.score * 100).toFixed(0) + ' %' }}
						</tspan>
					</text>
				</g>
			</g>

			<g transform="translate(910 183)">
				<polygon fill="#404040" points="94 43 103 52 85 52" transform="matrix(1 0 0 -1 0 95)"/>
				<rect width="187" height="44" fill="#404040" fill-rule="nonzero" rx="8"/>
				<text fill="#FFF" fill-rule="nonzero" font-family="Helvetica" font-size="12">
                    <tspan x="20.782" y="19">% de bonnes réponses aux </tspan>
                    <tspan x="40.813" y="33">mini-jeux par thème</tspan>
                </text>
            </g>

			<g fill-rule="nonzero" :transform="`translate(30 ${258 + groupBlockSizes[groupIndex].categoryScoreBlockHeight})`">
				<rect width="991" :height="groupBlockSizes[groupIndex].scenarioScoreBlockHeight" fill="#FFF" rx="16"/>
				<text fill="#555" font-family="Helvetica" font-size="22">
					<tspan x="30" y="47.63">Météo des épisodes</tspan>
				</text>

				<g
					v-for="(scenario, index) in group.scoresByScenario"
					:key="scenario.id"
				>
					<text fill="#555" font-family="Helvetica" font-size="14">
						<tspan
							:x="(index % 2 === 0 ? 30 : 515.5)"
							:y="86 + (Math.floor(index / 2) * 28)"
						>
							{{ scenario.name }}
						</tspan>
					</text>
					<g :transform="`translate(${(index % 2 === 0 ? 460 : 945)} ${72 + (Math.floor(index / 2) * 28)})`">
						<g v-if="scenario.score < 0.40">
							<path d="M3.9375 14C1.76288 14 0 12.5076 0 10.6667C0 8.90465 1.61495 7.46195 3.66016 7.34147C4.3528 5.39524 6.23018 4 8.4375 4C10.6694 4 12.5639 5.42645 13.2375 7.40662C13.5036 7.3586 13.7795 7.33333 14.0625 7.33333C16.2371 7.33333 18 8.82572 18 10.6667C18 12.5076 16.2371 14 14.0625 14H3.9375Z" fill="#555555"/><path d="M6 14L10 6L9.5 13H12L8 20L8.5 14H6Z" fill="#F2994A"/>
						</g>
						<g v-else-if="scenario.score < 0.80">
							<circle cx="5.00005" cy="6.00005" r="2.22222" fill="#F2994A"/><rect x="7.77783" y="5.44434" width="2.22222" height="1.11111" rx="0.555556" fill="#F2994A"/><rect y="5.44434" width="2.22222" height="1.11111" rx="0.555556" fill="#F2994A"/><rect x="4.44446" y="3.22217" width="2.22222" height="1.11111" rx="0.555556" transform="rotate(-90 4.44446 3.22217)" fill="#F2994A"/><rect x="4.44446" y="11" width="2.22222" height="1.11111" rx="0.555556" transform="rotate(-90 4.44446 11)" fill="#F2994A"/><rect x="7.35706" y="7.57129" width="2.22222" height="1.11111" rx="0.555556" transform="rotate(45 7.35706 7.57129)" fill="#F2994A"/><rect x="1.85742" y="2.07153" width="2.22222" height="1.11111" rx="0.555556" transform="rotate(45 1.85742 2.07153)" fill="#F2994A"/><rect x="6.57129" y="3.64307" width="2.22222" height="1.11111" rx="0.555556" transform="rotate(-45 6.57129 3.64307)" fill="#F2994A"/><rect x="1.07166" y="9.14258" width="2.22222" height="1.11111" rx="0.555556" transform="rotate(-45 1.07166 9.14258)" fill="#F2994A"/><path d="M3.9375 14C1.76288 14 0 12.5076 0 10.6667C0 8.90465 1.61495 7.46195 3.66016 7.34147C4.3528 5.39524 6.23018 4 8.4375 4C10.6694 4 12.5639 5.42645 13.2375 7.40662C13.5036 7.3586 13.7795 7.33333 14.0625 7.33333C16.2371 7.33333 18 8.82572 18 10.6667C18 12.5076 16.2371 14 14.0625 14H3.9375Z" fill="#808080"/>
						</g>
						<g v-else>
							<circle cx="9" cy="9" r="4" fill="#F2994A"/><rect x="14" y="8" width="4" height="2" rx="1" fill="#F2994A"/><rect y="8" width="4" height="2" rx="1" fill="#F2994A"/><rect x="8" y="4" width="4" height="2" rx="1" transform="rotate(-90 8 4)" fill="#F2994A"/><rect x="8" y="18" width="4" height="2" rx="1" transform="rotate(-90 8 18)" fill="#F2994A"/><rect x="13.2427" y="11.8284" width="4" height="2" rx="1" transform="rotate(45 13.2427 11.8284)" fill="#F2994A"/><rect x="3.34314" y="1.92896" width="4" height="2" rx="1" transform="rotate(45 3.34314 1.92896)" fill="#F2994A"/><rect x="11.8284" y="4.75732" width="4" height="2" rx="1" transform="rotate(-45 11.8284 4.75732)" fill="#F2994A"/><rect x="1.92896" y="14.6567" width="4" height="2" rx="1" transform="rotate(-45 1.92896 14.6567)" fill="#F2994A"/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
	import pdfTemplate from '@/utils/pdfTemplate'

	export default {
		name: 'GroupPDFTemplate',
		props: {
			user: {
				type: Object,
				required: true,
			},
			trackingData: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				pdfTemplate,
			}
		},
		computed: {
			groupBlockSizes() {
				const ids = Object.keys(this.trackingData.user.groupsById)
				
				return ids.map((id) => {
					const group = this.trackingData.user.groupsById[id]
					
					const categoryScoreBlockHeight = (100 + (52 * Math.ceil(group.scoresByCategory.length / 2)))
					const scenarioScoreBlockHeight = (86 + (28 * Math.ceil(group.scoresByScenario.length / 2)))

					return {
						categoryScoreBlockHeight,
						scenarioScoreBlockHeight,
						contentBlockHeight: (228 + categoryScoreBlockHeight + scenarioScoreBlockHeight + 60),
					}
				})
			},
			svgHeight() {
				const ids = Object.keys(this.trackingData.user.groupsById)
				
				return ids.reduce((height, _id, index) => {
					return (height + this.groupBlockSizes[index].contentBlockHeight + (40 * index))
				}, 112)
			},
		},
		methods: {
			getScoreBarPath(score, index) {
				const deltaX = (index % 2 === 0 ? 30 : 515.5)
				const deltaY = (96 + (52 * Math.floor(index / 2)))

				return `
					M${deltaX},${10 + deltaY}
					C${deltaX},${4 + deltaY} ${deltaX + 4},${deltaY} ${deltaX + 10},${deltaY}
					L${deltaX + (score * 450)},${deltaY}
					L${deltaX + (score * 450)},${20 + deltaY}
					L${deltaX + 10},${20 + deltaY}
					C${deltaX + 4},${20 + deltaY} ${deltaX},${15 + deltaY} ${deltaX},${10 + deltaY} Z
				`
			},
		},
	}
</script>