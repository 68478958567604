<template>
  <div class="text-control flex">
    <!-- Label -->
    <span v-if="label" class="text-control-label">{{label}}</span>

    <!-- Input -->
    <input v-if="multiline <= 1" class="text-control-input twn-input" type="text" :readonly="readonly" :value="value" @input="change($event)"/>

    <!-- Multiline texte area -->
    <textarea v-else class="text-control-input twn-input" :rows="multiline" :readonly="readonly" :value="value" @input="change($event)"></textarea>
  </div>
</template>


<script>
export default {
  props: {
    ikey: String,
    label: String,// todo:improve
    readonly: Boolean,
    multiline: Number,
    getData: Function,
    putData: Function,
  },
  data() {
    return {
      value: ''
    }
  },
  methods: {
    change(e) {
      this.value = e.target.value;
      this.update();
    },
    update() {
      if (this.ikey) this.putData(this.ikey, this.value);
    }
  },
  mounted() {
    this.value = this.getData(this.ikey);
  }
}
</script>

<style lang="scss">
.text-control-input.twn-input {
  @apply text-principal-gray flex-grow;
}

input.text-control-input.twn-input {
  height: 1.6rem;
}
textarea.text-control-input.twn-input {
  resize: none;
}

.text-control-label {
  color: $textLight;
  padding-right: 0.75rem;
  line-height: 1.6rem;
  vertical-align: middle;
}
</style>

