import utils from "@/utils/trackingReportTemplate"

import { sequenceState, sequencesState, userLatestSequenceDates } from "../common/commonSequences"

const oneHour = (1 * 60 * 60 * 1000) // in 
const fiveMinutes = (5 * 60 * 1000)
const twoAndHalfHour = (150 * 60 * 1000)

function groupScenarios(group) {
	return group?.course?.modules?.reduce((list, module) => {
		list = list.concat(module.scenarios.map((moduleScenario) => moduleScenario.scenario))

		module.sequences.forEach((sequence) => {
			list = list.concat(sequence.scenarios.map((sequenceScenario) => sequenceScenario.scenario))
		})

		return list
	}, []) || []
}

function groupProgression(user, group) {
	// Get number of scenarios done and available for this user
	const progression = {
		done: 0,
		available: 0
	}
	
	// Get scenarios available in this group
	const scenarios = groupScenarios(group)
	progression.available = scenarios.length

	// Count scenario done in this group
	scenarios.forEach((scenario) => {
		if (user.logsData.scenariosDonePerGroup?.[group.id]?.[scenario.id]) {
			progression.done += 1
		}
	})

	// Return progression percentage
	if (progression.available <= 0)
		return 0

	return (progression.done / progression.available)
}

function defaultGroupIdForSession(session, user) {
	for (let i = 0; i < user.promotions.length; i += 1) {
		const group = user.promotions[i].promotion

		const startTime = (new Date(group.start_date)).getTime()
		const endTime = (new Date(group.end_date)).getTime()
		
		if (session.start > startTime && session.start < endTime) {
			return group.id
		}
	}

	return null
}

function groupAverageScenarioTime(user, group, scenarios) {
	// Create hash map to check if a scenario is a main one (ie. one of the main types and not used as a preset)
	const mainScenarioTypeSlug = ['scenario', 'edl']
	const isPresetScenario = scenarios.reduce((dict, scenario) => {
		if (scenario.preset_scenario_id) {
			dict[scenario.preset_scenario_id] = true
		}
		return dict
	}, {})
	const isMainScenario = scenarios.reduce((dict, scenario) => {
		// Check type and if it's not used as preset
		if (mainScenarioTypeSlug.indexOf(scenario.type.slug) > -1 && !isPresetScenario[scenario.id]) {
			dict[scenario.id] = true
		}

		return dict
	}, {})

	// Average scenario time
	const scenarioIds = Object.keys(user.logsData.perGroupScenario?.[group.id] || {})
	const scenarioTimeData = scenarioIds.reduce((data, scenarioId) => {
		// Check if scenario should be included
		if (!isMainScenario[scenarioId]) {
			return data
		}

		const scenarioTimes = user.logsData.perGroupScenario[group.id][scenarioId]

		// Add max valid time data to the average (more or less the first time they tried)
		const max = scenarioTimes.reduce((max, time) => {
			if (time.end) {
				const deltaTime = (time.end - time.update)

				if (deltaTime <= oneHour) {
					const duration = (time.end - time.start)

					if (duration > max) {
						max = duration
					}
				}
			}
			
			return max
		}, 0)

		if (max > 0) {
			data.sum += max
			data.count += 1
		}

		return data
	}, {
		sum: 0,
		count: 0,
	})

	return (scenarioTimeData.count > 0 ? (scenarioTimeData.sum / scenarioTimeData.count) : null)
}

function groupCourseSequences(group) {
	return group?.course?.modules?.reduce((list, module) => {
		list = list.concat(module.sequences)

		return list
	}, []) || []
}

function groupSequences(group) {
	return group.sequences.map((groupSequence) => {
		return {
			id: groupSequence.id,
			startDate: new Date(groupSequence.start_date),
			endDate: new Date(groupSequence.end_date),
			userID: groupSequence.user_id,
		}
	})
}

function groupSequencesById(group) {
	return group.sequences.reduce((dict, groupSequence) => {
		if (groupSequence.user_id === null) {
			dict[groupSequence.id] = {
				id: groupSequence.id,
				startDate: new Date(groupSequence.start_date),
				endDate: new Date(groupSequence.end_date),
				userID: groupSequence.user_id,
			}
		}

		return dict
	}, {})
}

function groupLogs(group, logs) {
	const groupStartTime = (new Date(group.start_date)).getTime()
	const groupEndTime = (new Date(group.end_date)).getTime()

	return logs.filter((log) => {
		const logTime = (new Date(log.created_at)).getTime()

		// Only include log done in this group or that happend when the group was active (sadly not all log have a promotion_id)
		return (log.promotion_id === group.id || (logTime >= groupStartTime && logTime <= groupEndTime))
	}).sort((a, b) => {
		return ((new Date(a.created_at)).getTime() - (new Date(b.created_at)).getTime())
	})
}

function groupScenarioLogs(group, groupLogs) {
	const validTypeSlug = ['scenario', 'scenario_end']

	return groupLogs.filter((log) => {
		return (validTypeSlug.indexOf(log.type.slug) > -1)
	})
}

function groupRemainingScenariosByModuleId(group, scenariosDoneById) {
	return group?.course?.modules?.reduce((remainingScenarios, module) => {
		// Get all module and sequences scenarios
		let moduleScenarios = module.scenarios.map((moduleScenario) => moduleScenario.scenario)

		module.sequences.forEach((sequence) => {
			moduleScenarios = moduleScenarios.concat(sequence.scenarios.map((sequenceScenario) => sequenceScenario.scenario))
		})

		// Check which one are done
		remainingScenarios[module.id] = moduleScenarios.filter((scenario) => {
			return !scenariosDoneById || !scenariosDoneById[scenario.id]
		})

		return remainingScenarios
	}, {}) || {}
}

function groupScores(group, bestUserScorePerGameInScenario, scoreCategories, gamesById, scenariosById, scenariosByPresetScenarioId, scenariosByLinkedScenarioId, scenariosDoneById) {
	// Compute user score and max category score for this group course
	const scoresData = group?.course?.modules?.reduce((scoresData, module) => {
		// Get all module and sequences scenarios
		let moduleScenarios = module.scenarios.map((moduleScenario) => moduleScenario.scenario)

		module.sequences.forEach((sequence) => {
			moduleScenarios = moduleScenarios.concat(sequence.scenarios.map((sequenceScenario) => sequenceScenario.scenario))
		})

		// Also include preset and linked scenario to the list of scenario to check
		moduleScenarios = moduleScenarios.reduce((list, scenario) => {
			if (scenariosByPresetScenarioId[scenario.id]) {
				scenariosByPresetScenarioId[scenario.id].forEach((presetScenario) => {
					list.push(presetScenario)
				})
			}

			if (scenariosByLinkedScenarioId[scenario.id]) {
				scenariosByLinkedScenarioId[scenario.id].forEach((linkedScenario) => {
					list.push(linkedScenario)
				})
			}

			return list
		}, moduleScenarios)

		// Add user score and max score for each game and each scenario if the user has already try them
		moduleScenarios.forEach((moduleScenario) => {
			// Check if we have game scores for this scenario
			if (!bestUserScorePerGameInScenario[moduleScenario.id]) {
				return
			}

			// For each game score for this scenario
			Object.keys(bestUserScorePerGameInScenario[moduleScenario.id]).forEach((gameId) => {
				const scores = gamesById[gameId]?.scores

				let userScore = bestUserScorePerGameInScenario[moduleScenario.id] && bestUserScorePerGameInScenario[moduleScenario.id][gameId]

				// Check validity
				if (isNaN(userScore)) {
					userScore = 0
				}

				// Scenario score
				if (!scoresData.scenarioScoreById[moduleScenario.id]) {
					scoresData.scenarioScoreById[moduleScenario.id] = {
						value: 0,
						count: 0,
					}
				}

				scoresData.scenarioScoreById[moduleScenario.id].value += userScore
				scoresData.scenarioScoreById[moduleScenario.id].count += 1

				if (scores?.length > 0) {
					// Categories scores
					scores.forEach((score) => {
						if (!scoresData.categoryScoreById[score.jauge_id]) {
							scoresData.categoryScoreById[score.jauge_id] = {
								value: 0,
								max: 0,
							}
						}

						// Compute user score
						scoresData.categoryScoreById[score.jauge_id].value += userScore * score.score

						// Get max score
						scoresData.categoryScoreById[score.jauge_id].max += score.score
					})
				}
			})
		})

		return scoresData
	}, {
		categoryScoreById: {},
		scenarioScoreById: {},
	})

	// Compute average score
	const categoryScores = scoreCategories.reduce((list, category) => {
		const categoryScore = scoresData.categoryScoreById[category.id]

		if (categoryScore && categoryScore.max > 0) {
			list.push({
				id: category.id,
				name: category.name,
				score: (categoryScore.value / categoryScore.max),
			})
		}

		return list
	}, [])

	// Create hash map to check if a scenario is a main one (ie. one of the main types and not used as a preset)
	const mainScenarioTypeSlug = ['scenario', 'edl']
	const isPresetScenario = Object.keys(scenariosById).reduce((dict, scenarioId) => {
		const scenario = scenariosById[scenarioId]

		if (scenario.preset_scenario_id) {
			dict[scenario.preset_scenario_id] = true
		}
		return dict
	}, {})
	const isMainScenario = Object.keys(scenariosById).reduce((dict, scenarioId) => {
		const scenario = scenariosById[scenarioId]

		// Check type and if it's not used as preset
		if (mainScenarioTypeSlug.indexOf(scenario.type.slug) > -1 && !isPresetScenario[scenario.id]) {
			dict[scenario.id] = true
		}

		return dict
	}, {})

	const scenarioScores = Object.keys(scenariosDoneById).reduce((list, scenarioId) => {
		if (!isMainScenario[scenarioId]) {
			return list
		}

		const scenario = scenariosById[scenarioId]
		const scenarioScore = scoresData.scenarioScoreById[scenarioId] || { value: 1, count: 1 }

		list.push({
			id: scenario.id,
			name: [scenario.name, scenario.title].join(' - '),
			score: (scenarioScore.value / scenarioScore.count),
		})

		return list
	}, []).sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	})

	return {
		categoryScores,
		scenarioScores,
	}
}

export default {
	logsData(user) {
		// Init computed data
		let computedLogsData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			perGroupScenario: {},
			averagePerGroupScenario: {},
			lastSessionTimestamp: 0,
			scenariosDonePerGroup: {},
			bestScorePerGameInScenario: {},
			sequencesDonePerGroup: {},
			loginPerDay: {},
		}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			const groupId = log.promotion_id

			// Add log creation time
			times.push({
				logTime: (new Date(log.created_at)).getTime(),
				groupId,
			})

			// Handle scenario done and times data and best game score
			switch (log.type.slug) {
				case 'scenario':
					if (!computedLogsData.perGroupScenario[groupId]) {
						computedLogsData.perGroupScenario[groupId] = {}
					}

					if (!computedLogsData.perGroupScenario[groupId][log.data_key]) {
						computedLogsData.perGroupScenario[groupId][log.data_key] = []
					}

					computedLogsData.perGroupScenario[groupId][log.data_key].push({
						start: (new Date(log.created_at)).getTime(),
						update: (new Date(log.updated_at)).getTime(),
						groupId,
					})
					break

				case 'scenario_end':
					if (computedLogsData.perGroupScenario?.[groupId]?.[log.data_key]?.length > 0) {
						const times = computedLogsData.perGroupScenario[groupId][log.data_key]

						times[times.length - 1].end = (new Date(log.created_at)).getTime()
					}

					if (!computedLogsData.scenariosDonePerGroup[groupId]) {
						computedLogsData.scenariosDonePerGroup[groupId] = {}
					}

					computedLogsData.scenariosDonePerGroup[groupId][log.data_key] = true
					break

				case 'game':
					if (isNaN(log.data)) {
						break
					}
					if (!computedLogsData.bestScorePerGameInScenario[log.scenario_id]) {
						computedLogsData.bestScorePerGameInScenario[log.scenario_id] = {}
					}
					if (!computedLogsData.bestScorePerGameInScenario[log.scenario_id][log.data_key]) {
						computedLogsData.bestScorePerGameInScenario[log.scenario_id][log.data_key] = log.data
					} else if (log.data > computedLogsData.bestScorePerGameInScenario[log.scenario_id][log.data_key]) {
						computedLogsData.bestScorePerGameInScenario[log.scenario_id][log.data_key] = log.data
					}
					break

				case 'sequence_end':
					if (!computedLogsData.sequencesDonePerGroup[groupId]) {
						computedLogsData.sequencesDonePerGroup[groupId] = {}
					}

					computedLogsData.sequencesDonePerGroup[groupId][log.data_key] = true
					break

				case 'login': {
					const dayId = utils.dayId(new Date(log.created_at))

					if (!computedLogsData.loginPerDay[dayId]) {
						computedLogsData.loginPerDay[dayId] = []
					}

					computedLogsData.loginPerDay[dayId].push(log)
					break
				}
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		let lastLogTime = 0
		let lastGroupId = null

		times.forEach(({ logTime, groupId }) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if two logs are too far apart or from different group
			if (deltaTime > oneHour || (lastGroupId && groupId !== lastGroupId)) {
				// Check group id for last session
				if (!lastGroupId && computedLogsData.times.length > 0) {
					computedLogsData.times[computedLogsData.times.length - 1].groupId = defaultGroupIdForSession(computedLogsData.times[computedLogsData.times.length - 1], user)
				}

				// Start a new session with the minimal time (5 min)
				computedLogsData.lastSessionTimestamp = logTime
				computedLogsData.times.push({
					start: logTime,
					duration: fiveMinutes,
					groupId,
				})

				// Update sum
				computedLogsData.sum += fiveMinutes

				// Register session week as active
				computedLogsData.weekIds[utils.weekId(new Date(logTime))] = true
			} else {
				const currentTime = computedLogsData.times[computedLogsData.times.length - 1]

				// Set group id if not set
				if (!currentTime.groupId) {
					currentTime.groupId = groupId
				}

				// Increment current session time
				currentTime.duration += deltaTime

				// Update sum
				computedLogsData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedLogsData.times[computedLogsData.times.length - 1].duration

			if (sessionTime > computedLogsData.max) {
				computedLogsData.max = sessionTime
			}

			lastLogTime = logTime
			lastGroupId = groupId
		})

		// Compute average time
		if (computedLogsData.times.length > 0) {
			computedLogsData.average = (computedLogsData.sum / computedLogsData.times.length)
		}

		// Compute scenario time data
		const groupIds = Object.keys(computedLogsData.perGroupScenario)

		groupIds.forEach((groupId) => {		
			const scenarioIds = Object.keys(computedLogsData.perGroupScenario[groupId])

			computedLogsData.averagePerGroupScenario[groupId] = scenarioIds.reduce((dict, id) => {
				const times = computedLogsData.perGroupScenario?.[groupId]?.[id]
				
				let sum = 0
				let count = 0

				// Make an average of valid time data
				times.forEach((time) => {
					if (time.end) {
						const deltaTime = (time.end - time.update)

						if (deltaTime <= oneHour) {
							sum += (time.end - time.start)
							count += 1
						}
					}
				})

				if (count > 0) {
					dict[id] = (sum / count)
				}

				return dict
			}, {})
		})

		return computedLogsData
	},
	timesPerGroup(user) {
		return user.logsData.times.reduce((dict, time) => {
			if (!dict[time.groupId]) {
				dict[time.groupId] = []
			}
			
			dict[time.groupId].push(time)

			return dict
		}, {})
	},
	groupsById(user, data) {
		return user.promotions.reduce((dict, userPromotion) => {
			const group = userPromotion.promotion

			// Fill group extra data
			group.progressRate = groupProgression(user, group)

			const times = (user.timesPerGroup[group.id] || [])
			const totalTime = times.reduce((sum, time) => {
				sum += time.duration
				return sum
			}, 0)

			// Compute number of sequence who should be done as of today
			let sequencesDone = 0

			const now = Date.now()
			group.sequences.forEach((groupSequence) => {
				if (groupSequence.user_id === null && now > (new Date(groupSequence.start_date)).getTime()) {
					sequencesDone += 1
				}
			})

			group.activeTimeData = {
				times,
				totalTime,
				averageScenarioTime: groupAverageScenarioTime(user, group, data.scenarios),
				remainingTime: (twoAndHalfHour * sequencesDone) || null,
			}

			// Group sequence state
			const sequencesById = groupSequencesById(group)
			const courseSequences = groupCourseSequences(group)
			const latestSequenceDatesById = userLatestSequenceDates(user, groupSequences(group))

			group.sequenceState = sequenceState(user.logsData.sequencesDonePerGroup[group.id], user.logsData.loginPerDay, latestSequenceDatesById, courseSequences, sequencesById, group.type)
			group.sequencesState = sequencesState(group.sequenceState, courseSequences, sequencesById)

			// Group scenarios logs
			group.logs = groupLogs(group, user.logs)
			group.scenarioLogs = groupScenarioLogs(group, group.logs)
			group.remainingScenariosByModuleId = groupRemainingScenariosByModuleId(group, user.logsData.scenariosDonePerGroup?.[group.id])

			// Group user score by category
			const scenariosDoneById = user.logsData.scenariosDonePerGroup?.[group.id] || user.logsData.scenariosDonePerGroup?.[null] || user.logsData.scenariosDonePerGroup?.[''] || []
			const scoresData = groupScores(group, user.logsData.bestScorePerGameInScenario, data.scoreCategories, data.gamesById, data.scenariosById, data.scenariosByPresetScenarioId, data.scenariosByLinkedScenarioId, scenariosDoneById)
			group.scoresByCategory = scoresData.categoryScores
			group.scoresByScenario = scoresData.scenarioScores

			dict[group.id] = group

			return dict
		}, {})
	},
	activeTimeData(user) {
		return {
			times: user.logsData.times,
			sum: user.logsData.sum,
			max: user.logsData.max,
			average: user.logsData.average,
			weekIds: user.logsData.weekIds,
			lastSessionTimestamp: user.logsData.lastSessionTimestamp
		}
	},
}