import { computeTrackingDataForList, countMatch, sumField, averageField } from '@/utils/trackingDataFactories'

import commonTypes from '../../common/commonTypes'

import extraUsersData from "./extraUsersData"

const now = new Date()

export default {
	id: 'organizationTrackingData',
	types: commonTypes,
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				roles: ['user'],
				emailFilter: '%(actionlogement.fr|afpols.fr|thewalkingnerds.com|grave.cool)',
				logTypes: ['login', 'logout', 'pdf', 'pdf_view', 'open_library', 'sequence_end', 'video_start', 'scenario', 'video_end', 'scenario_end', 'game'],
			},
			groupByUniq: 'id'
		},
		promotions: {
			module: 'Logs',
			action: 'GetPromotionsTrackingData',
			groupByUniq: 'id'
		},
		scenarios: {
			module: 'Scenarios',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		},
		courses: {
			module: 'Course',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id',
		},
		scenarioLogs: {
			module: 'Logs',
			action: 'GetScenarioLogsTrackingData',
			payload: {
				roles: ['user'],
				emailFilter: '%(actionlogement.fr|afpols.fr|thewalkingnerds.com|grave.cool)',
			},
		},
	},
	extraData: {
		promotions: {
			scenarios(promotion) {
				// Get scenario list for this promotion course
				return promotion.course.modules.reduce((scenarios, mod) => {
					// Get scenarios in this module sequences
					mod.sequences.forEach((sequence) => {
						scenarios = scenarios.concat(sequence.scenarios)
					})

					return scenarios
				}, [])
			},
			status(group) {
				const start = new Date(group.start_date)
				const end = new Date(group.end_date)

				return (now < start ? 'coming' : (now > end ? 'done' : 'pending'))
			},
		},
		scenarioLogs: {
			time(log) {
				// Compute log time
				const startTime = (new Date(log.created_at)).getTime()
				const endTime = (new Date(log.updated_at)).getTime()

				return (endTime - startTime)
			}
		},
		users: extraUsersData,
		usersData: (data) => {
			// Compute active and finished count
			const { activeCount, finishedCount } = computeTrackingDataForList(data.users || [], {
				activeCount: countMatch((user) => {
					return user.progression > 0 && user.progression < 1
				}),
				finishedCount: countMatch((user) => {
					return user.progression >= 1
				}),
			})

			return {
				count: data.users.length,
				activeCount,
				finishedCount,
			}
		},
		groupsData: (data) => {
			// Compute collective and individual count
			const { collectiveCount, individualCount } = computeTrackingDataForList(data.promotions || [], {
				collectiveCount: countMatch((group) => {
					return (group.type.slug === 'collective_promotion')
				}),
				individualCount: countMatch((group) => {
					return (group.type.slug === 'individual_promotion')
				}),
			})

			return {
				count: data.promotions.length,
				collectiveCount,
				individualCount,
			}
		},
		coursesData: (data) => {
			// Compute complet and block count
			const {
				completCount,
				completActiveCount,
				completFinishedCount,
				blockCount,
				blockActiveCount,
				blockFinishedCount,
			} = computeTrackingDataForList(data.promotions || [], {
				completCount: countMatch((group) => {
					return (group.course.type.slug === 'complet_course')
				}),
				completActiveCount: countMatch((group) => {
					return (group.course.type.slug === 'complet_course' && group.status === 'pending')
				}),
				completFinishedCount: countMatch((group) => {
					return (group.course.type.slug === 'complet_course' && group.status === 'done')
				}),
				blockCount: countMatch((group) => {
					return (group.course.type.slug === 'bloc_course')
				}),
				blockActiveCount: countMatch((group) => {
					return (group.course.type.slug === 'bloc_course' && group.status === 'pending')
				}),
				blockFinishedCount: countMatch((group) => {
					return (group.course.type.slug === 'bloc_course' && group.status === 'done')
				}),
			})

			return {
				completCount,
				completActiveCount,
				completFinishedCount,
				blockCount,
				blockActiveCount,
				blockFinishedCount,
			}
		},
		activityData: (data) => {
			// Compute total time and average success rate for done users
			const { totalTime, averageSuccessRate } = computeTrackingDataForList(data.users || [], {
				totalTime: sumField('activeTimeData', 'sum'),
				averageSuccessRate: averageField('successRate', null, (value, user) => {
					return (user.progression >= 1)
				}),
			})

			// Create hash map to check if a scenario is a main one (ie. one of the main types and not used as a preset)
			const mainScenarioTypeSlug = ['scenario', 'edl']
			const isPresetScenario = data.scenarios.reduce((dict, scenario) => {
				if (scenario.preset_scenario_id) {
					dict[scenario.preset_scenario_id] = true
				}
				return dict
			}, {})
			const isMainScenario = data.scenarios.reduce((dict, scenario) => {
				// Check type and if it's not used as preset
				if (mainScenarioTypeSlug.indexOf(scenario.type.slug) > -1 && !isPresetScenario[scenario.id]) {
					dict[scenario.id] = true
				}

				return dict
			}, {})

			// Only make average time computation on base scenario (not revision or preset) and for the first user attempt
			const userAttemptedScenarioDict = {}
			const scenarioLogs = (data.scenarioLogs || []).filter((log) => {
				const scenario = data.scenariosById[log.data_key]

				if (!scenario) {
					return false
				}

				if (!userAttemptedScenarioDict[scenario.id]) {
					userAttemptedScenarioDict[scenario.id] = {}
				}

				const isValid = (isMainScenario[scenario.id] && !userAttemptedScenarioDict[scenario.id][log.user_id])

				// Mark the scenario as attempted for this user
				userAttemptedScenarioDict[scenario.id][log.user_id] = true

				return isValid
			})

			// Compute average time to finish a scenario
			const { averageScenarioTime } = computeTrackingDataForList(scenarioLogs, {
				averageScenarioTime: averageField('time'),
			})

			return {
				totalTime,
				averageScenarioTime,
				averageSuccessRate,
			}
		},
	},
	sheets: []
}