import commonTypes from '../common/commonTypes'

import extraUserData from "./extraUserData"

export default {
	id: 'userTrackingReportData',
	types: commonTypes,
	remoteData: {
		user: {
			module: 'Logs',
			action: 'GetUserTrackingData',
			payload: {				
				logTypes: ['login', 'logout', 'pdf', 'pdf_view', 'open_library', 'sequence_end', 'video_start', 'scenario', 'video_end', 'scenario_end', 'game'],
			},
		},
		scenarios: {
			module: 'Scenarios',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id',
			groupBy: ['preset_scenario_id', 'linked_scenario_id'],
		},
		scoreCategories: {
			module: 'Games',
			action: 'getJaugeList',
			state: 'jauges',
			groupByUniq: 'id'
		},
		games: {
			module: 'Games',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		},
	},
	extraData: {
		user: extraUserData,
	},
	sheets: []
}