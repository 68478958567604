import utils from "@/utils/trackingReportTemplate"

export default {
	name: 'Rappel Parcours associé',
	content: [
		{
			text: 'titre du parcours : ',
			value: (data) => {
				return data.course.name
			}
		},
		{
			text: 'type de planification choisie :',
			value: (data) => {
				return data.promotionType.name
			},
		},
		{},
		{
			table: (data) => {
				return data.course.levels.reduce((list, level) => {
					const name = ([level.name, level.title].filter(word => !!word).join(' - ') || 'Niveau ' + (level.order + 1))

					list.push({
						name,
						availableScenarios: level.scenarios.map((levelScenario) => {
							return levelScenario.scenario.name
						}).join(', '),
						extraScenarios: '',
						date: '',
					})

					level.sequences.forEach((sequence) => {
						const name = ([sequence.name, sequence.title].filter(word => !!word).join(' - ') || 'Séquence ' + (level.order + 1) + '.' + (sequence.order + 1))
						const date = data.promotion.sequencesById?.[sequence.id]?.startDate.getTime()

						list.push({
							name,
							availableScenarios: sequence.level_scenario_limit,
							extraScenarios: sequence.scenarios.map((sequenceScenario) => {
								return sequenceScenario.scenario.name
							}).join(', '),
							date,
						})
					})

					return list
				}, [])
			},
			headers: (users, data) => {
				const extraColumn = (data.promotionType.slug === 'collective_promotion' ? ['date planifiée'] : [])

				return ['séquence', 'scénario disponibles', 'scénario additionnels'].concat(extraColumn)
			},
			row: (item, data) => {
				const extraColumn = (data.promotionType.slug === 'collective_promotion' ? [item.date ? { value: item.date, type: 'date' } : { value: '-' }] : [])

				return [
					'name',
					'availableScenarios',
					'extraScenarios',
				].concat(extraColumn)
			}
		},
	]
}