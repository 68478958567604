<template>
	<div class="dashboard dashboard-main">
		<!-- Header -->
		<div class="flex items-center justify-between mb-8 w-full">
			<div>
				<v-select
					class="twn-select mb-4"
					name="type"
					:searchable="false"
					:clearable="true"
					:options="years"
					v-model="atYear"
					placeholder="Filtrer par année"
					:disabled="isLoading"
				/>

				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span
					v-if="isSuperAdmin"
					class="ml-2 cursor-pointer"
					@click="() => includeOtherRoles = !includeOtherRoles"
				>
					Inclure les admins/responsables clients
				</span>
			</div>

			<div>
				<button type="button" class="twn-button mr-4" @click="exportPDF" :disabled="loadingPDF || isLoading">
					<span v-if="!loadingPDF">
						Export PDF
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>

				<button type="button" class="twn-button" @click="ExportReport" :disabled="loadingReport || isLoading">
					<span v-if="!loadingReport">
						Export CSV
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>
			</div>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !trackingData">
			Chargement...
		</p>
		<div v-else>
			<!-- Users, customers and groups -->
			<CategoryBlock title="Utilisateurs, organisations et promotions">
				<div class="dashboard-kpis">
					<KPIBlock
						title="Utilisateurs inscrits"
						:number="trackingData.usersData.count"
					/>
					<KPIBlock
						title="Utilisateurs en attente"
						help="Les utilisateurs inscrits qui ne sont pas dans une promotion ou qui sont dans une promotion qui n’a pas encore commencé."
						:number="trackingData.usersData.waitingCount"
						:backgroundColor="(trackingData.usersData.waitingCount ? '' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Utilisateurs en cours"
						:number="trackingData.usersData.activeCount"
						:backgroundColor="(trackingData.usersData.activeCount ? 'bg-dashboard-blue-dark' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Utilisateurs ayant terminé"
						:number="trackingData.usersData.finishedCount"
						:backgroundColor="(trackingData.usersData.finishedCount ? 'bg-dashboard-green' : 'bg-dashboard-gray-light')"
					/>
				</div>

				<div class="dashboard-kpis">
					<KPIBlock
						title="Organisations"
						:number="trackingData.organizationsData.count"
					/>
					<KPIBlock
						title="Promotions"
						:number="trackingData.groupsData.count"
					/>
					<KPIBlock
						title="Promotions en planification collective"
						:number="trackingData.groupsData.collectiveCount"
						backgroundColor="bg-dashboard-blue"
					/>
					<KPIBlock
						title="Promotions en planification individuelle"
						:number="trackingData.groupsData.individualCount"
						backgroundColor="bg-dashboard-blue"
					/>
				</div>
			</CategoryBlock>

			<!-- Courses -->
			<CategoryBlock title="Parcours">
				<div class="dashboard-kpis">
					<div class="flex-1 flex items-center">
						<p class="text-2xl text-dashboard-gray-darker">
							Parcours plusieurs niveaux
						</p>
					</div>
					<KPIBlock
						title="Promotions"
						:number="trackingData.coursesData.completCount"
					/>
					<KPIBlock
						title="Promotions en cours"
						:number="trackingData.coursesData.completActiveCount"
						:backgroundColor="(trackingData.coursesData.completActiveCount ? 'bg-dashboard-blue-dark' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Promotions terminées"
						:number="trackingData.coursesData.completFinishedCount"
						:backgroundColor="(trackingData.coursesData.completFinishedCount ? 'bg-dashboard-green' : 'bg-dashboard-gray-light')"
					/>
				</div>

				<div class="dashboard-kpis">
					<div class="flex-1 flex items-center">
						<p class="text-2xl text-dashboard-gray-darker">
							Parcours 1 niveau
						</p>
					</div>
					<KPIBlock
						title="Promotions"
						:number="trackingData.coursesData.blockCount"
					/>
					<KPIBlock
						title="Promotions en cours"
						:number="trackingData.coursesData.blockActiveCount"
						:backgroundColor="(trackingData.coursesData.blockActiveCount ? 'bg-dashboard-blue-dark' : 'bg-dashboard-gray-light')"
					/>
					<KPIBlock
						title="Promotions terminées"
						:number="trackingData.coursesData.blockFinishedCount"
						:backgroundColor="(trackingData.coursesData.blockFinishedCount ? 'bg-dashboard-green' : 'bg-dashboard-gray-light')"
					/>
				</div>
			</CategoryBlock>

			<!-- Courses -->
			<CategoryBlock title="Connexions et réussites">
				<div class="dashboard-kpis">
					<KPIBlock
						title="De formation délivrées"
						:time="trackingData.activityData.totalTime"
					/>
					<KPIBlock
						title="Temps passé pour compléter un scénario (Moyenne)"
						:time="trackingData.activityData.averageScenarioTime"
						help="Pour la première tentative aboutie concernant les MES et VT."
					/>
					<KPIBlock
						title="De réussite sur nos mises en situation (moyenne)"
						help="% moyen de réussite pour les utilisateurs ayant terminé leur parcours"
						:percentage="trackingData.activityData.averageSuccessRate"
					/>
				</div>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'
	import pdfTemplate from '@/utils/pdfTemplate'
	
	import trackingTemplate from "@/constants/mainTrackingReportTemplate"
	import trackingDataTemplate from "@/constants/dashboard/mainTrackingData"

	import Toggle from '@/components/Toggle'
	
	import CategoryBlock from "./block/Category"
	import KPIBlock from "./block/KPI"

	const now = new Date()

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			KPIBlock,
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
				loadingReport: false,
				loadingPDF: false,
				includeOtherRoles: false,
				atYear: { label: '18 derniers mois', value: 'last_18' },
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
			years() {
				const years = [
					{ label: '12 derniers mois', value: 'last_12' },
					{ label: '18 derniers mois', value: 'last_18' }
				]

				for (let i = now.getFullYear(); i >= 2020; i -= 1) {
					years.push(i)
				}
				
				return years
			}
		},
		watch: {
			includeOtherRoles() {
				this.loadData()
			},
			atYear() {
				this.loadData()
			}
		},
		mounted() {
			this.loadData()
		},
		methods: {
			async loadData(force = false) {
				if (this.isLoading) {
					return
				}

				this.isLoading = true

				// Load tracking data
				const payload = {
					fromDate: new Date(0),
					toDate: new Date(`${(now.getFullYear() + 1)}-01-01`),
				}

				if (this.includeOtherRoles) {
					payload.roles = ['user', 'superadmin', 'manager']
					payload.emailFilter = ''
				}

				if (this.atYear) {
					if (typeof this.atYear === 'object' && this.atYear.value) {
						const months = parseInt(this.atYear.value.replace('last_', ''))

						if (months) {
							payload.fromDate = new Date()
							payload.fromDate.setMonth(now.getMonth() - 12)
						}
					} else {
						payload.fromDate = new Date(`${this.atYear}-01-01`)
						payload.toDate = new Date(`${this.atYear + 1}-01-01`)
					}
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingDataTemplate, payload, force)

				this.isLoading = false
			},
			async ExportReport(){
				this.loadingReport = true

				const payload = {
					fromDate: new Date(0),
					toDate: new Date(`${(now.getFullYear() + 1)}-01-01`),
				}

				if (this.includeOtherRoles) {
					payload.roles = ['user', 'superadmin', 'manager']
					payload.emailFilter = ''
				}

				if (this.atYear) {
					if (typeof this.atYear === 'object' && this.atYear.value) {
						const months = parseInt(this.atYear.value.replace('last_', ''))

						if (months) {
							payload.fromDate = new Date()
							payload.fromDate.setMonth(now.getMonth() - 12)
						}
					} else {
						payload.fromDate = new Date(`${this.atYear}-01-01`)
						payload.toDate = new Date(`${this.atYear + 1}-01-01`)
					}
				}

				let year = ''

				if (this.atYear) {
					year += '_'

					if (typeof this.atYear === 'object' && this.atYear.value) {
						const months = parseInt(this.atYear.value.replace('last_', ''))

						if (months) {
							const toDate = new Date()
							const fromDate = new Date()
							fromDate.setMonth(now.getMonth() - 12)

							const from = fromDate.getDate() + '_' + fromDate.getMonth() + '_' + fromDate.getFullYear()
							const to = toDate.getDate() + '_' + toDate.getMonth() + '_' + toDate.getFullYear()

							year += 'du_' + from + '_au_' + to
						}
					} else {
						year += this.atYear
					}
				}

				try {
					await trackingReport.export(`TB_général${year}`, this.$store, trackingTemplate, payload)
				} catch (err) {
					console.error(err)
				}

				this.loadingReport = false
			},
			async exportPDF() {
				this.loadingPDF = true

				let year = ''

				if (this.atYear) {
					year += '_'

					if (typeof this.atYear === 'object' && this.atYear.value) {
						const months = parseInt(this.atYear.value.replace('last_', ''))

						if (months) {
							const toDate = new Date()
							const fromDate = new Date()
							fromDate.setMonth(now.getMonth() - 12)

							const from = fromDate.getDate() + '_' + fromDate.getMonth() + '_' + fromDate.getFullYear()
							const to = toDate.getDate() + '_' + toDate.getMonth() + '_' + toDate.getFullYear()

							year += 'du_' + from + '_au_' + to
						}
					} else {
						year += this.atYear
					}
				}

				pdfTemplate.export(`TB_général${year}`, require("../../assets/templates/main_dashboard.svg"), this.trackingData)

				this.loadingPDF = false
			}
		}
	}
</script>

<style lang="scss">
	
</style>