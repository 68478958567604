import Vue from 'vue'

export default new Vue({
	data() {
		return {
			pageTitle: null,
			viewTitle: null
		}
	},
	watch: {
		pageTitle: {
			handler() {
				this.updateTitle()
			},
			immediate: true
		},
		viewTitle: {
			handler() {
				this.updateTitle()
			},
			immediate: true
		}
	},
	methods: {
		updateTitle() {
			let parts = [process.env.VUE_APP_NAME, (this.pageTitle || 'Backoffice')]

			if (this.viewTitle) {
				parts.push(this.viewTitle)
			}

			document.title = parts.join(' - ')
		}
	}
})