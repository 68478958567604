var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard dashboard-group"},[_c('div',{staticClass:"flex items-center justify-between mb-8 w-full"},[_c('div',[(_vm.isSuperAdmin)?_c('Toggle',{attrs:{"checked":_vm.includeOtherRoles,"onInput":() => _vm.includeOtherRoles = !_vm.includeOtherRoles}}):_vm._e(),(_vm.isSuperAdmin)?_c('span',{staticClass:"ml-2 cursor-pointer",on:{"click":() => _vm.includeOtherRoles = !_vm.includeOtherRoles}},[_vm._v(" Inclure les admins/responsables clients ")]):_vm._e()],1),_c('div',[_c('button',{staticClass:"twn-button mr-4",attrs:{"type":"button","disabled":_vm.loadingPDF || _vm.isLoading},on:{"click":_vm.exportPDF}},[(!_vm.loadingPDF)?_c('span',[_vm._v(" Export PDF ")]):_c('span',[_vm._v(" Chargement... ")])]),_c('button',{staticClass:"twn-button",attrs:{"type":"button","disabled":_vm.loadingReport || _vm.isLoading},on:{"click":_vm.ExportReport}},[(!_vm.loadingReport)?_c('span',[_vm._v(" Export CSV ")]):_c('span',[_vm._v(" Chargement... ")])])])]),(_vm.isLoading || !_vm.trackingData)?_c('p',[_vm._v(" Chargement... ")]):_c('div',[_c('CategoryBlock',{attrs:{"title":"Connexions"}},[_c('div',{staticClass:"dashboard-kpis"},[_c('KPIBlock',{attrs:{"title":"Durée totale de connexion","time":_vm.trackingData.activityData.totalTime}}),_c('KPIBlock',{attrs:{"title":"Durée moyenne de connexion","time":_vm.trackingData.activityData.averageTime,"help":"Durée totale de connexion de l'ensemble des utilisateurs de la promotion / le nombre d'utilisateurs"}}),_c('KPIBlock',{attrs:{"title":"Durée estimée","time":_vm.trackingData.activityData.remainingTime,"help":(_vm.groupType.slug === 'collective_promotion' ?
						'Estimation de la durée nécessaire à la réalisation des séquences à date' :
						'Estimation de la durée nécessaire à la réalisation des séquences à date (fiable a la date de fin de niveau)'
					)}})],1),_c('SubCategoryBlock',{attrs:{"title":"Durée totale de connexion par apprenant","help":"La zone grisée correspond à la durée moyenne par utilisateur"}},[_c('p',[_vm._v(" Moyenne de la promotion : "),_c('span',{staticClass:"font-principal-medium"},[_vm._v(_vm._s(_vm.formatTime(_vm.trackingData.activityData.averageTime)))])]),_c('BarChart',{attrs:{"data":_vm.trackingData.activityData.perUser,"labels":_vm.chartUserLabels,"showLegend":false,"enableTooltip":true,"limit":_vm.trackingData.activityData.averageTime,"maxBarSize":60,"minBarSpace":6,"format":"time"}})],1),_c('SubCategoryBlock',{attrs:{"title":"Avancement des apprenants"}},[_c('div',{staticClass:"dashboard-group-progress-legend"},_vm._l((_vm.sequenceStateData),function(data,state){return _c('p',{key:state,class:{ 'd-none': _vm.groupType.slug === 'collective_promotion' && state === 'todo' },attrs:{"title":data.description}},[_c('img',{attrs:{"src":data.icon}}),_c('span',{class:data.class},[_vm._v(_vm._s(data.content))]),_c('span',[_vm._v(_vm._s(data.name))])])}),0),_c('div',{staticClass:"overflow-auto"},[_c('table',[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.course.levels),function(level,index){return _c('th',{key:level.id,staticClass:"border-b-1 border-dashboard-gray-dark",class:{
										'border-r-1': (index < _vm.course.levels.length - 1)
									},attrs:{"colspan":level.sequences.length}},[_vm._v(" "+_vm._s(`Niveau ${(level.order + 1)}`)+" ")])})],2),_c('tr',[_c('th'),_vm._l((_vm.course.levels),function(level,index){return _vm._l((level.sequences),function(sequence,sequenceIndex){return _c('th',{key:sequence.id,staticClass:"border-dashboard-gray-dark",class:{
											'border-r-1': (index < _vm.course.levels.length - 1 || sequenceIndex < (level.sequences.length - 1))
										},attrs:{"title":sequence.name}},[_vm._v(" "+_vm._s((sequence.name.split(' ')[0] || `${(level.order + 1)}.${(sequence.order + 1)}`))+" ")])})})],2)]),_c('tbody',_vm._l((_vm.trackingData.users),function(user){return _c('tr',{key:user.id},[_c('td',[_c('img',{staticClass:"inline-block",attrs:{"src":_vm.sequenceStateData[user.sequencesState].icon}}),_c('router-link',{attrs:{"to":{ name: 'users-edit', params: { itemID: user.id } }}},[_vm._v(" "+_vm._s([user.first_name, user.name].join(' '))+" ")])],1),_vm._l((_vm.course.levels),function(level){return _vm._l((level.sequences),function(sequence){return _c('td',{key:sequence.id,class:_vm.sequenceStateData[user.sequenceState[sequence.id]].class,attrs:{"title":_vm.sequenceStateData[user.sequenceState[sequence.id]].description}},[_c('span',[_vm._v(" "+_vm._s(_vm.sequenceStateData[user.sequenceState[sequence.id]].content)+" ")])])})})],2)}),0)])]),_c('div',{staticClass:"text-center mt-4"},[_c('button',{staticClass:"underline",attrs:{"type":"button"},on:{"click":function($event){_vm.showLegend = !_vm.showLegend}}},[_vm._v(" "+_vm._s((_vm.showLegend ? 'Cacher' : 'Afficher'))+" la légende détaillée ")]),(_vm.showLegend)?_c('div',{staticClass:"text-left mt-2"},[_c('table',{staticClass:"dashboard-group-progress-legend-details"},_vm._l((_vm.sequenceStateData),function(data,state){return _c('tr',{key:state,class:{ 'd-none': _vm.groupType.slug === 'collective_promotion' && state === 'todo' }},[_c('td',[_c('img',{attrs:{"src":data.icon}}),_c('span',{class:data.class},[_vm._v(_vm._s(data.content))])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(data.name)+" ")])]),_c('td',[_c('span',[_vm._v(_vm._s(data.description))])])])}),0)]):_vm._e()])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }